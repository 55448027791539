.fullPageSliderWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
  justify-items: center;
  overflow: hidden;

  h1 {
    font-size: 4.5rem;
    color: var(--dark-grey);
    text-align: center;
    font-weight: bold;
    padding: 20px;
  }

  p {
    font-size: 2.5rem;
    color: var(--light-grey);
    text-align: center;
    max-width: 1200px;
    padding: 20px;
  }
}

.sliderImages {
  display: flex;
  width: 100%;
  height: 470px;
  overflow: hidden;
}

.sliderImageContainer {
  display: flex;
  gap: 20px;
  width: 90%;
  margin: 0 auto;
  transition: transform 0.5s ease-in-out;
}

.sliderImage {
  width: 50%;
  height: auto;
  flex: 0 0 50%;
}

.pageSliderNav {
  display: flex;
  gap: 20px;
  justify-content: start;
  align-items: start;
  justify-items: start;
  padding: 0 0 0 90px;

  button {
    width: 60px;
    height: 60px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    cursor: pointer;
    border-radius: 50px;
    background: var(--light-grey-hovered);
    border: none;
    transition: 0.2s ease-in-out;

    &:hover {
      background: var(--light-grey);
    }
  }
}

@media screen and (max-width: 768px) {
  .sliderImages {
    height: fit-content;
  }

  .pageSliderNav {
    padding: 0 0 0 20px;
  }

  .sliderImage{
    width: 100%;
    height: 300px;
  }

}