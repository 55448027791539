.default {
  background-color: #007bff;
  border: none;
  width: 100%;
  border-radius: 50px;
  height: fit-content;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  text-align: center;
  color: var(--royal-white);
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: var(--royal-blue-hovered);
  }
}

.default-100 {
  background-color: #007bff;
  border: none;
  width: 100%;
  border-radius: 50px;
  height: fit-content;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  text-align: center;
  color: var(--royal-white);
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: var(--royal-blue-hovered);
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.default-non-responsive {
  background-color: #007bff;
  border: none;
  width: 100%;
  border-radius: 50px;
  height: fit-content;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  color: var(--royal-white);
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: var(--royal-blue-hovered);
  }
}

.outlined {
  background: none;
  border: 1px solid var(--royal-blue);
  width: 100%;
  border-radius: 50px;
  height: fit-content;
  padding: 10px 20px;
  cursor: pointer;
  color: var(--royal-blue);
  font-size: 16px;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: var(--light-grey-hovered);
  }
}

.attach-photo {
  display: flex;
  width: 100%;
  border: 1px solid var(--light-grey);
  border-radius: 16px;
  text-align: center;
  align-content: center;
  align-items: center;
  font-size: 1.8rem;
  font-weight: bold;
  padding: 10px 20px;
  justify-content: center;
  color: var(--dark-grey);
  background: transparent;
  transition: 0.2s ease-in-out;

  &:hover {
    background: var(--light-grey-hovered-2);
  }
}

.more {
  display: flex;
  width: 100%;
  border: 1px solid var(--dark-grey);
  border-radius: 55px;
  padding: 10px 20px;
  text-align: center;
  align-content: center;
  align-items: center;
  font-size: 1.8rem;
  font-weight: bold;
  justify-content: center;
  color: var(--dark-grey);
  background: transparent;
  transition: 0.2s ease-in-out;

  &:hover {
    background: var(--light-grey-hovered-2);
  }
}

.attach-file {
  display: flex;
  width: 100%;
  border: 1px solid var(--light-grey);
  border-radius: 16px;
  text-align: center;
  align-content: center;
  padding: 10px 20px;
  align-items: center;
  font-size: 1.8rem;
  font-weight: bold;
  justify-content: center;
  color: var(--dark-grey);
  background: transparent;
  transition: 0.2s ease-in-out;

  &:hover {
    background: var(--light-grey-hovered-2);
  }
}

.delete {
  background-color: var(--royal-red);
  border: none;
  width: 100%;
  border-radius: 50px;
  height: fit-content;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  color: var(--royal-white);
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: var(--royal-red-hovered);
  }
}

.apply {
  background-color: var(--royal-green);
  border: none;
  width: 100%;
  border-radius: 50px;
  height: fit-content;
  padding: 10px 20px;
  cursor: pointer;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  font-size: 16px;
  text-decoration: none;
  text-align: center;
  color: var(--royal-white);
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: var(--royal-green-hovered);
  }
}

.apply-100 {
  background-color: var(--royal-green);
  border: none;
  width: 100%;
  max-width: 250px;
  border-radius: 50px;
  height: fit-content;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  text-align: center;
  color: var(--royal-white);
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: var(--royal-green-hovered);
  }
}


.apply-non-responsive {
  background-color: var(--royal-green);
  border: none;
  width: 100%;
  border-radius: 50px;
  height: fit-content;
  padding: 10px 20px;
  cursor: pointer;
  align-content: center;
  align-items: center;
  max-width: 250px;
  justify-content: center;
  justify-items: center;
  font-size: 16px;
  color: var(--royal-white);
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: var(--royal-green-hovered);
  }
}

.slim {
  display: flex;
  background-color: transparent;
  border-radius: 50px;
  height: fit-content;
  width: 100%;
  text-align: left;
  padding: 10px 20px;
  cursor: pointer;
  color: var(--royal-blue);
  font-size: 16px;
  border: none;
  transition: 0.2s ease-in-out;

  &:hover {
    background: var(--light-grey-hovered);
  }
}

.filter {
  background-color: var(--light-grey);
  width: 100%;
  border: none;
  border-radius: 50px;
  height: fit-content;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold;
  color: var(--grey);
  font-size: 16px;
  outline: none;
  transition: 0.2s ease-in-out;

  &:hover {
    background: var(--grey-darker);
    color: var(--grey);
  }
}

.neutral {
  background-color: transparent;
  width: 100%;
  border: 1px solid var(--light-grey);
  border-radius: 50px;
  height: fit-content;
  padding: 10px 20px;
  cursor: pointer;
  color: var(--light-grey);
  font-size: 16px;
  transition: 0.2s ease-in-out;

  &:hover {
    border: 1px solid var(--grey);
    color: var(--grey);
  }
}

.add-stop {
  background-color: var(--light-grey-hovered-2);
  width: 100%;
  color: var(--royal-green);
  border-radius: 50px;
  height: fit-content;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: var(--light-grey-hovered);
  }
}

.remove-stop {
  background-color: var(--light-grey-hovered-2);
  width: 100%;
  color: var(--royal-red);
  border-radius: 50px;
  border: none;
  height: fit-content;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: var(--light-grey-hovered);
  }
}

.outlined-non-responsive {
  background-color: transparent;
  width: 100%;
  border: 1px solid var(--royal-blue);
  border-radius: 50px;
  height: fit-content;
  padding: 7px 17px;
  cursor: pointer;
  color: var(--royal-blue);
  font-size: 12px;
  font-weight: 600;
  transition: 0.2s ease-in-out;

  @media screen and (max-width: 768px) {
    font-size: 10px;
  }

  &:hover {
    background: var(--light-grey-hovered);
  }
}

.neutral-non-responsive {
  background-color: transparent;
  width: 100%;
  border: 1px solid var(--light-grey);
  border-radius: 50px;
  height: fit-content;
  padding: 10px 20px;
  cursor: pointer;
  color: var(--light-grey);
  font-size: 16px;
  transition: 0.2s ease-in-out;

  &:hover {
    border: 1px solid var(--grey);
    color: var(--grey);
  }
}

.icon {
  margin-right: 8px; /* Adjust the spacing as needed */
}

.sendButton {
  background: var(--royal-blue);
  display: flex;
  height: 50px;
  width: 50px;
  align-content: center;
  align-items: center;
  padding: 5px;
  color: white;
  justify-content: center;
  border: none;
  border-radius: 10px;
  transition: 0.2s ease-in-out;
}

.square-non-responsive {
  background: var(--royal-green);
  display: flex;
  align-content: center;
  align-items: center;
  max-width: 250px;
  width: 100%;
  font-size: 16px;
  padding: 10px 20px;
  color: white;
  justify-content: center;
  border: none;
  border-radius: 10px;
  transition: 0.2s ease-in-out;

  &:hover {
    background: var(--royal-green-hovered);
  }
}

.sendButton:hover {
  background: var(--royal-blue-hovered);
}

.wait {
  display: flex;
  width: fit-content;
  padding: 10px 20px;
  align-content: center;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50px;
  background: var(--dark);
  transition: 0.2s ease-in-out;
}

.grey-responsive {
  display: flex;
  width: fit-content;
  padding: 10px 20px;
  align-content: center;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  transition: 0.2s ease-in-out;
  font-size: 16px;
  border-radius: 55px;
  color: var(--royal-blue);

  &:hover {
    background: var(--light-grey-hovered);
  }
}

.grey {
  display: flex;
  width: fit-content;
  padding: 10px 20px;
  align-content: center;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  transition: 0.2s ease-in-out;
  font-size: 16px;
  border-radius: 55px;
  color: var(--royal-blue);

  &:hover {
    background: var(--light-grey-hovered);
  }
}

.darkGrey-100 {
  display: flex;
  width: 100%;
  padding: 10px 20px;
  align-content: center;
  align-items: center;
  justify-content: center;
  border: none;
  background: var(--grey-darker);
  transition: 0.2s ease-in-out;
  font-size: 16px;
  border-radius: 55px;
  color: var(--dark-grey);

  &:hover {
    background: var(--light-grey-hovered);
  }
}

.close {
  display: flex;
  width: fit-content;
  padding: 10px 20px;
  align-content: center;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  transition: 0.2s ease-in-out;
  font-size: 16px;
  border-radius: 55px;
  color: var(--royal-red);

  &:hover {
    background: var(--light-grey-hovered);
  }
}

.rounded {
  height: 40px;
  width: 40px;
  position: absolute;
  left: 3%;
  border-radius: 50px;
  background: var(--light-grey-hovered-2);
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  border: none;
  display: none;
  transition: 0.2s ease-in-out;

  &:hover {
    background: var(--light-grey-hovered);
  }

  @media screen and (max-width: 768px) {
    display: flex;
  }
}

.attachMaterial {
  background: transparent;
  display: flex;
  height: 50px;
  width: 55px;
  align-content: center;
  align-items: center;
  padding: 10px 20px;
  justify-content: center;
  border: none;
  border-radius: 50px;
  transition: 0.2s ease-in-out;
}

.attachMaterial:hover {
  background: var(--light-grey-hovered);
}

@media screen and (max-width: 768px) {
  .default {
    padding: 6px 10px 6px 10px;
  }

  .apply {
    padding: 7px 13px;
    font-size: 13px;
  }

  .grey-responsive {
    padding: 7px 13px;
    font-size: 1rem;
  }

  .filter {
    padding: 7px;
    font-size: 13px;
  }

  .neutral {
    padding: 6px 10px 6px 10px;
  }
}

