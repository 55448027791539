.dashboard-content-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-content: center;
    align-items: center;
    justify-items: center;
    border-bottom: 1px solid var(--light-grey);
    padding: 15px;
    background: var(--royal-white);
}

.dashboard-content-title {
    color: var(--royal-white);
    font-size: 2.5rem;
    background: linear-gradient(to right, var(--royal-blue), var(--royal-blue-hovered));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.dashboard-content-subtitle {
    color: var(--light-grey);
    font-size: 2rem;
    font-weight: 300;
}

.account-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 650px;
    height: 100%;
}

.dashboard-account-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 350px;
    margin: 0 0 0 20px;
}

.dashboard-searchbar {
    background: none;
    display: flex;
    width: 300px;
    height: fit-content;
    padding: 7px 15px 7px 15px;
    border-radius: 15px;
    justify-content: start;
    gap: 15px;
    justify-items: center;
    align-items: center;
    font-weight: bold;
    color: var(--grey);
    border: 1px solid var(--light-grey);
    font-size: 1.8rem;
    transition: 0.2s ease-in-out;
}

.dashboard-searchbar input::placeholder {
    color: var(--light-grey);
    font-size: 1.8rem;
}

.dashboard-searchbar:hover {
    transition: 0.2s ease-in-out;
    border: 1px solid var(--royal-blue);
}

.dashboard-searchbar:active {
    transition: 0.2s ease-in-out;
    border: 2px solid var(--royal-blue);
}

.search-icon-searchbar {
    cursor: pointer;
    font-size: 2rem;
    font-weight: bold;
}

.search-icon-searchbar:hover {
}


.dashboard-searchbar-expanded {
    display: flex;
    width: 600px;
    height: 55px;
    padding: 0 15px 0 17px;
    border-radius: 40px;
    justify-content: start;
    justify-items: center;
    align-items: center;
    gap: 10px;
    font-weight: bold;
    background: white;
    color: var(--grey);
    border: 1px solid var(--light-grey);
    font-size: 1.8rem;
    transition: 0.2s ease-in-out;
}

.dashboard-searchbar-expanded input::placeholder {
    color: var(--light-grey);
    font-size: 1.8rem;
}

.bars-button {
    display: none;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 40px;
    font-size: 1.8rem;
    transition: 0.2s ease-in-out;
    border: none;
}

.bars-button:hover {
    background: var(--search-bar-background);
    transition: 0.2s ease-in-out;
}

.dashboard-searchbar-expanded:hover {
    transition: 0.2s ease-in-out;
    border: 1px solid var(--royal-blue);
}

.dashboard-searchbar-expanded:active {
    transition: 0.2s ease-in-out;
    border: 2px solid var(--royal-blue);
}


.account-info {
    display: flex;
    border-radius: 40px;
    width: 220px;
    transition: 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
        background: var(--light-grey-hovered);
        transition: 0.2s ease-in-out;
    }
}

.dashboard-account-info-text {
    text-decoration: none;
    height: 50px;
    display: flex;
    margin: 0 0 0 5px;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
}

.account-info-buttons {
    border-radius: 40px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    transition: 0.2s ease-in-out;
}

.account-info-buttons:hover {
    background: var(--light-grey-hovered);
    transition: 0.2s ease-in-out;
}

.header-dashboard-account-info-name {
    font-size: 1.6rem;
    font-weight: 500;
    margin: 0;
    color: var(--grey);
}

.user-header-avatar {
    border-radius: 50%;
    object-fit: cover;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
}

.header-dashboard-account-info-role {
    font-size: 1.3rem;
    margin: 0;
    font-weight: 400;
    color: var(--light-grey);
}

.search-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
}

.search-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    padding: 20px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translate(-50%, -40%);
        opacity: 0;
    }
    to {
        transform: translate(-50%, -50%);
        opacity: 1;
    }
}

.suggestions-list {
    list-style: none;
    height: 200px;
    margin: 10px 0 0 0;
    overflow: scroll;
    overflow-x: hidden;
    border: none;
    color: var(--grey);
    background-color: white;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    z-index: 1001;
}

.suggestion-link {
    text-decoration: none;
    color: var(--grey);
    display: flex;
}

.suggestions-list.hidden {
    visibility: hidden;
}

.suggestions-list li {
    padding: 10px;
    border-radius: 8px;

    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.searchbar-button-mobile{
    display: none;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    font-size: 1.8rem;
    transition: 0.2s ease-in-out;
    border: none;
}

.searchbar-button-mobile:hover{
    background: var(--light-grey-hovered);
    transition: 0.2s ease-in-out;
}

.suggestions-list li h2 {
    font-size: 16px;
    color: var(--grey);
    width: 100%;
    margin: 0 0 0 15px;
    border-bottom: 1px solid var(--light-grey-hovered);
    padding: 0 0 7px 0;
}

.suggestions-list li:hover {
    background-color: #f0f0f0;
}

/* Hide scrollbar track and only show thumb for WebKit browsers */
.suggestions-list::-webkit-scrollbar {
    width: 5px;
    margin: 0 10px 0 0;
}

.suggestions-list::-webkit-scrollbar-track {
    background: transparent; /* Makes track invisible */
}

.suggestions-list::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.25); /* Color of the scrollbar thumb */
    border-radius: 6px; /* Optional: Rounds the corners of the scrollbar thumb */
}

@media screen and (max-width: 1300px) {
    .account-info-buttons {
        display: none;
    }

    .account-container {
        max-width: 350px;
        width: 100%;
    }

    .dashboard-account-info {
        width: fit-content;
    }

    .account-info {
        width: fit-content;
    }

    .dashboard-account-info-text {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .dashboard-content-header{
        padding: 5px;
    }

    .dashboard-searchbar-expanded{
        width: 350px;
    }
}

@media screen and (max-width: 600px) {

    .searchbar-button-mobile{
        display: flex;
    }

    .account-container {
        width: fit-content;
    }

    .dashboard-content-title {
        display: none;
    }

    .dashboard-searchbar {
        display: none;
    }

    .bars-button {
        display: flex;
    }

    .user-header-avatar{
        width: 45px;
        height: 45px;
    }
}

