.take-load-bid-container-wrapper {
    width: 100%;
    height: fit-content;
    padding: 20px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    border: 1px solid var(--light-grey);
    transition: height 0.2s ease-in-out;
    cursor: pointer;

    @media screen and (max-width: 1028px) {
        display: none;
    }
}

.take-load-bid-container-wrapper-mobile{
    display: none;
    justify-content: space-between;
    background: var(--royal-white);
    padding: 20px;
    border-radius: 20px;
    border: 1px solid var(--light-grey);

    @media screen and (max-width: 1028px) {
        display: flex;
        flex-direction: column;
    }
}

.tale-load-bid-wrapper-mobile.expanded{
    height: auto;
}

.take-load-bid-container-wrapper.expanded {
    height: auto; /* Adjust this as needed */
}

.take-load-bid-container-short-info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    opacity: 0;
    animation: fadeIn 0.5s forwards;
}

.take-load-content-mobile{
    display: flex;
    gap: 10px;
    flex-direction: column;
    width: 60%;
}

.load-container-bid-more-info-mobile{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin: 20px 0 0 0;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.google-map-container{
    height: 200px;
    width: 100%;

}

.load-item-wrap{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.take-load-title{
    color: var(--royal-blue);
    font-size: 2rem;
    font-weight: bold;
}

.load-credentials{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.take-load-bid-container-wrapper:hover {
    background: var(--light-grey-hovered-transparent);
    transition: 0.2s ease-in-out;
    border: 1px solid var(--royal-blue);
}

.take-load-bid-container-wrapper:active {
    background: var(--light-grey-hovered);
    transition: 0.1ms ease-in-out;
    transform: scale(0.99);
}

.take-load-bid-container-content span label {
    color: var(--grey);
    font-size: 1.2rem;
    font-weight: bold;
}

.take-load-bid-container-content span h3 {
    color: var(--royal-blue);
    font-size: 2rem;
    font-weight: bold;
}

.take-load-bid-container-content span h1 {
    color: var(--royal-green);
    font-size: 2.3rem;
    font-weight: bold;
}

.instant-book-load {
    height: fit-content;
    display: flex;
    gap: 15px;
    flex-direction: column;
    align-content: center;
    text-align: center;
    justify-content: space-between;
    width: fit-content;

    @media screen and (max-width: 1028px) {
        width: 40%;
    }
}

.more-info-button {
    background: none;
    border: none;
    color: var(--royal-blue);
    font-weight: bold;
    font-size: 1.5rem;
    display: flex;
    margin: 15px 0 0 0;
    align-content: center;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    transition: 0.2s ease-in-out;
}

.more-info-button:hover {
    background: var(--light-grey-hovered);
    transition: 0.2s ease-in-out;
}

.more-info-icon-down {
    margin: 0 5px;
}

.more-info-icon-down.rotated {
    transform: rotate(180deg);
}

.bid-button {
    background: var(--royal-green);
    color: var(--royal-white);
    font-size: 1.5rem;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    padding: 7px;
    border: none;
    width: 100%;
    text-decoration: none;
    margin: 10px 0 0 0;
    border-radius: 30px;
    transition: 0.2s ease-in-out;
}

.bid-button:hover {
    background: var(--royal-green-hovered);
    transition: 0.2s ease-in-out;
}

.load-short-info {
    height: 150px;
    display: flex;
    flex-direction: column;
    width: 150px;
    justify-content: space-between;
}

.load-short-info span label {
    color: var(--grey);
    font-size: 1.3rem;
    font-weight: bold;
}

.load-short-info span h3 {
    color: var(--royal-blue);
    font-size: 1.8rem;
    font-weight: bold;
}

.load-container-bid-more-info {
    display: flex;
    width: 100%;
    padding: 20px;
    justify-content: space-between;
    height: fit-content;
    margin: 0 0 20px 0;
}

.bid-popup-overlay {
    position: fixed;
    top: 0;
    z-index: 999;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.bid-popup {
    width: 900px;
    height: 600px;
    background: white;
    border-radius: 10px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
}

.bid-popup-header {
    justify-content: space-between;
    height: fit-content;
    display: flex;
    padding: 20px;
    align-content: center;
    justify-items: center;
    align-items: center;

    border-bottom: 1px solid var(--light-grey);
}

.bid-popup-header h2 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.bid-popup-content {
    display: flex;
    width: 600px;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

.bid-description {
    width: 90%;
}

.bid-description h3 {
    font-size: 2rem;
    color: var(--dark-grey);
}

.bid-description p {
    font-size: 1.5rem;
    color: var(--grey);
}

.bid-popup-value-inputs {
    justify-content: space-between;
    display: flex;
    gap: 10px;
}

.submit-bid-button{
    display: flex;
    margin: 0 auto;
    padding: 10px 15px 10px 15px;
    width: fit-content;
    background: var(--royal-green);
    border-radius: 50px;
    border: none;
    font-size: 15px;
    font-weight: bold;
    transition: 0.2s ease-in-out;
}

.submit-bid-button:hover{
    background: var(--royal-green-hovered);
}