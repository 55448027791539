.loadStatus {
  display: flex;
  width: fit-content;
  border-radius: 12px;
  font-size: 14px;
  color: var(--royal-white);
  padding: 5px 12px 5px 12px;
}

.published {
  background: var(--royal-yellow);
}

.active {
  background: var(--royal-green);
}

.delivered{
  background: var(--light-grey-hovered);
  color: var(--royal-green);
}

.booked{
  background: var(--orange);
}

.completed {
  background: var(--royal-blue);
}

.cancelled {
  background: var(--royal-red);
}