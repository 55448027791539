.wrapper{
  display: flex;
  width: 100%;
  margin: 60px 0;
}

.image {
  width: 100%;
  height: 100%;
  user-select: none;
  max-width: 1440px;
  margin: 0 auto;
  object-fit: contain;
  object-position: center;
  border-radius: 25px;
}