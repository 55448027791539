.floating-window-component-failed{
    z-index: 1000;
    background: #b72d00;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    font-weight: bold;
    color: #fff;
    font-size: 1.6rem;
    border-radius: 8px;
    padding: 16px;
    text-align: center;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    animation: slide-up 0.5s forwards;
}

@keyframes slide-down {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes slide-up {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-200px);
        opacity: 0;
    }
}