.loadboard-load-container-wrapper {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 200px;
    text-decoration: none;
    border: 1px solid var(--light-grey);
    border-radius: 45px;
    cursor: pointer;
    background: var(--royal-white);
    margin: 10px 0 10px 0;
    transition: 0.2s ease-in-out;
}

.loadboard-load-container-wrapper-mobile {
    display: none;
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    text-decoration: none;
    border: 1px solid var(--light-grey);
    border-radius: 15px;
    padding: 10px 15px 10px 15px;
    cursor: pointer;
    background: var(--royal-white);
    margin: 10px 0 10px 0;
    transition: 0.2s ease-in-out;
}

.load-quotes-circle {
    position: absolute;
    top: -5px;
    left: -1px;
    width: 30px;
    height: 30px;
    background-color: red;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.loadboard-load-container-wrapper:hover {
    transition: 0.2s ease-in-out;
    border: 1px solid var(--royal-blue);
    background-color: var(--light-grey-hovered-transparent);
    transform: scale(1.0);
}

.loadboard-load-container-wrapper:active {
    transition: 0.1s ease-in-out;
    border: 2px solid var(--royal-blue);
    background-color: var(--light-grey-hovered-transparent);
    transform: scale(0.98);
}

.loadboard-load-container-info {
    display: flex;
    width: 50%;
    margin: 0 0 0 30px;
    justify-content: space-between;
    height: 100%;
}

.loadboard-load-info-main {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    height: 94%;
}

.loadboard-load-status {
    margin: 10px 0 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: fit-content;
    justify-items: center;
    padding: 2px;
    border-radius: 33px;
    background: var(--load-credentials-background);
}

.loadboard-status-text {
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: var(--load-credentials-font-color);
    margin: 0 5px 0 5px;
}

.loadboard-status-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--royal-green);
    margin: 0 0 0 5px;
}

.load-container-title {
    color: var(--dark-grey);
    font-size: 2rem;
    font-weight: bold;
}

.loadboard-load-price {
    color: var(--royal-green);
    font-size: 1.8rem;
    font-weight: bold;
}

.loadboard-load-direction-wrapper {
    display: flex;
    align-items: center;
}

.loadboard-load-direction-wrapper-mobile {
    display: flex;
    align-items: center;
    margin: 10px 0 0 0;
}

.loadboard-load-direction {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    height: 85%;
}

.loadboard-load-direction-origin {
    margin: 5px 0 0 0;
    display: flex;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-items: center;
}

.loadboard-load-direction-origin h3 {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.loadboard-load-direction-origin p {
    font-size: 1.2rem;
    font-weight: normal;
    color: var(--light-grey);
    margin: 0 0 0 5px;
}

.loadboard-load-direction-destination {
    margin: 5px 0 0 0;
    display: flex;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-items: center;
}

.loadboard-load-direction-destination h3 {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.loadboard-load-direction-destination p {
    font-size: 1.2rem;
    font-weight: normal;
    color: var(--light-grey);
    margin: 0 0 0 5px;
}

.loadboard-load-info-additional {
    display: flex;
    width: 350px;
    align-content: start;
    justify-content: space-between;
    margin: 0 30px 0 0;
}

.map-load-section-load-container {
    width: 50%;
    height: 100%;
}

.loadboard-carrier-load-logo {
    width: 200px;
    height: 150px;
}

.loadboard-credentials-mobile {
    display: none;
    align-content: center;
    gap: 5px;
    justify-content: space-between;
}

.loadboard-credentials {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    padding: 0 0 20px 0;
}

.loadboard-credentials-mobile span {
    color: var(--load-credentials-font-color);
    font-size: 1.1rem;
    font-weight: normal;
    display: inline-block;
    width: fit-content;
    padding: 5px 10px 5px 10px;
    text-align: center;
    border-radius: 5px;
    background: var(--load-credentials-background);
}

.loadboard-credentials span {
    padding: 7px 12px 7px 12px;
    margin: 10px 3px 10px 3px;
    color: var(--load-credentials-font-color);
    font-size: 1.4rem;
    font-weight: bold;
    display: inline-block;
    width: fit-content;
    text-align: center;
    border-radius: 30px;
    background: var(--load-credentials-background);
}

@media screen and (max-width: 1360px) {
    .map-load-section-load-container {
        display: none;
    }

    .loadboard-load-container-info {
        width: 100%;

    }

    .loadboard-load-container-wrapper {
        height: fit-content;
        border-radius: 20px;
    }

}

@media screen and (max-width: 768px) {
    .loadboard-load-direction-origin {
        display: flex;
        text-align: left;
        align-content: start;
        justify-content: start;
        justify-items: start;
        align-items: start;
    }

    .loadboard-load-direction-origin h3 {
        font-size: 1.5rem;
    }

    .loadboard-load-direction-origin p {
        font-size: 1rem;
    }

    .loadboard-load-direction-destination {
        display: flex;
        text-align: left;
        align-content: start;
        justify-content: start;
        justify-items: start;
        align-items: start;
    }

    .loadboard-load-direction-destination h3 {
        font-size: 1.5rem;
    }

    .loadboard-load-direction-destination p {
        font-size: 1rem;
    }

    .loadboard-credentials {
        display: none;
    }

    .loadboard-load-info-main {
        width: 100%;
    }

    .loadboard-credentials-mobile {
        display: flex;
        margin: 0;
    }

    .loadboard-load-price {
        display: none;
    }

    .loadboard-load-container-wrapper-mobile {
        display: flex;
    }

    .loadboard-load-container-wrapper {
        display: none;
    }

    .loadboard-load-container-info {
        margin: 0;
        width: 100%;
    }

}
