.load-container-content {
    display: flex;
    padding: 35px;

    @media screen and (max-width: 768px) {
        padding: 10px;
    }
}

.assigned-load-info {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
}

.assigned-load-info-label {
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
    color: var(--royal-green);
    background: var(--light-grey-hovered);
    padding: 10px;
    font-weight: bold;
    border-radius: 5px;
}

.assigned-load-info-assigned {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.assigned-load-info-assigned-content{
    margin: auto 0 0 0;
    width: 100%;
    gap: 10px;
    display: flex;
    flex-direction: column;
}