.container {
  display: flex;
  height: 100vh;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    height: 60vh;
    padding: 0 20px;
  }

  h1 {
    display: flex;
    font-size: 5rem;
    flex-direction: column;
    color: var(--royal-green);
    font-weight: bold;
    gap: 10px;

    span {
      color: var(--grey);
      font-size: 2rem;
      font-weight: normal;
    }

    @media screen and (max-width: 768px) {
        font-size: 3rem;

        span{
            font-size: 1.5rem;
        }
    }
  }
}