* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

.sign-in-wrapper {
    display: flex;
}

.left-side {
    display: flex; /* Make the left-side a flex container */
    justify-content: center; /* Center children horizontally */
    align-items: center; /* Center children vertically */
    flex-direction: column; /* Stack children vertically */
    max-width: 700px;
    width: 100%;
    background-color: #ffffff;
}

.sign-up-right-side {
    width: 100vw;
    height: 100vh;
    background: url("../../assets/images/background-image.svg") no-repeat center;
    background-size: cover;
    align-content: center;
    justify-content: center;
    text-align: center;
    display: flex;
    align-items: center;

}

.label-text {
    color: #343541;
    font-weight: 500;
    font-size: 1.2rem;
}

.question-div {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.divider {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 20px 0;
}

.divider .line {
    flex: 1;
    height: 1px;
    background-color: #707070; /* Adjust the color as needed */
}

.divider .text {
    padding: 0 10px;
    font-size: 16px;
    color: var(--dark-grey);
}

.form-divider {
    width: 100%;
    height: auto;
    display: flex;

}

.is-carrier {
    display: flex;
    margin: 0 10px 0 10px;
    justify-content: center;
    align-items: center;
    background: #e3e3e3;
    text-decoration: none;
    color: #313131;
    padding: 10px;
    font-size: 1.7rem;
    font-weight: 600;
    border-radius: 10px;
}

.is-customer {
    display: flex;
    margin: 0 10px 0 10px;
    justify-content: center;
    align-items: center;
    background: #f66711;
    text-decoration: none;
    color: #f1f1f1;
    padding: 10px;
    font-size: 1.7rem;
    font-weight: 600;
    border-radius: 10px;
}

.sign-in-link {
    color: var(--royal-blue);
    font-weight: 600;
    font-size: 1.5rem;
    margin-left: 5px;
    text-decoration: none;
}

.input-field-name, .input-field-second-name, .input-field-email, .input-field-password, .input-field-phone-number {
    position: relative;
    margin: 10px 0 10px 0;
    background-color: #f2f2f2;
    color: #343541;
    height: 5vh;
    font-weight: 500;
    font-size: 1.5rem;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #d7d7d7;
    max-width: 500px;
}

.input-field:hover {
    background-color: #f0f0f0;
    border: 1px solid #a2a2a2;
    outline: none;
}

.sign-up-custom-form {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 500px;
    width: 100%;
    margin: 0 15px 0 15px;
    padding: 0 15px 0 15px;
}

.registration-block-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.registration-block {
    position: relative;
    width: 48%;
}

.sign-up-custom-form p {
    font-size: 1.2rem;
    margin: 20px 0 20px 0;
    font-weight: 500;
    color: #6b6b6b;
}

.login-with-google-button {
    display: flex;
    justify-content: center;
    margin: 20px 0 20px 0;
}

.sign-up-button {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 190px;
    width: 100%;
    height: 50px;
    background: var(--royal-blue);
    padding: 10px 20px;
    font-weight: bold;
    font-size: 1.5rem;
    border-radius: 60px;
    cursor: pointer;
    border: none;
    justify-items: center;
    align-content: center;
    margin: 10px auto;
    transition: 0.3s ease-in-out;
}

.sign-up-button:hover {
    transition: 0.3s ease-in-out;
    background: var(--royal-blue-hovered);
}

.sign-in-link-sign-up {
    text-decoration: none;
    font-size: 1.3rem;
    margin: 0 0 0 10px;
    font-weight: bold;
    color: var(--royal-blue);
}

.h2-title-sign-up {
    font-size: 3rem;
    margin: 0 0 5px 0;
    font-weight: 600;
    color: var(--royal-blue);
}

.h3-title-sign-up {
    font-size: 1.5rem;
    margin: 0 0 20px 0;
    font-weight: 500;
    color: var(--grey);
}

.recaptcha-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0 15px 0;
    transform: scale(1);
    position: relative;
    border-radius: 55px;
}

.logo-blue-sign-up{
    height: auto;
    width: 150px;
    display: flex;
    margin: 0 auto;
}


@media only screen and (max-width: 768px) {
    .sign-up-right-side {
        display: none;
    }
    .logo-blue-sign-up{
        display: none;
    }
}
