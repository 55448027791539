.slider-content-wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 370px;
    height: 300px;
    border-radius: 6px;
}

.slider-navigation{
    display: flex;
    justify-content: space-between;
    width: 320px;
    margin-top: 10px;
}

.main-image{
    width: 100%;
    border-radius: 6px;
    height: 225px;
    object-fit: cover;
}

.thumbnail{
    width: 55px;
    height: 45px;
    object-fit: cover;
    border: 1px solid var(--light-grey);
    border-radius: 6px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.thumbnail:hover{
    border: 1px solid var(--royal-blue);
}

.slider-nav-button{
    height: 100%;
    border-radius: 8px;
    padding: 0 10px;
    border: 1px solid var(--light-grey);
    color: var(--royal-blue);
    transition: 0.2s ease-in-out;
}

.slider-nav-button:hover{
    background-color: var(--light-grey);
}