* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    overflow-x: hidden;

}

.landing-page-wrapper-body {
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-logo {
    max-width: 200px;
    width: 100%;
    min-width: 180px;
}

.landing-page-header {
    position: relative;
    padding: 0 45px 0 45px;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-size: 2rem;
    width: 100%;
}

.nav-menu-container {
    display: flex;
    justify-content: space-between;
    width: 800px;
    border: none;
}

.drop-down-icon {
    margin: 0 0 0 5px;
    transition: 0.2s ease-in-out;
}

.rotate-icon {
    transform: rotate(180deg);
    transition: transform 0.2s;
}

.nav-menu-container a {
    color: var(--dark-grey);
    text-decoration: none;
    font-size: 2rem;
    font-weight: bold;
    transition: 0.2s ease-in-out;
    border-radius: 40px;
    padding: 9px;
    width: 350px;
    justify-content: space-between;
}

.nav-menu-container a:hover .drop-down-icon {
    transform: rotate(180deg);
    transition: 0.2s ease-in-out;
}

.landing-button {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 200px;
    width: 100%;
    min-width: 180px;
    height: 50px;
    background: var(--royal-blue);
    color: white;
    font-size: 1.9rem;
    font-weight: bold;
    border-radius: 30px;
    text-decoration: none;
    border: none;
    transition: 0.2s ease-in-out;
}

.landing-button:hover {
    background: var(--royal-blue-hovered);
    transition: 0.2s ease-in-out;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 120px;
    margin: 5px 0;
    border-radius: 20px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    text-align: left;
    color: black;
    padding: 12px 16px;
    margin: 12px;
    text-decoration: none;
    display: block;
}

.nav-menu-container a:hover .dropdown-content {
    display: block;
}

.landing-page-footer-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    background: #f1f3f4;
    color: var(--dark-grey);
    align-items: center;
    width: 100%;
}

.footer-section-upper {
    display: grid;
    grid-template-columns: repeat(4, 4fr);
    align-items: center;
    width: 100%;
    margin: 0 auto;
    max-width: 1920px;
    gap: 20px;
    align-content: center;
    justify-items: center;
    justify-content: space-between;
    height: fit-content;
    padding: 40px;
}

.footer-section-content {
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: left;
    justify-content: space-between;
    width: fit-content;
    height: 275px;
    color: var(--dark-grey);
}

.footer-section-content h3 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #939393;
}

.footer-section-link {
    color: #939393;
    font-size: 1.7rem;
    font-weight: normal;
    text-decoration: none;
    transition: 0.2s ease-in-out;
    cursor: pointer;
}

.footer-section-link:hover {
    text-decoration: underline;
}

.footer-section-content-newsletter {
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: left;
    justify-content: space-between;
    width: fit-content;
    height: 275px;
}

.footer-section-content-newsletter p {
    font-size: 1.7rem;
    font-weight: normal;
    color: #939393;
}

.footer-input-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 350px;
    border-radius: 30px;
    border: 2px solid var(--grey-darker);
    padding: 5px 5px 5px 25px;
    background: var(--royal-white);
}

.footer-input-wrapper input {
    border: none;
    font-size: 1.5rem;
    font-weight: bold;
}

.footer-input-wrapper button {
    border: none;
    background: var(--light-grey);
    font-weight: bold;
    color: var(--royal-white);
    font-size: 1.7rem;
    border-radius: 30px;
    padding: 15px;
    transition: 0.2s ease-in-out;
}

.footer-input-wrapper button:hover {
    background: var(--royal-green);
    transition: 0.2s ease-in-out;

}

.footer-section-content h3 {
    font-size: 2.5rem;
    font-weight: bold;
}

.footer-section-bottom {
    background: var(--royal-white);
    width: 100%;
    height: 90px;
    align-content: center;
    align-items: center;
    justify-content: center;
    display: flex;
}

.footer-section-bottom p {
    margin: 0 auto;
    font-weight: bold;
    font-size: 2rem;
    color: #3B3B3B;
}

.contact-icons-wrapper {
    display: flex;
    justify-content: space-between;
    width: 200px;
}

.icon-container {
    color: #939393;
    font-size: 20px;
}


.left-landing-introduction-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 650px;
    color: white;
}

.right-landing-introduction-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 650px;
    color: white;
}

.left-advantage-introduction-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 650px;
    color: white;
    transition: opacity 0.5s ease-in-out;
}

.right-advantage-introduction-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 650px;
    color: white;
    transition: opacity 0.5s ease-in-out;
}

.advantage-introduction-content {
    width: 600px;
    margin: 0 40px 0 40px;

    @media screen and (max-width: 1028px) {
        max-width: 600px;
        width: 100%;
        margin: 0;
        padding: 0 20px;
    }
}

.advantage-container-title {
    font-size: 5rem;
    width: 100%;
    background: linear-gradient(to right, var(--royal-blue), var(--royal-blue-light));
    font-weight: bolder;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.get-started-button-advantage-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 50px;
    border: 1px solid var(--royal-blue);
    color: var(--royal-blue);
    font-size: 1.9rem;
    font-weight: bold;
    border-radius: 30px;
    text-decoration: none;
    transition: 0.2s ease-in-out;
}

.get-started-button-advantage-container:hover {
    background: var(--royal-blue-light-transparent);
    color: var(--royal-blue);
    transition: 0.2s ease-in-out;
}

.get-started-button-advantage-container:hover .right-arrow {
    transform: translateX(10px);
    transition: transform 0.2s ease-in-out;
}

.advantage-introduction-content p {

}

.advantage-introduction-content button {

}

.advantages-container-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 45px 0 45px;
    margin: 50px 0;
    height: fit-content;
}

.advantages-container-wrapper p {
    font-size: 2rem;
    font-weight: normal;
    color: var(--grey);
    margin: 30px 0 20px 0;
}

.advantages-select-button {
    display: flex;
}

.advantages-select-button button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
    margin: 20px 10px 20px 10px;
    height: 50px;
    color: var(--dark-grey);
    font-size: 1.9rem;
    font-weight: bold;
    border-radius: 30px;
    text-decoration: none;
    border: none;
    transition: 0.2s ease-in-out;


}

.advantages-button-selected {
    background: var(--royal-blue);
    color: var(--royal-white);

}

.advantages-select-button button.active {
    background: var(--royal-blue);
    color: var(--royal-white);
}

.advantage-component {
    transition: opacity 0.5s ease-in-out;
}

.advantages-select-button button:active {
    color: var(--royal-white);
    border: none;
    background: var(--royal-blue);
    transition: 0.2s ease-in-out;
}

.advantages-select-button button:hover {
    color: var(--royal-white);
    background: var(--royal-blue);
    transition: 0.2s ease-in-out;
}

.advantages-container-wrapper h1 {
    color: var(--royal-blue);
    font-size: 50px !important;
    font-weight: bold !important;
    cursor: pointer;
    background: linear-gradient(to right, hsl(0, 0%, 30%) 0, hsl(0, 0%, 85%) 50%, hsl(0, 0%, 30%) 100%);
    background-size: 200% 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s infinite linear;
}

@keyframes shine {
    0% {
        background-position: 100% 0;
    }
    100% {
        background-position: -100% 0;
    }
}

.landing-introduction-content {
    width: 500px;
    margin: 0 40px 0 40px;
}

.right-arrow {
    margin: 0 0 0 5px;
    color: white;
    transition: transform 0.2s ease-in-out;
}

.landing-introduction-content button:hover .right-arrow {
    transform: translateX(10px);
    transition: transform 0.2s ease-in-out;
}

.landing-introduction-content h1 {
    font-size: 4rem;
    font-weight: bold;
    color: var(--dark-grey);
}

.landing-introduction-content p {
    font-size: 2rem;
    font-weight: normal;
    margin: 20px 0 0;
    color: var(--grey);
}

.landing-image {
    border-radius: 15px;
}

.landing-introduction-content button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    border: none;
    width: 200px;
    margin: 25px 0 0 0;
    padding: 7px;
    color: var(--royal-white);
    font-weight: normal;
    font-size: 2rem;
    background: var(--royal-blue);
    transition: 0.2s ease-in-out;
}

.landing-introduction-content button:hover {
    background: var(--royal-blue-hovered);
    transition: 0.2s ease-in-out;
}

.get-started-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 650px;
}

.get-started-container-content {
    width: 1500px;
    height: 450px;
    margin: 0 auto;
    padding: 70px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    text-align: center;
    border-radius: 35px;
    background: var(--light-grey-hovered);
}

.get-started-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 450px;
    margin: 50px 0 0 0;

}

.get-started-container-content h1 {
    font-size: 6rem;
    background: linear-gradient(to right, var(--royal-blue), var(--royal-blue-light));
    font-weight: bolder;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.get-started-container-content-text span {
    font-size: 4rem;
    font-weight: normal;
    color: var(--grey);
}


.sign-up-button-get-started {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 50px;
    background: var(--royal-blue);
    color: white;
    font-size: 1.9rem;
    font-weight: bold;
    border-radius: 30px;
    text-decoration: none;
    border: none;
    transition: 0.2s ease-in-out;
}

.sign-up-button-get-started:hover {
    background: var(--royal-blue-hovered);
    transition: 0.2s ease-in-out;
}

.contact-sales-button-get-started {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 50px;
    background: none;
    color: var(--royal-green);
    font-size: 1.9rem;
    font-weight: bold;
    border-radius: 30px;
    text-decoration: none;
    border: 1px solid var(--royal-green);
    transition: 0.2s ease-in-out;
}

.contact-sales-button-get-started:hover {
    background: var(--royal-green-hovered);
    color: var(--royal-white);
    transition: 0.2s ease-in-out;
}

.card-wrapper-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    width: 100%;
    height: 900px;
}

.card-wrapper-content p {
    font-weight: normal;
    color: var(--light-grey);
    font-size: 3rem;
    margin: 0 0 100px 0;
}

.card-wrapper-content h1 {
    color: var(--royal-white);
    font-size: 50px !important;
    font-weight: bold !important;
    cursor: pointer;
    margin: 30px 0 0 0;
    background: linear-gradient(to right, var(--royal-blue) 0, hsla(0, 0%, 96%, 0.57) 50%, var(--royal-blue) 100%);
    background-size: 200% 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s infinite linear;
}

.introduction-card-wrapper {
    display: flex;

}

.introduction-card-vector {
    width: 1340px;
    height: 500px;
    border-radius: 30px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.introduction-card-vector-mobile {
    display: none;
}

.introduction-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 350px;
    height: 500px;
    position: relative;
    border-radius: 15px;
    margin: 120px 0 0 0;
    padding: 0 0 40px 0;
    color: white;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    background: linear-gradient(to bottom, var(--royal-blue) 0%, transparent 13%, var(--royal-white) 13%, var(--royal-white) 100%);
}


.introduction-card h2 {
    font-size: 3rem;
    font-weight: bold;
    color: var(--royal-blue);
    margin: 20px 0 0 0;
}

.introduction-card p {
    font-size: 2rem;
    font-weight: normal;
    color: var(--grey);
    margin: 20px 40px 0 40px;
}

.introduction-card .step-icon {
    margin: 0;
    width: 130px;
    height: 130px;
}

.illustration-card-image {
    margin: 0 auto;
    width: 200px;
    height: 200px;
}

.video-main {
    width: 100%;
    height: 100vh;
}

.video-main video {
    width: 50%;
    height: 100vh;
    object-fit: cover;
}

.video-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.introduction-project-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
    height: 300px;
    color: var(--dark-grey);
    align-content: center;
    text-align: center;
}

.introduction-text {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
    width: 500px;
    height: 100%;
}

.introduction-text h2 {
    font-size: 5rem;
    font-weight: bold;
}

.introduction-text p {
    font-size: 2rem;
    font-weight: normal;
    width: 500px;
    color: var(--light-grey-hovered);

}

.video-content h1 {
    margin: 60px 0 0 0;
    font-size: 5rem;
    font-weight: bold;
}

.video-content p {
    font-size: 4rem;
    font-weight: normal;
}

.main-slider-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 420px;
    height: 50px;
    color: white;
    font-size: 1.9rem;
    font-weight: bold;
    border-radius: 30px;
    text-decoration: none;
    border: none;
    transition: 0.2s ease-in-out;
}

.become-shipper-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 50px;
    background: var(--royal-blue);
    color: white;
    font-size: 1.9rem;
    font-weight: bold;
    border-radius: 30px;
    text-decoration: none;
    border: none;
    transition: 0.2s ease-in-out;
    margin: 0 auto;
}

.become-shipper-button:hover {
    background: var(--royal-blue-hovered);
    transition: 0.2s ease-in-out;
}

.become-carrier-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 50px;
    background: none;
    border: 1px solid var(--royal-white);
    color: var(--royal-white);
    font-size: 1.9rem;
    font-weight: bold;
    border-radius: 30px;
    margin: 0 auto;
    text-decoration: none;
    transition: 0.2s ease-in-out;
}

.become-carrier-button:hover {
    background: var(--royal-blue-light-transparent);
    border: 1px solid var(--royal-blue);
    color: var(--royal-blue-light);
    transition: 0.2s ease-in-out;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

.burger-menu-header {
    display: none;
    cursor: pointer;
    width: 50px;
    height: 50px;
    padding: 9px;
    border-radius: 20px;
}


.sidebar-landing-page {
    z-index: 1111;
    display: none;
}

.nav-menu-sidebar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.nav-menu-sidebar-container a {
    color: var(--dark-grey);
    text-decoration: none;
    font-size: 2rem;
    font-weight: bold;
    transition: 0.2s ease-in-out;
    border-radius: 40px;
    align-content: center;
    align-items: center;
    padding: 9px;
    height: 70px;
    display: flex;
    width: 100%;
    justify-content: space-between;

}

.landing-button-sidebar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 210px;
    height: 50px;
    background: var(--royal-blue);
    color: white;
    font-size: 1.9rem;
    font-weight: bold;
    border-radius: 30px;
    text-decoration: none;
    border: none;
    margin: 0 0 10px 0;
    transition: 0.2s ease-in-out;
}

.sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 15px 0 15px;
    height: 80px;
    background: var(--royal-white);
    box-shadow: 0 0 10px rgba(0, 0, 0, .09);

}

.fa-times-button-menu-header {
    width: 30px;
    height: 30px;
}


@media screen and (max-width: 1240px) {

    .introduction-text h2 {
        font-size: 3rem;
    }

    .introduction-text p {
        font-size: 3rem;
    }

    .nav-menu-container {
        display: none;
    }

    .landing-button {
        display: none;
    }

    .burger-menu-header {
        display: block;
    }

    .sidebar-dropdown-content {
        display: flex;
        position: relative;
        flex-direction: column;
        max-height: 200px;
        height: 100%;
        background-color: var(--royal-white);
        transition: 0.2s ease-in-out;
    }

    .sidebar-landing-page {
        display: flex;
        width: 0;
        position: fixed;
        flex-direction: column;
        justify-content: space-between;
        top: 0;
        right: 0;
        height: 100%;
        background-color: var(--royal-white);
        overflow-x: hidden;
        border-left: 1px solid var(--light-grey);
        transition: 0.5s;
    }

    .sidebar-landing-page.open {
        width: 350px;
        justify-content: space-between;
    }
}

@media screen and (max-width: 1000px) {

    .video-content h1 {
        margin: 60px 0 0 0;
        font-size: 4rem;
        text-align: center;
    }

    .introduction-project-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 1000px;
        color: var(--dark-grey);
        align-content: center;
        text-align: center;
    }

    .video-main {
        width: 100%;
        height: 200vh;
    }

    .video-main video {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        object-fit: cover;
    }
}


.load-types-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: center;
    text-align: center;
}

.load-types-content {
    display: flex;
    max-width: 1500px;
    width: 100%;
    align-items: center;
    padding: 0 0 20px 0;
    justify-content: space-between;
}

.load-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 255px;
    width: 255px;
    border-radius: 40px;
    transition: 0.2s ease-in-out;
    cursor: pointer;
}

.load-type h2 {
    font-size: 2rem;
    margin: 15px 0 0 0;
    font-weight: bold;
    color: var(--light-grey);
}

.load-type p {
    font-size: 1.5rem;
    font-weight: normal;
    color: var(--grey);

}

.load-type:hover {
    transition: 0.2s ease-in-out;
    background: var(--light-grey-hovered-transparent);
}

.load-type-icon {
    height: 130px;
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    width: 500px;
    height: 500px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.shipper-popup-title {
    font-size: 2rem;
    font-weight: bold;
    color: var(--dark-grey);
}

.subscribe-container-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;
    background: var(--royal-blue);
    color: white;
    align-content: center;
    text-align: center;
}

.subscribe-container h2 {
    font-size: 5rem;
    font-weight: bold;
    color: var(--royal-white);
}

.subscribe-container p {
    font-size: 2rem;
    font-weight: normal;
    color: var(--light-grey);
}

.subscribe-input-form {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    background: var(--royal-white);
    margin: 50px 0 0 0;
    border-radius: 30px;
}

.subscribe-input-form input {
    padding: 0 20px 0 20px;
    font-size: 1.7rem;
    font-weight: bold;
    border-radius: 0;
    border-right: 2px solid var(--light-grey);
}

.subscribe-input-form button {
    border: none;
    background: var(--royal-green);
    font-weight: bold;
    color: var(--royal-white);
    font-size: 1.7rem;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    padding: 15px;
    transition: 0.2s ease-in-out;
}

.subscribe-carrier-shipper-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 50px;
    background: var(--royal-blue);
    color: white;
    font-size: 1.9rem;
    font-weight: bold;
    border-radius: 30px;
    text-decoration: none;
    border: none;
    margin: 0 auto;
    transition: 0.2s ease-in-out;
}


.pre-production-title {
    font-size: 5rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.pre-production-subtitle {
    font-size: 2rem;
    font-weight: normal;
    color: var(--grey);
}

.roles-container-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 1000px;
    background: var(--royal-white);
    color: var(--dark-grey);
    align-content: center;
    text-align: center;
}

.roles-description {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: left;
    width: 500px;
    height: 100%;
}

.roles-description h2 {
    font-size: 3rem;
    font-weight: bold;
    margin: 10px 0 10px 0;
    color: var(--dark-grey);
}

.roles-description p {
    font-size: 1.7rem;
    font-weight: normal;
    margin: 10px 0 10px 0;
    width: 250px;
    color: var(--grey);
}

.roles-description h4 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.learn-more-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 50px;
    color: var(--royal-blue-light);
    border: 1px solid var(--royal-blue-light);
    font-size: 1.9rem;
    font-weight: bold;
    border-radius: 30px;
    text-decoration: none;
    margin: 20px 0 0 0;
    transition: 0.2s ease-in-out;

}

.learn-more-button:hover {
    background: var(--royal-blue);
    color: var(--royal-white);
    transition: 0.2s ease-in-out;

}

.roles-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 800px;
    height: 100%;

}

.shipper-role-container, .broker-role-container, .carrier-role-container, .dispatch-role-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-content: end;
    justify-content: end;
    width: 170px;
    height: 600px;
    border-radius: 20px;
    padding: 0 0 0 15px;
    cursor: pointer;
    flex: 0 0 auto;
    transition: width 0.3s ease-in-out;
}

.shipper-role-container {
    width: 170px;
    background: url("../../assets/images/shipper-image.jpg") no-repeat center;
    object-fit: cover;
    transition: width 0.3s ease-in-out;
}

.broker-role-container {
    cursor: pointer;
    background: url("../../assets/images/broker-image.jpg") no-repeat center;
    object-fit: cover;
    transition: width 0.3s ease-in-out;
}

.broker-role-container:hover, .shipper-role-container:hover, .carrier-role-container:hover, .dispatch-role-container:hover {
    width: 250px
}

.role-content {
    display: none;
}

.broker-role-container:hover .role-content,
.shipper-role-container:hover .role-content,
.carrier-role-container:hover .role-content,
.dispatch-role-container:hover .role-content {
    display: block;
}


.carrier-role-container {
    background: url("../../assets/images/carrier-image.jpg") no-repeat center;
    object-fit: cover;
}

.dispatch-role-container {
    background: url("../../assets/images/dispatch-image.jpg") no-repeat center;
    object-fit: cover;
}

.roles-image-wrapper img {
    width: 150px;
    height: 633px;
}


.main-benefits-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 500px;
    align-content: center;
    text-align: center;
}

.main-benefits-wrapper h1 {
    font-size: 4rem;
    font-weight: bold;
    width: 900px;
    margin: 130px 0 0 0;
    background: linear-gradient(to right, var(--royal-blue), hsl(0, 0%, 85%) 50%, var(--royal-blue) 100%);
    background-size: 200% 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s infinite linear;
}

.benefit-container-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;
    align-content: center;
    text-align: center;
}

.benefit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 800px;
    justify-content: space-between;
    height: 240px;
    margin: 50px 20px 40px 20px;
    padding: 20px;
    text-align: center;
}

.benefit-container-icon {
    width: 100px;
    height: 100px;

}

.benefit-container h2 {
    font-size: 2.1rem;
    font-weight: bold;
    width: 300px;
    color: var(--royal-blue);
}

.benefit-container p {
    font-size: 1.7rem;
    width: 300px;
    font-weight: normal;
    color: var(--grey);
}


.sliders-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
    height: 2100px;
    align-content: center;
    text-align: center;
}

.first-slider-wrapper {
    display: flex;
    position: relative;
    align-items: center;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    width: 1800px;
    height: 800px;
    color: white;
    text-align: center;
}

.second-slider-wrapper {
    display: flex;
    position: relative;
    align-items: center;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    width: 1800px;
    height: 800px;
    color: white;
    text-align: center;
}

.sliders-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-content: center;
    text-align: center;

}

.first-slider-wrapper h1, .second-slider-wrapper h1 {
    font-size: 4rem;
    font-weight: bold;
    margin: 60px 0 0 0;
    background: linear-gradient(to right, var(--royal-blue), var(--royal-blue-light));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.first-slider-wrapper p, .second-slider-wrapper p {
    font-size: 3rem;
    font-weight: normal;
    color: var(--grey);
    margin: 20px 0 20px 0;
}

.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
}

.swiper-slide img {
    display: block;
    width: 100%;
}

.slide-item {
    background: url("../../assets/images/shipper-image.jpg") no-repeat center;
}

.slider-button {
    background: url("../../assets/images/custom-arrow-left.svg") no-repeat center;
}

.slider-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-content: center;
    text-align: center;

}

.navigation-slider-wrapper {
    display: flex;
    width: 150px;
    justify-content: space-between;
}


.navigation-slides-icon {
    margin: 30px 0 0 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.navigation-slides-icon:hover {
    transition: 0.2s ease-in-out;
    transform: scale(1.2);
}


.how-it-works-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 1000px;
    background: var(--royal-white);
    color: var(--dark-grey);
    align-content: center;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .footer-section-upper {
        display: grid;
        grid-template-columns: repeat(2, 2fr);
        align-content: start;
        justify-content: start;
        justify-items: start;
        align-items: start;
        gap: 50px;
    }

    .left-landing-introduction-container {
        height: 850px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        text-align: center;
        flex-direction: column-reverse;
    }

    .right-landing-introduction-container {
        height: 850px;
        width: 100%;
        display: flex;
        text-align: center;
        flex-direction: column;
    }
}

@media screen and (max-width: 585px) {
    .footer-section-upper {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        align-content: center;
        justify-content: center;
        justify-items: center;
        align-items: center;
        text-align: center;
        gap: 20px;
    }

    .footer-section-content {
        text-align: center;
    }

    .footer-section-link {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .footer-section-content-newsletter {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 30px;
    }
}


@media screen and (max-width: 510px) {

    .slider-wrapper {
        width: 500px;
    }

    .roles-image-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        overflow-x: scroll;
    }

    .shipper-role-container, .broker-role-container, .carrier-role-container, .dispatch-role-container {
        width: calc(50% - 10px);
        margin: 0 10px 0 10px;
    }

    .footer-section-content {
        margin: 20px 0 0 0;
        align-items: center;
        width: 100%;
    }

    .footer-section-content-newsletter {
        align-content: center;
        align-items: center;
        justify-content: space-between;
        margin: 40px 0 0 0;
        width: 100%;
    }

    .landing-page-footer-wrapper {
        display: flex;
        height: fit-content;
        flex-direction: column;
        justify-content: space-between;
    }

    .roles-image-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 1600px;
        overflow-x: auto;
        white-space: nowrap;
    }

    .roles-container-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 1000px;
        background: var(--royal-white);
        color: var(--dark-grey);
        align-content: center;
        text-align: center;
    }

    .roles-description {
        width: 100%;
        align-content: center;
        justify-content: center;
        text-align: center;
    }

    .roles-description p {
        padding: 10px;
        width: 100%;
    }

    .learn-more-button {
        width: 100%;

    }

    .introduction-card-vector {
        display: none;
    }

    .introduction-card-vector-mobile {
        display: block;
        height: 1200px;
        width: 500px;
    }

    .first-slider-wrapper h1,
    .second-slider-wrapper h1 {
        font-size: 2.5rem;
    }

    .first-slider-wrapper p,
    .second-slider-wrapper p {
        font-size: 2rem;
    }

    .sliders-wrapper {
        height: 2900px;
        width: 500px;
    }

    .first-slider-wrapper {
        height: 1400px;
        width: 500px;
    }

    .second-slider-wrapper {
        height: 1400px;
        width: 500px;
    }

    .sliders-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 500px;
        height: 100%;
        align-content: center;
        text-align: center;
        flex-direction: column;
    }

    .main-benefits-wrapper {
        height: 1100px;
    }

    .benefit-container-wrapper {
        height: 1500px;
        flex-direction: column;
        display: flex;
    }

    .video-main {
        width: 100%;
        height: 195vh;
    }

    .benefit-container {
        width: 100%;
        margin: 20px 0 20px 0;
        padding: 20px 0 20px 0;
    }

    .video-overlay {
        height: 100vh;
    }

    .introduction-project-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 500px;
        color: var(--dark-grey);
        align-content: center;
        text-align: center;
    }

    .introduction-text {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: 255px;
    }

    .video-main video {
        width: 100%;
        height: 50vh;
        display: flex;
        flex-direction: column;
        object-fit: cover;
    }

    .load-types-wrapper {
        position: relative;
        display: flex;
        width: 100%;
        margin: 30px 0 0 0;
        align-content: center;
        justify-content: center;
        text-align: center;
    }

    .load-types-content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }

    .load-type {
        flex: 0 0 48%;
        margin-bottom: 2%;
        width: 200px;
    }

    .load-type:hover {
        background: none;
    }

    .video-content h1 {
        font-size: 4rem;
        text-align: center;
    }

    .video-content p {
        width: 100%;
        font-size: 1.5rem;
    }

    .landing-image {
        width: 370px;
    }

    .left-landing-introduction-container {
        height: 850px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        text-align: center;
        flex-direction: column-reverse;
    }

    .landing-introduction-content {
        width: 370px;
    }

    .landing-introduction-content button {
        margin: 15px auto;
    }

    .right-landing-introduction-container {
        height: 850px;
        width: 100%;
        display: flex;
        text-align: center;
        flex-direction: column;
    }

    .main-slider-button-wrapper {
        height: 120px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .card-wrapper-content {
        height: 1600px;
    }

    .introduction-card-wrapper {
        display: flex;
        flex-direction: column;
    }

    .introduction-card-vector {
    }

    .advantages-container-wrapper {
        height: 1400px;
        text-align: center;
    }

    .advantages-select-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 0 20px 0;
    }

    .advantage-introduction-content {
        width: 430px;
    }

    .left-advantage-introduction-container {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        text-align: center;
    }

    .right-advantage-introduction-container {
        margin: 50px 0 0 0;
        display: flex;
        flex-direction: column-reverse;
        align-content: center;
        justify-content: center;
        text-align: center;
    }

    .get-started-button-advantage-container {
        margin: 15px auto;
    }

    .get-started-container {
        height: 900px;
        align-content: center;
        justify-content: center;
        text-align: center;
    }

    .get-started-container-content {
        width: 370px;
        height: 750px;
        padding: 70px;
    }

    .get-started-container-content h1 {
        font-size: 6rem;
    }

    .get-started-container-content-text span {
        font-size: 2rem;
        width: 100%;
        height: 70px;
    }

    .get-started-buttons {
        height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }


}

