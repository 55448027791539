.container {
  list-style: none;
  padding: 0 0 0 40px;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  counter-reset: item;
  position: relative;
  width: 300px;
}

.column {
  position: relative;
  display: flex;
  align-items: flex-start;
  counter-increment: item;
}

.column::before {
  content: counter(item);
  width: 25px;
  height: 25px;
  background-color: var(--royal-blue);
  color: var(--royal-white);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  border-radius: 50%;
  position: absolute;
  left: -40px; /* Adjust for bullet position */
  z-index: 1;

  @media screen and (max-width: 768px) {
    width: 20px;
    height: 20px;;
    font-size: 10px;
  }
}

.column::after {
  content: '';
  position: absolute;
  width: 2px;
  background-color: var(--royal-blue);
  top: 25px;
  bottom: -20px; /* Connects to the next item */
  left: -28px; /* Aligns with the bullet center */

  @media screen and (max-width: 768px) {
    top: 20px;
    left: -30px;
  }
}

.column:last-child::after {
  display: none; /* No line after the last item */
}

.infoContainer {
  display: flex;
  width: fit-content;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    flex-direction: row;
    align-content: center;
    gap: 10px;
    justify-items: center;
    align-items: center;
  }
}

.location {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  color: #333;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.date {
  font-size: 14px;
  margin: 0;
  color: #666;

  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
}