.choosingRoleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
  position: relative;
}

.chooseYourSideText {
  font-size: 5rem;
  color: var(--royal-white);
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background: rgba(83, 83, 83, 0.44);
  backdrop-filter: blur(10px);
  padding: 7px 25px;
  border-radius: 55px;

    @media screen and (max-width: 1028px) {
      top: 50%;
      font-size: 2rem;
      padding: 7px 15px;
      left: 50%;
    }
}

.imageBlocks {
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;


  @media screen and (max-width: 1028px) {
    flex-direction: column;
  }
}

.typewriterText {
  font-size: 3rem;
}

.chooseRoleLink {
  text-decoration: none;
  display: flex;
  width: fit-content;
  padding: 10px 25px;
  font-weight: bold;
  background: none;
  border: 1px solid var(--royal-white);
  font-size: 3rem;
  color: var(--royal-white);
  border-radius: 65px;
  transition: 0.2s ease-in-out;

  &:hover {
    border: 1px solid var(--royal-white);
    background: var(--royal-white);
    color: var(--royal-blue);
  }
}

.imageBlock {
  flex: 1;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s;
}

.imageBlock:hover {
  transform: scale(1.05);
}

.roleText {
  font-size: 13rem;
  color: var(--royal-white);

  @media screen and (max-width: 1028px) {
    font-size: 5rem;
  }
}

.typewriter {
  font-size: 3rem;
  color: var(--light-grey);


}

.headline {
  font-size: 3rem;
  color: var(--light-grey);
}

.blackout {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s;
  backdrop-filter: blur(3px);

  span {
    font-size: 3rem;
    color: var(--royal-white);

    @media screen and (max-width: 1028px) {
     align-content: center;
      justify-content: center;
      margin: 0 auto;
      text-align: center;
    }
  }
}

.imageBlock:hover .blackout {
  backdrop-filter: blur(0px);
  background-color: rgba(0, 0, 0, 0);
}