* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.load-page-content-page-section {
    display: flex;
    flex-direction: column;
    background: var(--royal-white);
    padding: 10px;
    margin: 15px;
    height: 90vh;
    overflow: auto;
    gap: 15px;
    border: 1px solid var(--light-grey);
    border-radius: 15px;
}

.carriers-bids-empty {
    margin: 50px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: lighter;
}

.shipper-dashboard-wrapper {
    height: 100dvh;
    width: 100%;
    display: flex;
    background: var(--royal-white-dark);
}

.text-message-warning {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 250px auto;
}

.shipper-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 300px;
    width: 100%;
    height: 100dvh;
    align-content: center;
    background-color: var(--royal-white);
    border-right: 1px solid var(--light-grey);
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
}

.messages-title {
    display: none;

    @media screen and (max-width: 768px) {
        display: flex;
        font-size: 2.5rem;
        font-weight: bold;
        color: var(--royal-blue);
        margin: 10px 0 20px 0;
    }
}

.chat-content {
    display: flex;
    width: 100%;
    height: 91dvh;
    justify-content: space-between;
    gap: 15px;
    padding: 0 15px;
}

.shipper-sidebar.hidden {
    transform: translateX(-250px);
    width: 0;
}

.main-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 600px;
    justify-content: space-between;
}

.link-nav-button {
    display: flex;
    width: 220px;
    height: 55px;
    font-weight: normal;
    color: var(--light-grey);
    text-decoration: none;
    font-size: 1.8rem;
    padding: 0 10px 0 20px;
    border-radius: 30px;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    transition: 0.2s ease-in-out;
}

.link-nav-button-logout {
    display: flex;
    margin: 0 auto 30px auto;
    width: 220px;
    height: 55px;
    font-weight: bold;
    color: var(--light-grey);
    text-decoration: none;
    font-size: 1.8rem;
    padding: 0 10px 0 20px;
    border-radius: 30px;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    transition: 0.2s ease-in-out;
}

.create-load-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.create-load-popup {
    display: flex;
    flex-direction: column;
    width: 1200px;
    height: 900px;
    background-color: var(--royal-white);
    border-radius: 30px;
    border: none;
}

.create-load-popup section {
    width: 100%;
    border-bottom: 1px solid var(--light-grey);
    align-items: center;
    align-content: center;
    justify-items: center;
    padding: 15px;
    justify-content: space-between;
    display: flex;
}

.create-load-popup section h2 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.create-load-popup section button {
    background: none;
    border: none;
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--royal-red);
    border-radius: 50px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.create-load-popup section button:hover {
    background: var(--light-grey-hovered);
}

.link-nav-button-logout:hover {
    transition: 0.2s ease-in-out;
    color: var(--royal-white);
    background: var(--royal-red);
}

.link-nav-button-icon {
    margin-right: 10px;
    width: 25px;
    height: 25px;
}

.link-nav-button:hover {
    transition: 0.2s ease-in-out;
    background: var(--royal-blue);
    color: var(--royal-white);
}

.shipper-dashboard-content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: var(--royal-white-dark);
    border-radius: 15px;
    justify-content: space-between;

    @media screen and (max-width: 1028px) {
        gap: 10px;
        justify-content: start;
    }
}

.shipper-dashboard-content-settings {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
}

.chat-messages-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 98%;
    border: 1px solid var(--light-grey-hovered);
    overflow: scroll;
    overflow-x: hidden;
    background: var(--royal-white);
    border-radius: 15px;
}

.go-to-chats-button {
    position: absolute;
    display: flex;
    font-weight: bold;
    color: var(--royal-blue);
    font-size: 1.8rem;
    border-radius: 30px;
    text-align: center;
    top: 60px;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    transition: 0.2s ease-in-out;
    border: none;
}

.go-to-chats-button:hover {
    background: var(--light-grey);
}

.payments-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 850px;
}

.payment-selection-header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: start;
    width: 100%;
    height: 130px;
}

.payment-selection-title {
    font-size: 3rem;
    font-weight: bold;
    color: var(--dark-grey);
}

.select-payment-method {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 350px;
}

.payment-method-button {
    position: relative;
    border: none;
    display: flex;
    width: 150px;
    height: 50px;
    color: var(--light-grey);
    font-size: 2rem;
    font-weight: bold;
    justify-content: start;
    align-items: start;
    transition: 0.2s ease-in-out;
}

.payment-method-button:hover {
    color: var(--royal-blue);
    transition: 0.2s ease-in-out;
}

.payment-method-content {
    margin: 20px 0 0 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 750px;

}

.user-card-container {
    display: flex;
    justify-content: space-between;
    height: 650px;
    flex-direction: column;
}

.transaction-history {
    display: flex;
    flex-direction: column;
    width: 550px;
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
    border-right: 1px solid var(--light-grey);
    border-left: 1px solid var(--light-grey);
}

.transaction-history h3 {
    color: var(--light-grey);
    margin: 0 auto;
    display: flex;
}

.transaction-history-header {
    display: flex;
    justify-content: space-between;
    padding: 0 20px 0 20px;
    align-items: center;
    width: 550px;
    height: 100px;
}

.transaction-history-header h3 {
    font-size: 3rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.transaction-history-header button {
    border: none;
    background: none;
    color: var(--royal-blue);
    font-size: 2rem;
    border-radius: 30px;
    font-weight: bold;
    transition: 0.2s ease-in-out;
}

.transaction-history-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 25px 0 25px;
    margin: 0 auto;
    height: 100px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    border-radius: 30px;

}

.transaction-history-item:hover {
    background: var(--light-grey-hovered);
    transition: 0.2s ease-in-out;

}

.transaction-history-item h4 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--royal-red);
}

.transaction-history-item section {
}

.transaction-history-item section h4 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--dark-grey);
}

.transaction-history-item section p {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--light-grey);
}

.transaction-history-header button:hover {
    background: var(--light-grey-hovered);
    transition: 0.2s ease-in-out;
}

.selected-card {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: start;
    width: 100%;
    height: 550px;
}

.card-settings {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 350px;
    height: 100%;
}

.selected-card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 250px;
}

.card-settings-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100px;
}

.card-settings-content a {
    width: 100%;
    text-decoration: none;
    font-size: 2rem;
    font-weight: bold;
    padding: 15px;
    color: var(--light-grey);
    transition: 0.2s ease-in-out;
    border-radius: 35px;
}

.card-settings-content a:hover {
    background: var(--light-grey-hovered);
    color: var(--dark-grey);
    transition: 0.2s ease-in-out;
}


.card-settings button {
    width: 200px;
    border: none;
    border-radius: 35px;
    background: none;
    font-size: 2rem;
    font-weight: bold;
    padding: 15px;
    border: 20px;
    background: var(--royal-red);
    transition: 0.2s ease-in-out;
}

.card-settings button:hover {
    background: var(--royal-red-hovered);
    transition: 0.2s ease-in-out;
}


.card-icon {
    transition: 0.2s ease;
    border-radius: 25px;
}

.card-icon:hover {
    transition: 0.2s ease;
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: 0px 0px 15px 0px var(--dark-grey);
}

.payment-method-button::after {
    content: '';
    position: absolute;
    border-radius: 30px;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    background: var(--royal-blue);
    transition: width 0.3s ease;
}

.payment-method-button:hover::after {
    width: 50%;
}

.shipper-chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 0 0 20px 0;
    width: 100%;
    height: 90px;
    background: var(--royal-white);
    padding: 0 30px 0 30px;
    border-bottom: 1px solid var(--light-grey);

    @media screen and (max-width: 768px) {
        flex-wrap: wrap-reverse;
        align-content: center;
        gap: 10px;
        justify-content: center;

    }
}

.shipper-carrier-chat-header {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 10px;
    height: fit-content;
}

.warning-container-wrapper {
    position: absolute;
    z-index: 1000;
    max-width: 1050px;
    width: 100%;
    margin: 0 auto;
}

.success-container {
    background: var(--royal-green);
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 15px;
    margin: 0 auto;
}

.success-container-header {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    font-weight: bold;
    align-content: center;
    align-items: center;
    justify-items: center;
    border-bottom: 1px solid var(--light-grey);
}

.success-container-header h2 {
    font-size: 2rem;
    margin: 5px 0 0 5px;
    color: var(--royal-white);
}

.success-container-body {
    padding: 10px;
    display: flex;
}

.success-container-body p {
    font-size: 1.8rem;
    color: var(--royal-white);
}

.bol-container {
    margin: 10px 0 0 0;
    background: var(--royal-yellow);
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 15px;
}

.bol-container-header {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    font-weight: bold;
    align-content: center;
    align-items: center;
    justify-items: center;
    border-bottom: 1px solid var(--light-grey);
}

.bol-container-header h2 {
    font-size: 2rem;
    margin: 5px 0 0 5px;
    color: var(--royal-white);
}

.bol-container-body {
    padding: 10px;
    display: flex;
}

.bol-container-body p {
    font-size: 1.8rem;
    color: var(--royal-white);
}

.close-warning-container-button {
    border: none;
    border-radius: 50px;
    width: 50px;
    height: 51px;
    font-size: 2rem;
    transition: 0.2s ease-in-out;
}

.close-warning-container-button:hover {
    background: var(--light-grey-hovered-transparent);
}

.shipper-chat-header h1 {
    font-size: 3rem;
    font-weight: bold;
    color: var(--royal-blue);

    @media screen and (max-width: 768px) {
        font-size: 2rem;
    }
}

.shipper-account-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.shipper-dashboard-content-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.shipper-dashboard-account-info {
    display: flex;
    width: 330px;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    justify-items: center;
    margin: 0 0 0 40px;
}

.shipper-profile-content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 850px;
}

.shipper-profile-content {
    padding: 30px;
    display: flex;
    max-width: 1150px;
    width: 100%;
    border: 1px solid var(--light-grey);
    justify-content: space-between;
    height: fit-content;
    border-radius: 25px;
}

.profile-content-wrapper {
    margin: 20px 0 0 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}


.shipper-profile-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 300px;
    width: 100%;
    height: 300px;
    border-radius: 35px;
    background: linear-gradient(to right, var(--royal-blue), var(--royal-blue-light));
    padding: 0 30px 0 30px;
    margin: 0 0 0 20px;
}

.shipper-profile-status p {
    font-size: 2rem;
    font-weight: bold;
    color: var(--grey);
    margin: 0 0 10px 0;
}


.shipper-profile-activity {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 1300px;
}

.shipper-profile-activity section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 460px;
    border-radius: 25px;
    border: 1px solid var(--light-grey);
    height: 100%;
    padding: 30px;
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 75px;
    border-bottom: 1px solid var(--light-grey);
    background: var(--royal-white);
    padding: 0 30px 0 30px;
}

.section-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.section-content h3 {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--grey);

}

.section-header span h3 {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.section-header span p {
    font-size: 2rem;
    font-weight: bold;
    color: var(--light-grey);
}

.section-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100px;
    border-radius: 30px;
    padding: 0 10px 0 10px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.section-item div {
}

.section-item div h3 {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.section-item div p {
    font-size: 1.3rem;
    font-weight: bold;
    color: var(--grey);
}

.section-item:hover {
    background: var(--light-grey-hovered);
    transition: 0.2s ease-in-out;
}


.user-item-info {
    display: flex;
    width: 130px;
    text-align: left;
    align-items: center;
    align-content: center;
    justify-content: space-between;
}

.shipper-info {
    display: flex;
    height: 100%;
}

.shipper-profile-avatar {
    display: flex;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    object-fit: cover;

}

.shipper-details-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 0 20px;
    height: 200px;
}

.shipper-role-name h3 {
    font-size: 3rem;
    font-weight: bold;
    color: var(--royal-blue);
    margin: 0 0 5px 0;

}

.shipper-role-name p {
    font-size: 2.2rem;
    font-weight: bold;
    color: var(--light-grey);
}

.shipper-info-details p {
    font-size: 2rem;
    font-weight: bold;
    color: var(--grey);
    margin: 0 0 10px 0;
}

.shipper-nav-buttons {
    display: flex;
}

.shipper-profile-content button {
    display: flex;
    width: 35px;
    height: 35px;
    border: none;
    border-radius: 5px;
    background: var(--light-grey);
    color: var(--royal-white);
    font-size: 1.8rem;
    margin: 0 5px 0 5px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;
}

.shipper-profile-content button:hover {
    background: var(--light-grey-hovered);
    transition: 0.2s ease-in-out;
}


.account-info-text {
    margin: 10px 0 0 10px;
    height: 50px;
}


.account-info-name {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    color: var(--grey);
}

.account-info-role {
    font-size: 1.5rem;
    margin: 0;
    font-weight: bold;
    color: var(--light-grey);
}

.shipper-dashboard-searchbar {
    background: var(--search-bar-background);
    display: flex;
    width: 300px;
    height: 70px;
    padding: 0 15px 0 17px;
    border-radius: 40px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    font-weight: bold;
    color: var(--grey);
    border: 2px solid var(--search-bar-background);
    font-size: 1.8rem;
    transition: 0.2s ease-in-out;
}

.shipper-dashboard-searchbar:hover {
    border: 2px solid var(--royal-blue);
    transition: 0.2s ease-in-out;
}

.shipper-dashboard-searchbar:focus {
    border: 2px solid var(--royal-blue);
    transition: 0.2s ease-in-out;
}


.shipper-dashboard-searchbar input {
    width: 230px;
    height: 30px;
    padding: 0 20px;
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--grey);
    border: none;
}

.shipper-dashboard-searchbar input::placeholder {
    color: var(--search-bar-placeholder-color)
}

.shipper-dashboard-content-title {
    color: var(--royal-white);
    font-size: 3.5rem;
    background: linear-gradient(to right, var(--royal-blue), var(--royal-blue-hovered));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.shipper-dashboard-content-subtitle {
    color: var(--light-grey);
    font-size: 2rem;
    font-weight: bold;
}

.close-shipper-side-bar {
    height: 40px;
    border-radius: 30px;
    width: 40px;
    margin: 20px 0 0 10px;
    box-shadow: 1px 1px 5px 0px var(--light-grey);
}

.deal-chat-conversations-wrapper {
    display: flex;
    width: 100%;
    height: 850px;
    margin: 20px 0 0 0;

}

.arrow-nav-close-open-side-bar {
    transition: transform 0.3s ease-in-out;
}

.arrow-nav-close-open-side-bar.rotated {
    transform: rotate(180deg);
}


.shipper-dashboard-content-body {
    display: flex;
    width: 100%;
    border-radius: 30px;
    height: 100%;
    padding: 15px;
}

.dashboard-content {
    width: 100%;
    height: 100%;
    display: flex;
}

.chat-metric-content {
    width: 65%;
    display: flex;
    flex-direction: column;
}

.map-content {
    width: 35%;
    display: flex;
    flex-direction: column;
}

.shipper-loads-dashboard-content-body {
    display: flex;
    width: 100%;
    margin: 20px 0 20px 0;
    border-radius: 30px;
    flex-direction: column;
}

.load-containers-wrapper {
    display: flex;
    flex-direction: column;
    height: 83dvh;
    overflow: scroll;
    padding: 0 10px 0 10px;
    overflow-x: hidden;
}

.load-containers-wrapper::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */

}

.load-containers-wrapper::-webkit-scrollbar-thumb {
    background-color: var(--grey); /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

.load-containers-wrapper::-webkit-scrollbar-track {
    background-color: var(--light-grey); /* Color of the scrollbar track */
    border-radius: 10px; /* Rounded corners for the scrollbar track */
}

.create-load-container-header {
    display: flex;
    width: 100%;
    justify-content: start;
    align-content: center;
    align-items: center;
    text-align: center;
}

.create-load-container-content {
    display: flex;
    width: 100%;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    overflow: scroll;
    height: 83vh;
    overflow-x: hidden;
    margin: 50px 0 0 0;
}

.load-type-frame-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.load-frame-description {
    width: 100%;
    text-align: left;
    border-bottom: 1px solid var(--light-grey);
    margin: 0 0 140px 0;
}

.load-frame-description h2 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--dark-grey);

}

.load-frame-description p {
    font-size: 1.8rem;
    font-weight: normal;
    color: var(--grey);

}

.load-type-frame-wrapper-content {
    display: grid;
    margin: 0 auto;
    grid-template-columns: repeat(2, 2fr);
    justify-content: space-between;
    gap: 15px;
}

.create-load-buttons {
    display: flex;
    gap: 15px;
    margin: 15px auto;
    width: 100%;
    max-width: 100%;
}

.stops-container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.create-load-first-step {
    display: flex;
    margin: 0 auto;
    height: 210px;
    width: 600px;
    justify-content: space-between;
}

.create-load-credentials-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: space-between;
    height: 100%;
}

.create-load-credentials-container h2 {
    font-size: 3rem;
    color: var(--dark-grey);
}

.create-load-credentials-container p {
    font-size: 2rem;
    color: var(--light-grey);

}

.load-creation-input-fields {
    height: 100%;
    display: flex;
    gap: 15px;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
}

.input-fields-with-date-time {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 100%;
    gap: 15px;
    justify-content: center;
    align-items: center;
    justify-items: center;
    margin: 0 auto;
}

.distance-in-miles {
    display: flex;
    width: 100%;
    font-size: 15px;
    color: var(--light-grey);
}

.load-preference-checkboxes {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    align-content: center;
    align-items: center;
    justify-items: center;
    margin: 0 auto;
}

.creation-load-buttons-nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 15px;
}

.create-load-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
}

.create-load-submit-button {
    color: var(--royal-white);
    width: fit-content;
    background: var(--royal-blue);
    border: none;
    padding: 7px 20px 7px 20px;
    border-radius: 50px;
    font-weight: bold;
    font-size: 1.2rem;
    margin: 0 0 0 0;
    transition: 0.2s ease-in-out;
}

.create-load-submit-button:hover {
    background: var(--royal-blue);
}

.go-previous-step-button {
    display: flex;
    align-items: center;
    text-align: center;
    justify-items: center;
    align-content: center;
    color: var(--grey);
    width: fit-content;
    padding: 7px 11px 7px 11px;
    border-radius: 50px;
    font-weight: bold;
    font-size: 1.2rem;
    margin: 0;
    transition: 0.2s ease-in-out;
}

.arrow-back {
    margin-right: 5px;
    width: 8px;
    height: 8px;
}

.go-previous-step-button:hover {
    background: var(--light-grey-hovered);
}

.create-load-direction-icon {
    margin: 30px 20px auto;
    height: 112px;
}

.load-creation-input-fields section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 235px;
}

.load-empty-message {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: bold;
    color: var(--light-grey);
    margin: 270px auto;
}

.create-load-container-header h3 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--royal-blue);
    margin: 0 0 0 20px;
}

.create-load-container-header button {
    border-radius: 50px;
    padding: 12px 10px 12px 10px;
    border: none;
    margin: 0 0 0 5px;
    transition: 0.3s ease-in-out;
}

.create-load-container-header button:hover {
    background: var(--light-grey-hovered);
}

.shipper-qoutes-dashboard-content-body {
    display: flex;
    width: 100%;
    margin: 20px 0 20px 0;
    border-radius: 30px;
    justify-content: space-between;
}

.shipper-listing-container {
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 100%;
    border-radius: 30px;
    overflow: scroll;
    overflow-x: hidden;
}

.shipper-dashboard-chat-metric {
    justify-content: space-between;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.shipper-dashboard-side-panel-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin: 0 0 0 20px;
}

.shipper-map-container {
    display: flex;
    width: 100%;
    height: 30%;
    border-radius: 10px;
}

.shipper-dashboard-side-panel {
    display: flex;
    margin: 20px 0 0 0;
    flex-direction: column;
    width: 100%;
    height: 75dvh;
    padding: 1rem;
    gap: 20px;
    border-radius: 30px;
    overflow: scroll;
    border: 1px solid var(--light-grey);
    background: var(--royal-white);
}

.shipper-dashboard-side-panel::-webkit-scrollbar {
    width: 1px;
}

.load-directions {
    display: flex;
}

.active-loads-panel-nav {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    align-content: center;
    justify-content: center;
    overflow: auto;
    position: sticky;
    padding: 0 0 5px 0;
    border-bottom: 1px solid var(--light-grey);
    height: 50px;
    
    @media screen and (max-width: 768px) {
        padding: 0 0 0 90px;
    }
}

.active-loads-panel-nav button {
    display: flex;
    border: none;
    border-radius: 7px;
    padding: 5px 10px;
    font-size: 1.2rem;
    font-weight: normal;
    color: var(--grey);
    background: var(--light-grey-hovered-2);
    transition: 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
        background: var(--light-grey-hovered);
    }
}

.load-directions-icon {

}

.load-container-info {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    justify-items: center;
}

.load-container-carrier {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 155px;
    height: 100%;
    gap: 20px;
}

.carrier-icon {
    background: none;
    z-index: 999;
}

.origin-destination-container {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 5px;
    justify-content: space-between;
    height: 85px;
}

.load-directions-description {
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0;
    color: var(--light-grey);
}

.shipper-info-google-map-container {
    border-radius: 15px;
    overflow: hidden;
}

.metrics-container-wrapper {
    width: 100%;
    justify-content: space-between;
    display: flex;
    gap: 20px;
}

.ai-chat-content {
    display: flex;
    width: 100%;
    height: 730px;
    padding: 0 15px 0 15px;
    border-radius: 40px;
    border: 1px solid var(--light-grey);
}

.chat-carrier-button {
    display: flex;
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 30px;
    background: var(--royal-green);
    color: var(--royal-white);
    font-size: 1.2rem;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;
}

.shipper-dashboard-load-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    align-content: center;
}

.dashboard-content-body {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 15px;
    gap: 15px;
    justify-content: space-between;
}

.shipper-filter-buttons {
    width: 350px;
    display: flex;
    gap: 15px;
    justify-content: space-between;
}

.filter-buttons-shipper {
    background: var(--royal-white);
    color: var(--grey);
    font-size: 1.4rem;
    font-weight: bold;
    padding: 15px 22px 15px 22px;
    margin: 0;
    height: fit-content;
    background: var(--light-grey-hovered);
    width: fit-content;
    border: none;
    transition: 0.2s ease-in-out;
    border-radius: 55px;
}

.filter-buttons-shipper:hover {
    background: var(--light-grey);
}

.dropdown-animation {
    position: absolute;
    background-color: #f9f9f9;
    width: fit-content;
    border-radius: 8px;
    color: var(--grey);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    animation: slideIn 0.5s ease-out;
}

.dropdown-animation p {
    margin: 5px;
    padding: 10px;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: bold;
    color: var(--grey);
    border-radius: 8px;
    transition: 0.2s ease-in-out;
}

.dropdown-animation p:hover {
    background: var(--light-grey-hovered);
    transition: 0.2s ease-in-out;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(10px);
    }
}

.create-load-button {
    border-radius: 55px;
    display: flex;
    font-size: 1.4rem;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    justify-items: center;
    padding: 12px 15px 12px 15px;
    background: var(--royal-green);
    border: none;
    height: fit-content;
    width: fit-content;
    transition: 0.2s ease-in-out;
}

.add-load-icon {
    margin-right: 5px;
}

.circle-plus-icon {
    margin: 0 3px 1px 0;
}

.create-load-button:hover {
    background: var(--royal-green-hovered);
}

.button-nav-load-icon {
    margin-right: 10px;
}


.shipper-loads-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 750px;
    margin: 20px 0 0 0;
    overflow: scroll;
}

.shipper-loads-wrapper-qoutes {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 850px;
    margin: 20px 0 0 0;
    overflow: scroll;
}

.shipper-loads-qoutes-listing {
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 850px;
    padding: 0;
    margin: 0;
    border-radius: 30px;
    border: 1px solid var(--light-grey);
}

.carrier-listing-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 5px;
    overflow: scroll;
    overflow-x: hidden;
}

.carrier-message-item {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    margin: 3px 0 3px 0;
    border-radius: 80px;
    padding: 10px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.carrier-message-item:hover {
    background: var(--light-grey-hovered);
    transition: 0.2s ease-in-out;
}

.carrier-message-item-info h3 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.carrier-message-item-info p {
    font-size: 1.5rem;
    font-weight: normal;
    color: var(--light-grey);
}

.carrier-message-item-info {
    margin: 0 0 0 10px;
}

.listing-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100px;
    border-bottom: 1px solid var(--light-grey);
    padding: 0 30px 0 30px;
}

.listing-header section {
    margin: 20px 0 0 0;
}

.listing-header section h2 {
    font-size: 3rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.listing-header section p {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--light-grey);
}

.shipper-dashboard-load-container {
    display: flex;
    width: 100%;
    height: 335px;
    margin: 40px 0 0 0;
    border: 1px solid var(--light-grey);
    border-radius: 80px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.shipper-dashboard-load-container:hover {
    background: var(--light-grey-hovered);
    border: 1px solid var(--royal-blue);
    transition: 0.2s ease-in-out;
}

.shipper-load-container-info {
    display: flex;
    width: 50%;
    margin: 0 0 0 30px;
    justify-content: space-between;
    height: 100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
}

.shipper-load-info-main {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    padding: 60px 0 60px 40px;
}

.shipper-load-info-additional {
    display: flex;
    flex-direction: column;
    align-content: start;
    justify-content: space-between;
    margin: 0 30px 0 0;
}

.shipper-load-info-additional span {
    padding: 10px;
    margin: 10px 0 10px 0;
    color: var(--grey);
    font-size: 2rem;
    font-weight: bold;
    display: inline-block;
    width: fit-content;
    text-align: center;
    border-radius: 20px;
    background: var(--light-grey);
}

.carrier-load-logo {
    width: 250px;
    height: 200px;
}


.map-load-section {
    width: 50%;
    height: 100%;
}

.status-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--royal-green);
    margin: 0 0 0 5px;
}

.shipper-load-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: fit-content;
    justify-items: center;
    padding: 5px;
    border-radius: 33px;
    background: var(--light-grey);
}

.status-text {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: var(--grey);
    margin: 0 5px 0 5px;
}

.shipper-load-price {
    color: var(--royal-green);
    font-size: 4rem;
    font-weight: bold;
}

.shipper-load-direction-wrapper {
    display: flex;
    align-items: center;
    height: 120px;
}

.shipper-load-direction {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.shipper-load-direction-origin {
    margin: 15px 0 0 0;
}

.shipper-load-direction-origin h3 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.shipper-load-direction-origin p {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--light-grey);

}

.shipper-load-direction-destination {
    margin: 5px 0 0 0;
}

.shipper-load-direction-destination h3 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.shipper-load-direction-destination p {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--light-grey);
}


.shipper-deal-conversations-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.chat-id-containers-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 95dvh;
    gap: 10px;
    padding: 10px;
    overflow: scroll;
    overflow-x: hidden;
}

.chat-message-warning {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.5rem;
    color: var(--light-grey);
    margin: 270px auto;
}

.chat-id-containers-wrapper::-webkit-scrollbar {
    width: 6px;
}

.chat-id-containers-wrapper::-webkit-scrollbar-thumb {
    background-color: var(--light-grey-hovered); /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

.chat-id-containers-wrapper::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px; /* Rounded corners for the scrollbar track */
}

.chat-input-area-wrapper {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 10px auto;
    border-radius: 15px;
    border: 1px solid var(--light-grey);
    transition: 0.2s ease-in-out;
    padding: 5px;
}

.chat-input-area-wrapper:hover {
    border: 1px solid var(--royal-blue);
}

.chat-input-area {
    display: flex;
    width: 100%;
    height: 55px;
    justify-content: space-between;
    align-content: center;
    gap: 10px;
    align-items: center;
    justify-items: center;
}

.chat-input-options {
    display: flex;
    gap: 5px;
}

.chat-input-area-content {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-items: center;
}

.send-message-wrapper {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-items: center;
}

.chat-input-area section {
    width: 250px;
}

.chat-input-icons {
    margin: 0 15px 0 5px;
    cursor: pointer;
    transition: 0.1s ease-in-out;
}

.chat-input-icons:hover {
    transition: 0.1s ease-in-out;
    cursor: pointer;
}

.chat-messages {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 65dvh;
    overflow: scroll;
    overflow-x: hidden;
    padding: 20px;
    border-radius: 30px;
}

.chat-messages::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
}

.messaging-chat-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @media screen and (max-width: 768px) {
        height: 87%;
        padding: 0 0 15px 0;
    }
}

.chat-messages::-webkit-scrollbar-thumb {
    background-color: var(--royal-blue);
    border-radius: 10px;
}

.chat-messages::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
}

.chat-id-container {
    display: flex;
    cursor: pointer;
    align-content: center;
    align-items: center;
    justify-items: center;
    font-weight: bold;
    padding: 10px;
    color: black;
    border-radius: 10px;
    transition: 0.2s ease-in-out;
    font-size: 2rem;
}

.chat-id-container:hover {
    background: var(--light-grey-hovered);
}

.chat-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 0 20px;
    padding: 5px 0 5px;
}

.user-avatar-chat {
    height: 55px;
    width: 55px;
    border-radius: 50px;
}

.chat-details h3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--dark-grey);
}

.chat-details p {
    font-size: 1.4rem;
    color: var(--grey);
    font-weight: 400;
    margin: 0;
}

.chat-input-area input {
    font-size: 1.5rem;
    font-weight: normal;
}

.chat-input-area input::placeholder {
    color: var(--light-grey);
}

.chat-send-button {
    color: var(--royal-white);
    font-size: 1.5rem;
    width: 60px;
    height: 55px;
    margin: 0 0 0 10px;
    font-weight: bold;
    border-radius: 30px;
    transition: 0.2s ease-in-out;
    border: none;
}

.chat-send-button:hover {
    background: var(--royal-blue-light-transparent);
    transition: 0.2s ease-in-out;
}

.chat-conversation-search-bar {
    display: flex;
    width: 350px;
    height: 55px;
    padding: 0 15px 0 17px;
    border-radius: 40px;
    justify-content: flex-start;
    justify-items: center;
    gap: 20px;
    align-items: center;
    font-weight: bold;
    background: var(--royal-white);
    border: 1px solid var(--light-grey-hovered);
    color: var(--grey);
    font-size: 1.8rem;
    transition: 0.2s ease-in-out;

    @media screen and (max-width: 768px) {
        margin: 0 auto;
    }
}

.chat-conversation-search-bar:hover {
    transition: 0.2s ease-in-out;
    border: 1px solid var(--royal-blue);
}

.chat-conversation-search-bar:focus {
    transition: 0.2s ease-in-out;
    border: 1px solid var(--royal-blue);
}

.chat-conversation-search-bar input {
    font-size: 1.8rem;
    color: var(--grey);
    font-weight: bold;
    border: none;
}

.choose-chat-conversation {
    margin: 230px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;

}

.choose-chat-conversation p {
    border-radius: 25px;
    padding: 45px;
    font-weight: bold;
    font-size: 2rem;
    color: var(--dark-grey);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add this line */
    background: var(--royal-white);
}

.settings-container {
    display: flex;
    width: 100%;
    height: 85dvh;
    justify-content: space-between;
    border-radius: 25px;
    padding: 20px;
    gap: 10px;
}

.password-settings-container {
    display: flex;
    margin: 20px 0 0 0;
    flex-direction: column;
    justify-content: space-between;
    width: 350px;
}

.settings-content {
    display: flex;
    flex-direction: column;
    width: 86%;
    height: 100%;
    background: var(--royal-white);
    overflow: scroll;
    overflow-x: hidden;
    padding: 0 35px 35px 35px;
    border-radius: 20px;
    border: 1px solid var(--light-grey);
}

.settings-content::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
}

.settings-content::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

.settings-content::-webkit-scrollbar-track {
    background: transparent; /* Color of the scrollbar track */
}

.settings-content h2 {
    border-bottom: 1px solid var(--light-grey);
    font-size: 3rem;
    font-weight: bold;
    padding-bottom: 20px;
    color: var(--grey);
    margin: 30px 0 0 0;
}

.settings-content p {
    font-size: 1.8rem;
    font-weight: normal;
    color: var(--light-grey);
    margin: 20px 0 0 0;

}

.account-info-details-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 490px;
    height: 550px;
    margin: 20px auto;
}

.apply-settings-button {
    background: var(--royal-green);
    border: none;
    width: 150px;
    height: 50px;
    margin: 0 auto;
    font-size: 1.7rem;
    font-weight: bold;
    border-radius: 25px;
    transition: 0.2s ease-in-out;
}

.apply-settings-button:hover {
    background: var(--royal-green-hovered);
    color: var(--royal-white);
    transition: 0.2s ease-in-out;
}

.warning-message-section {
    display: flex;
    flex-direction: column;
    align-content: end;
    justify-items: flex-start;
    align-items: flex-start;
    justify-content: end;
    margin: 20px auto;
    width: 100%;
    height: 450px;
}

.changing-password-text {
    margin: 20px 0 30px 0;
    font-weight: lighter;
    font-size: 1.5rem;
    color: var(--light-grey);
}

.password-fields {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40%;
}

.warning-message-section h3 {
    font-size: 1.5rem;
    font-weight: lighter;
    width: 100%;
    border-top: 1px solid var(--light-grey);
    color: var(--light-grey);
    padding: 15px 0 15px 0;
}


.apply-settings-button-bottom {
    background: var(--royal-green);
    border: none;
    width: 150px;
    height: 50px;
    font-size: 1.7rem;
    font-weight: bold;
    border-radius: 25px;
    transition: 0.2s ease-in-out;
}

.apply-settings-button-bottom:hover {
    background: var(--royal-green-hovered);
    color: var(--royal-white);
    transition: 0.2s ease-in-out;
}

.deleting-account-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 20px 0;
}

.delete-popup-text {
    font-size: 1.5rem;
    font-weight: lighter;
    color: var(--grey);
}

.delete-account-popup-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--grey);
    margin: 25px 0 25px 0;
}

.chat-message-alert {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--light-grey);
    margin: 0 auto;
    padding: 150px 0;
    text-align: center;
}

.delete-account-popup-title span {
    color: var(--royal-red);
}

.delete-bin-icon {
    width: 19px;
    height: 19px;
    margin: 0 0 0 3px;
}

.deleting-account-section hr {
    width: 100%;
    border: 1px solid var(--light-grey);
    margin: 0 0 25px 0;
}

.deleting-account-section a {
    color: var(--royal-red);
    font-size: 1.7rem;
    font-weight: bold;
    transition: 0.2s ease-in-out;
    padding: 30px 0 0 0;
}

.avatar-settings {
    margin: 0 auto;
    align-content: center;
    justify-content: center;
    text-align: center;
    align-items: center;
    justify-items: center;
    display: flex;
    height: 250px;
    flex-direction: column;
}

.avatar-user-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: var(--royal-blue);
    color: var(--royal-white);
    font-size: 3rem;
    display: flex;
    object-fit: cover;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

}

.avatar-settings-wrapper {
    width: 300px;
    display: flex;
    justify-content: space-between;
    margin: 20px 0 20px 0;
}

.change-avatar {
    background: var(--royal-blue);
    border: none;
    padding: 10px 15px 10px 15px;
    font-size: 1.5rem;
    font-weight: normal;
    color: var(--royal-white);
    border-radius: 25px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.change-avatar:hover {
    background: var(--royal-blue-hovered);
    color: var(--royal-white);
    transition: 0.2s ease-in-out;
}

.shipper-delete-avatar {
    color: var(--royal-red);
    border: none;
    padding: 10px 15px 10px 15px;
    font-size: 1.5rem;
    font-weight: normal;
    border-radius: 25px;
    transition: 0.2s ease-in-out;
}

.shipper-delete-avatar:hover {
    background: var(--light-grey-hovered);
    color: var(--royal-red);
    transition: 0.2s ease-in-out;
}

.account-info-settings {
    display: flex;
    margin: 30px auto;
    width: 460px;
    justify-content: space-between;
    border-bottom: 1px solid var(--light-grey);
    height: 100%;
}

.account-info-settings-1 {
    width: 225px;
}

.account-info-settings-2 {
    width: 225px;
}

.avatar-settings-icon {
    height: 150px;
    border-radius: 50%;
    background: var(--royal-blue);
    color: var(--royal-white);
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.settings-nav {
    display: flex;
    flex-direction: column;
    align-content: start;
    justify-content: start;
    text-align: left;
    width: 250px;
    height: fit-content;
    background: var(--royal-white);
    border-radius: 20px;
    border: 1px solid var(--light-grey);
}

.settings-nav button {
    display: flex;
    width: 100%;
    height: fit-content;
    padding: 20px;
    background: var(--royal-white);
    font-weight: 500;
    color: var(--dark-grey);
    font-size: 1.8rem;
    text-align: center;
    align-content: center;
    border: none;
    align-items: center;
    border-right: 5px solid transparent;
    justify-content: flex-start;
    transition: 0.2s ease-in-out;
}

.settings-nav button:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.settings-nav button:first-child:hover {
    background: var(--royal-blue-light-transparent);
    border-right: 5px solid var(--royal-blue-hovered);
    transition: 0.2s ease-in-out;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}


.settings-nav button:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.settings-nav button:last-child:hover {
    background: var(--royal-blue-light-transparent);
    border-right: 5px solid var(--royal-blue-hovered);
    transition: 0.2s ease-in-out;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.settings-nav button:hover {
    background: var(--royal-blue-light-transparent);
    border-right: 5px solid var(--royal-blue-hovered);
    transition: 0.2s ease-in-out;
}

.load-frame-container {
    color: var(--royal-blue);
    width: 190px;
    height: 190px;
    align-content: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 25px;
    cursor: pointer;
    border-radius: 15px;
    border: 1px solid var(--light-grey-hovered);
    transition: 0.2s ease-in-out;
}

.load-frame-container:hover {
    border: 1px solid var(--royal-blue);
}

.load-frame-container:focus {
    border: 1px solid var(--royal-blue);
    background: var(--light-grey-hovered-transparent);
}

.load-frame-container section {
    margin: 20px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column-reverse;
    justify-items: center;
}

.load-frame-container section h2 {
    color: var(--grey);
    font-weight: lighter;
    font-size: 16px;
}

.arrow-nav-right {
    transform: rotate(180deg);
    background: var(--royal-blue);
    border: 50px;
    padding: 10px;
}

.go-back-link {
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: 0.2s ease;
    height: 40px;
    width: 40px;
}

.go-back-link:hover {
    background: var(--light-grey-hovered);
}

.load-page-header {
    display: flex;
    padding: 15px;
    align-items: center;
    justify-content: start;
    align-content: center;
    border-bottom: 1px solid var(--light-grey);
}

.load-page-header section {
    text-align: left;
    margin: 0 0 0 15px;
}

.load-page-header section h1 {
    color: var(--royal-blue);
    margin: 0;
}

.load-page-header section p {
    color: var(--grey);
    font-size: 1.5rem;
    margin: 0;
}

.load-status-label-container{
    display: none;

    @media screen and (max-width: 1028px) {
        display: flex;
    }
}

.load-status-bar{
    display: flex;

    @media screen and (max-width: 1028px) {
        display: none;
    }
}

.load-details-content-wrapper {
    max-width: 1204px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    overflow: scroll;
    height: 790px;
    overflow-x: hidden;
}

.load-details-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-content: center;
    justify-items: center;
    align-items: center;
    margin: 0;

    @media screen and (max-width: 1028px) {
        flex-direction: column;
        gap: 10px;
        align-content: flex-start;
        justify-content: flex-start;
        align-items: flex-start;
        justify-items: flex-start;
    }
}

.load-details-container {
    margin: 0 15px 0 0;
}

.load-details-container section {
    margin: 25px 0 0 0;
}

.load-details-container section h4 {
    font-size: 1.5rem;
    font-weight: lighter;
    color: var(--light-grey);
}

.load-details-container section h2 {
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--royal-blue);
}

.map-directions-load-details {
    width: 375px;
    height: 375px;
}

.load-carrier-bids-listing-content {
    margin: 50px 0 0 0;
}

.load-carrier-bids-listing-header {
    align-items: center;
    border-bottom: 1px solid var(--light-grey);
}

.load-carrier-bids-listing-header h1 {
    color: var(--royal-blue);
    font-size: 2.5rem;
    font-weight: bold;
}

.load-carrier-bids-listing-header p {
    color: var(--light-grey);
    font-size: 1.5rem;
}

.clip-loader-style {
    display: flex;
    position: absolute;
    margin: 50px auto;
    justify-content: center;
    align-items: center;
}

.chat-user-name {
    margin: 0;

    @media screen and (max-width: 768px) {
        font-size: 1rem;
    }
}

.overlay-popup-select {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.select-popup {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: white;
    width: 300px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 9999;
}

.select-popup-header {
    display: flex;
    padding: 10px 20px 10px 20px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    align-content: center;
    justify-items: center;
    border-bottom: 1px solid var(--light-grey);
}

.select-popup-content {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.select-popup-content p {
    font-size: 1.5rem;
    font-weight: bold;
    padding: 10px;
    border-radius: 10px;
    color: var(--grey);
    margin: 0 0 10px 0;
    transition: 0.1s ease-in-out;
}

.select-popup-content p:hover {
    background: var(--light-grey-hovered);
    cursor: pointer;

}

.select-popup-header h2 {
    font-size: 1.5rem;
    margin: 0;
    font-weight: bold;
    color: var(--grey);
}

.close-popup-button {
    color: var(--royal-red);
    width: fit-content;
    height: fit-content;
    font-size: 1.5rem;
    padding: 10px 15px 10px 15px;
    display: flex;
    transition: 0.2s ease-in-out;
    border: none;
    border-radius: 30px;
}

.close-popup-button:hover {
    background: var(--light-grey-hovered);
}

.load-status-delivering {
    background: var(--light-grey-hovered);
    color: var(--royal-green);
    padding: 10px;
    border-radius: 30px;
}

.card-data-section {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    gap: 15px;
}

.custom-select-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
}

.custom-button {
    display: flex;
    align-items: center;
    width: fit-content;
    height: fit-content;
    border-radius: 8px;
    padding: 10px 15px 10px 15px;
    font-size: 1.2rem;
    border: none;
    margin: 15px auto;
    background: var(--royal-green);
    transition: 0.2s ease-in-out;
}

.messages-label {
    display: none;
}

.custom-button:hover {
    background: var(--royal-green-hovered);
}

.custom-select {
    display: inline-block;
    width: 100%;
    padding: 10px 15px;
    font-size: 1.6rem;
    color: var(--light-grey);
    background: none;
    border: 1px solid var(--light-grey);
    border-radius: 8px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.custom-select option {
    font-size: 1.6rem;
    color: var(--grey);
    background: none;
    border: 1px solid var(--light-grey);
    border-radius: 8px;
    padding: 5px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.custom-select:hover {
    border-color: var(--royal-blue);
}

.custom-select:focus {
    outline: none;
    border-color: var(--royal-blue);
}

.settings-nav-mobile {
    display: none;
}

.custom-select-wrapper::after {
    content: '\25BC';
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    font-size: 1.2rem;
    color: var(--grey);
    pointer-events: none;
}

@media screen and (max-width: 1380px) {

    .settings-content {
        width: 100%;
    }

    .shipper-profile-status {
        display: none;
    }

    .settings-nav {
        display: none;
    }

    .password-fields {
        width: 100%;
    }

    .settings-nav-mobile {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 10px;
        padding: 5px 0;
    }

    .settings-container {
        flex-direction: column;

        padding: 10px;
    }

    .shipper-dashboard-side-panel-wrapper {
        margin: 0;
        height: 85dvh;
    }
}

@media screen and (max-width: 1024px) {
    .chat-messages-content {
        display: none;
    }

    .chat-messages-content.active {
        display: block;
    }

    .shipper-deal-conversations-sidebar.hidden {
        display: none;
    }
}

@media screen and (max-width: 768px) {

    .messages-label {
        display: flex;
        font-size: 2.3rem;
        font-weight: bold;
        margin: 10px 0;
        color: var(--royal-blue);
    }

    .settings-content {
        padding: 15px;
    }

    .account-info-settings {
        width: 100%;
    }

    .shipper-nav-buttons {
        display: none;
    }

    .shipper-profile-content {
        flex-direction: column;
        height: fit-content;
        align-content: center;
        justify-content: center;
        align-items: center;
        justify-items: center;
        text-align: center;
        width: 100%;
    }

    .account-info-details-container {
        width: 100%;
    }

    .shipper-deal-conversations-sidebar {
        width: 100%;
    }

    .shipper-info {
        flex-direction: column;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        height: fit-content;
        justify-items: center;
        text-align: center;
    }

    .settings-nav-mobile {
        width: 100%;
        overflow: scroll;
        overflow-y: hidden;
    }


    .shipper-map-container {
        height: 45%;
    }

}

@media screen and (max-width: 768px) {
    .shipper-dashboard-content-body {
        display: none;
    }
}
