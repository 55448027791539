* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.carrier-dashboard-take-load-mobile {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.carrier-dashboard-content-body-2 {
    display: flex;
    width: 100%;
    border-radius: 30px;
    height: 100%;
    padding: 15px;
}

.carrier-dashboard-wrapper {
    height: 100dvh;
    width: 100%;
    display: flex;
    background: var(--royal-white-dark);
}

.carrier-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 300px;
    width: 100%;
    height: 100dvh;
    background-color: var(--royal-white);
    border-right: 1px solid var(--light-grey);
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
}

.carrier-sidebar.hidden {
    transform: translateX(-250px);
    width: 0;
}

.main-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 600px;
    justify-content: space-between;
}

.link-nav-button {
    display: flex;
    width: 220px;
    height: 55px;
    font-weight: normal;
    color: var(--light-grey);
    text-decoration: none;
    font-size: 1.8rem;
    padding: 0 10px 0 20px;
    border-radius: 30px;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    transition: 0.2s ease-in-out;
}

.nav-items-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 15px;
}

.add-driver-section {
    display: flex;
    width: 30%;
}

.link-nav-button-logout {
    display: flex;
    margin: 0 auto 30px auto;
    width: 220px;
    height: 55px;
    font-weight: bold;
    color: var(--light-grey);
    text-decoration: none;
    font-size: 1.8rem;
    padding: 0 10px 0 20px;
    border-radius: 30px;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    transition: 0.2s ease-in-out;
}

.link-nav-button-logout:hover {
    transition: 0.2s ease-in-out;
    color: var(--royal-white);
    background: var(--royal-red);
}

.link-nav-button-icon {
    margin-right: 10px;
    width: 25px;
    height: 25px;
}

.link-nav-button:hover {
    transition: 0.2s ease-in-out;
    background: var(--royal-blue);
    color: var(--royal-white);
}

.carrier-dashboard-content {
    position: relative;
    padding: 20px;
    width: 100%;
    height: 100dvh;
}

.payments-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 850px;
}

.payment-selection-header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: start;
    width: 100%;
    height: 130px;
}

.payment-selection-title {
    font-size: 3rem;
    font-weight: bold;
    color: var(--dark-grey);
}

.select-payment-method {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 350px;
}

.payment-method-button {
    position: relative;
    border: none;
    display: flex;
    width: 150px;
    height: 50px;
    color: var(--light-grey);
    font-size: 2rem;
    font-weight: bold;
    justify-content: start;
    align-items: start;
    transition: 0.2s ease-in-out;
}

.payment-method-button:hover {
    color: var(--royal-blue);
    transition: 0.2s ease-in-out;
}

.payment-method-content {
    margin: 20px 0 0 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 750px;
}

.user-card-container {
    display: flex;
    justify-content: space-between;
    height: 650px;
    flex-direction: column;
}

.transaction-history {
    display: flex;
    flex-direction: column;
    width: 550px;
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
    border-right: 1px solid var(--light-grey);
    border-left: 1px solid var(--light-grey);
}

.transaction-history-header {
    display: flex;
    justify-content: space-between;
    padding: 0 20px 0 20px;
    align-items: center;
    width: 550px;
    height: 100px;
}

.transaction-history-header h3 {
    font-size: 3rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.transaction-history-header button {
    border: none;
    background: none;
    color: var(--royal-blue);
    font-size: 2rem;
    border-radius: 30px;
    font-weight: bold;
    transition: 0.2s ease-in-out;
}

.transaction-history-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 25px 0 25px;
    margin: 0 auto;
    height: 100px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    border-radius: 30px;

}

.transaction-history-item:hover {
    background: var(--light-grey-hovered);
    transition: 0.2s ease-in-out;

}

.transaction-history-item h4 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--royal-red);
}

.transaction-history-item section {
}

.transaction-history-item section h4 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--dark-grey);
}

.transaction-history-item section p {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--light-grey);
}

.transaction-history-header button:hover {
    background: var(--light-grey-hovered);
    transition: 0.2s ease-in-out;
}


.selected-card h2 {
    font-size: 2.3rem;
    font-weight: bold;
    margin: 0 0 20px 0;
    color: var(--royal-blue);
}

.card-settings-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100px;
}

.card-settings-content a {
    width: 100%;
    text-decoration: none;
    font-size: 2rem;
    font-weight: bold;
    padding: 15px;
    color: var(--light-grey);
    transition: 0.2s ease-in-out;
    border-radius: 35px;
}

.card-settings-content a:hover {
    background: var(--light-grey-hovered);
    color: var(--dark-grey);
    transition: 0.2s ease-in-out;
}


.card-settings button {
    width: 200px;
    border: none;
    border-radius: 35px;
    background: none;
    font-size: 2rem;
    font-weight: bold;
    padding: 15px;
    border: 20px;
    background: var(--royal-red);
    transition: 0.2s ease-in-out;
}

.card-settings button:hover {
    background: var(--royal-red-hovered);
    transition: 0.2s ease-in-out;
}


.card-icon {
    transition: 0.2s ease;
    border-radius: 25px;
}

.card-icon:hover {
    transition: 0.2s ease;
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: 0px 0px 15px 0px var(--dark-grey);
}

.payment-method-button::after {
    content: '';
    position: absolute;
    border-radius: 30px;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    background: var(--royal-blue);
    transition: width 0.3s ease;
}

.payment-method-button:hover::after {
    width: 50%;
}

.carrier-chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px 0;
    width: 100%;
    height: 90px;
    border-radius: 35px;
    background: var(--royal-white);
    padding: 0 30px 0 30px;
    box-shadow: 1px 1px 5px 0px var(--light-grey);
}

.carrier-carrier-chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
    height: 90px;

}

.carrier-chat-header h1 {
    font-size: 3rem;
    font-weight: bold;
    color: var(--royal-blue);

}

.carrier-account-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.carrier-dashboard-content-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.carrier-dashboard-account-info {
    display: flex;
    width: 330px;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    justify-items: center;
    margin: 0 0 0 40px;
}

.carrier-profile-content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 850px;
}

.carrier-profile-content {
    padding: 30px;
    display: flex;
    max-width: 1150px;
    width: 100%;
    border: 1px solid var(--light-grey);
    justify-content: space-between;
    height: 300px;
    border-radius: 25px;
}

.profile-content-wrapper {
    margin: 20px 0 0 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}


.carrier-profile-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    height: 300px;
    border-radius: 35px;
    background: linear-gradient(to bottom, var(--light-grey-hovered), var(--light-grey));
    padding: 0 30px 0 30px;
    margin: 0 0 0 20px;
}

.carrier-profile-status p {
    font-size: 2rem;
    font-weight: bold;
    color: var(--grey);
    margin: 0 0 10px 0;
}


.carrier-profile-activity {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 1300px;
}

.carrier-profile-activity section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 460px;
    border-radius: 25px;
    border: 1px solid var(--light-grey);
    height: 100%;
    padding: 30px;
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 75px;
    border-bottom: 1px solid var(--light-grey);
    background: var(--royal-white);
    padding: 0 30px 0 30px;
}

.section-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.section-content h3 {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--grey);

}

.section-header span h3 {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.section-header span p {
    font-size: 2rem;
    font-weight: bold;
    color: var(--light-grey);
}

.section-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100px;
    border-radius: 30px;
    padding: 0 10px 0 10px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.section-item div {
}

.section-item div h3 {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.section-item div p {
    font-size: 1.3rem;
    font-weight: bold;
    color: var(--grey);
}

.section-item:hover {
    background: var(--light-grey-hovered);
    transition: 0.2s ease-in-out;
}


.user-item-info {
    display: flex;
    width: 130px;
    text-align: left;
    align-items: center;
    align-content: center;
    justify-content: space-between;
}

.carrier-info {
    display: flex;
    height: 100%;
}

.carrier-profile-avatar {
    display: flex;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    object-fit: cover;
}

.carrier-details-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 0 20px;
    height: 200px;
}

.carrier-role-name h3 {
    font-size: 3rem;
    font-weight: bold;
    color: var(--royal-blue);
    margin: 0 0 5px 0;

}

.carrier-role-name p {
    font-size: 2.2rem;
    font-weight: bold;
    color: var(--light-grey);
}

.carrier-info-details p {
    font-size: 2rem;
    font-weight: bold;
    color: var(--grey);
    margin: 0 0 10px 0;
}

.carrier-nav-buttons {
    display: flex;
}


.carrier-profile-content button {
    display: flex;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 10px;
    background: var(--light-grey);
    color: var(--royal-white);
    font-size: 1.8rem;
    margin: 0 5px 0 5px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;
}

.carrier-profile-content button:hover {
    background: var(--light-grey-hovered);
    transition: 0.2s ease-in-out;
}


.account-info-name {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    color: var(--grey);
}

.account-info-role {
    font-size: 1.5rem;
    margin: 0;
    font-weight: bold;
    color: var(--light-grey);
}

.carrier-dashboard-searchbar {
    background: var(--search-bar-background);
    display: flex;
    width: 300px;
    height: 70px;
    padding: 0 15px 0 17px;
    border-radius: 40px;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    font-weight: bold;
    color: var(--grey);
    border: 2px solid var(--search-bar-background);
    font-size: 1.8rem;
    transition: 0.2s ease-in-out;
}

.carrier-dashboard-searchbar:hover {
    border: 2px solid var(--royal-blue);
    transition: 0.2s ease-in-out;
}

.carrier-dashboard-searchbar:focus {
    border: 2px solid var(--royal-blue);
    transition: 0.2s ease-in-out;
}

.carrier-dashboard-searchbar input {
    width: 230px;
    height: 30px;
    padding: 0 20px;
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--grey);
    border: none;
}

.carrier-dashboard-searchbar input::placeholder {
    color: var(--search-bar-placeholder-color)
}

.carrier-dashboard-content-title {
    color: var(--royal-white);
    font-size: 3.5rem;
    background: linear-gradient(to right, var(--royal-blue), var(--royal-blue-hovered));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.carrier-dashboard-content-subtitle {
    color: var(--light-grey);
    font-size: 2rem;
    font-weight: bold;
}

.close-carrier-side-bar {
    height: 40px;
    border-radius: 30px;
    width: 40px;
    margin: 20px 0 0 10px;
    box-shadow: 1px 1px 5px 0px var(--light-grey);
}

.arrow-nav-close-open-side-bar {
    transition: transform 0.3s ease-in-out;
}

.arrow-nav-close-open-side-bar.rotated {
    transform: rotate(180deg);
}

.carrier-dashboard-content-body {
    display: flex;
    width: 100%;
    padding: 10px;
    gap: 10px;
    flex-direction: column;
}

.carrier-dashboard-drivers {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 80vh;
    overflow: auto;
}

.taken-loads-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    gap: 15px;
    overflow: auto;
}

.driver-container-card-wrapper {
    display: flex;
    width: 100%;
    height: 750px;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    overflow: scroll;
    overflow-x: hidden;
    align-items: center;
    justify-items: center;
}

.filter-loads-container-mobile {
    display: flex;
    padding: 0 30px 0 30px;
    flex-direction: column;
    width: 100%;
    margin: 10px 0 0 0;
    color: var(--royal-white);
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 20px;
    background: var(--royal-blue);
}

.filter-loads-container-mobile h2 {
    font-size: 2.5rem;
    margin: 10px 0 0 0;
    font-weight: bold;
    color: var(--royal-white);
    text-align: center;
}

.filter-loads-container {
    display: flex;
    padding: 0 30px 0 30px;
    flex-direction: column;
    width: 400px;
    color: var(--royal-white);
    height: 100%;
    border-radius: 20px;
    background: var(--royal-blue);
}

.pickup-delivery-location-wrapper {
    display: flex;
    width: 100%;
    align-content: start;
    align-items: start;
    flex-direction: column;
    margin: 15px auto;
    justify-content: space-between;
}

.pickup-delivery-location-input {
    display: flex;
    width: 100%;
    height: 50px;
    background: var(--royal-white);
    border-radius: 40px;
    border: none;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    justify-items: center;
    font-weight: bold;
    font-size: 1.3rem;
    padding: 0 5px 0 20px;
    transition: 0.2s ease-in-out;
}

.load-filter {
    display: flex;
    width: 100%;
    height: 60px;
    border-radius: 40px;
    border: none;
    flex-direction: column;
    text-align: left;
    font-weight: bold;
    justify-items: center;
    justify-content: center;
    font-size: 1.3rem;
    transition: 0.2s ease-in-out;
    padding: 0 0 0 20px;
    cursor: pointer;
}

.load-filter label {
    color: var(--light-grey-hovered);
    font-size: 1.5rem;
}

.load-filter h4 {
    color: var(--royal-white);
    font-size: 1.7rem;
}

.load-filter:hover {
    background: var(--royal-blue-light-transparent);
    transition: 0.2s ease-in-out;
}

.loads-container-wrapper {
    display: flex;
    width: 100%;
    height: 89dvh;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    justify-items: center;
}

.load-filter-container {
    display: flex;
    width: 100%;
    gap: 10px;
    align-items: start;
    padding: 0 0 0 20px;

    @media screen and (max-width: 768px) {
        padding: 0;
        gap: 5px;
    }
}

.load-filter-container section {
    display: flex;
    gap: 10px;
    width: 50%;
}

.loads-containers-block {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
    overflow: scroll;
    padding: 20px 0 0 20px;
    overflow-x: hidden;
}

.carrier-load-directions-wrapper span label {
    color: var(--grey);
    font-size: 1.2rem;
    font-weight: bold;
}

.carrier-load-directions-wrapper span h3 {
    color: var(--royal-blue);
    font-size: 2rem;
    font-weight: bold;
}

.carrier-load-directions {
    display: flex;
    align-items: center;
    height: 100px;
    width: 350px;
}

.load-carrier-direction {
    display: flex;
    height: 100%;
    margin: 0 0 0 10px;
    padding: 10px 0 0 0;
    flex-direction: column;
    justify-content: space-between;
}

.load-carrier-direction section {
    margin: 0;
}

.load-carrier-direction section h3 {
    font-weight: bold;
    margin: 0;
    color: var(--royal-blue);
}

.load-carrier-direction section p {
    margin: 5px 0 0 0;
    color: var(--grey);
    font-weight: bold;
}

.bid-arrow-icon {
    margin: 0 0 0 5px;
    transition: transform 0.3s ease-in-out;
}

.bid-button:hover .bid-arrow-icon {
    transform: translateX(5px);
    transition: transform 0.3s ease-in-out;
}

.instant-book-load label {
    color: var(--grey);
    font-size: 1.3rem;
    font-weight: bold;
}

.instant-book-load h3 {
    color: var(--royal-blue);
    font-size: 1.8rem;
    font-weight: bold;
}

.text-message-p{
    text-align: center;
    align-content: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: var(--grey);
    font-weight: normal;
    margin: 0 auto;
}

.radius-text{
    font-size: 1.3rem;
    font-weight: normal;
}

.slider-wrapper-miles {
    display: flex;
    width: 100%;
    height: 50px;
    border-radius: 40px;
    border: none;
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-items: center;
    font-weight: bold;
    font-size: 1.3rem;
    transition: 0.2s ease-in-out;
    position: relative;
}

.slider-miles{
    display: flex;
}

.slider-wrapper-miles input {
    width: 130px;
    height: 40px;
    background: white;
    border-radius: 30px;
    border: none;
    margin: 0 30px 0 0;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    transition: 0.2s ease-in-out;
}

.pickup-delivery-location-input input::placeholder {
    color: var(--light-grey);
    font-weight: bold;
}

.pickup-delivery-location-input button {
    display: flex;
    width: 45px;
    height: 40px;
    border-radius: 40px;
    font-size: 1.8rem;
    font-weight: bold;
    border: none;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;
}

.pickup-delivery-location-input button:hover {
    background: var(--light-grey-hovered);
    transition: 0.2s ease-in-out;
}

.filter-loads-container h2 {
    font-size: 3rem;
    margin: 10px 0 0 0;
    font-weight: bold;
    color: var(--royal-white);
    text-align: center;
}

.choose-location-wrapper {
    background: var(--royal-white);
    display: flex;
    width: 100%;
    height: 50px;
    margin: 15px auto;
    align-content: center;
    justify-items: center;
    align-items: center;
    border-radius: 40px;
    justify-content: space-between;
}

.by-route, .by-location {
    display: flex;
    width: 170px;
    margin: 5px;
    height: 40px;
    color: var(--grey);
    align-items: center;
    border-radius: 40px;
    border: none;
    font-weight: bold;
    justify-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.3rem;
    transition: 0.2s ease-in-out;
}

.by-location.selected,
.by-route.selected {
    background-color: var(--royal-blue);
    color: white;
}

.by-route:hover, .by-location:hover {
    background: var(--light-grey-hovered);
    transition: 0.2s ease-in-out;
}

.by-route:focus, .by-location:focus {
    background: var(--royal-blue);
    transition: 0.2s ease-in-out;
    color: var(--royal-white);
}

.choose-location-icon {
    margin: 0 10px 0 0;

}

.carrier-loads-dashboard-content-body {
    display: flex;
    width: 100%;
    margin: 20px 0 20px 0;
    border-radius: 30px;
    flex-direction: column;
}

.carrier-qoutes-dashboard-content-body {
    display: flex;
    width: 100%;
    margin: 20px 0 20px 0;
    border-radius: 30px;
}

.carrier-listing-container {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100%;
    border-radius: 30px;
    overflow: scroll;
    overflow-x: hidden;

}

.carrier-dashboard-chat-metric {
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
}

.carrier-dashboard-side-panel-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 560px;
    width: 100%;
    margin: 0 0 0 20px;
}

.carrier-map-container {
    display: flex;
    width: 100%;
    max-height: 250px;
    height: 100%;
    border-radius: 30px;
}

.carrier-dashboard-side-panel {
    display: flex;
    margin: 20px 0 0 0;
    flex-direction: column;
    width: 100%;
    max-height: 600px;
    height: 100%;
    border-radius: 30px;
    overflow: scroll;
    border: 1px solid var(--light-grey);
}

.carrier-dashboard-side-panel::-webkit-scrollbar {
    width: 1px;
}


.load-status-section {
    display: flex;
    padding: 5px 10px 5px 10px;
    margin: 0 0 10px 0;
    border-radius: 15px;
    width: fit-content;
    color: var(--grey);
    font-weight: 700;
    font-size: 1.2rem;
    background: var(--light-grey);
    justify-content: space-between;
    align-items: center;
}

.load-directions {
    display: flex;
}


.load-directions-icon {

}

.load-container-info {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    justify-items: center;
}

.load-container-carrier {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 155px;
    height: 100%;
}

.carrier-icon {
    background: none;
    z-index: 999;
}


.origin-destination-container {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 5px;
    justify-content: space-between;
    height: 85px;
}


.load-directions-description {
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0;
    color: var(--light-grey);

}

.load-status-icon {
    display: flex;
    width: 7px;
    height: 7px;
    margin: 0 4px 0 0;
    align-items: center;
    justify-content: center;
    background: var(--royal-green);
    border-radius: 30px;

}

.carrier-info-google-map-container {
    border-radius: 15px;
    overflow: hidden;
}


.ai-chat-content {
    display: flex;
    width: 100%;
    height: 730px;
    padding: 0 15px 0 15px;
    border-radius: 40px;
    border: 1px solid var(--light-grey);
}

.chat-carrier-button {
    display: flex;
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 30px;
    background: var(--royal-green);
    color: var(--royal-white);
    font-size: 1.2rem;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;
}

.carrier-dashboard-load-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    align-content: center;
    margin: 20px 0 0 0;
}

.carrier-filter-buttons {
    width: 450px;
    display: flex;
    justify-content: space-between;
}

.filter-buttons-carrier {
    width: 200px;
    background: var(--royal-white);
    color: var(--royal-blue);
    font-size: 2rem;
    font-weight: bold;
    transition: 0.2s ease-in-out;
    border-radius: 55px;
}

.filter-buttons-carrier:hover {
    background: var(--royal-blue);
    transition: 0.2s ease-in-out;
    color: var(--royal-white);
}


.button-nav-load-icon {
    margin-right: 10px;
}


.carrier-loads-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 750px;
    margin: 20px 0 0 0;
    overflow: scroll;
}

.carrier-loads-wrapper-qoutes {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 850px;
    margin: 20px 0 0 0;
    overflow: scroll;
}

.carrier-loads-qoutes-listing {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 850px;
    padding: 0;
    margin: 0;
    border-radius: 30px;
    border: 2px solid var(--light-grey);
}

.listing-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100px;
    border-bottom: 1px solid var(--light-grey);
    padding: 0 30px 0 30px;
}

.listing-header section {
    margin: 20px 0 0 0;
}

.listing-header section h2 {
    font-size: 3rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.listing-header section p {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--light-grey);
}

.carrier-dashboard-load-container {
    display: flex;
    width: 100%;
    height: 395px;
    margin: 40px 0 0 0;
    border: 1px solid var(--light-grey);
    border-radius: 80px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.carrier-dashboard-load-container:hover {
    background: var(--light-grey-hovered);
    border: 1px solid var(--royal-blue);
    transition: 0.2s ease-in-out;
}

.carrier-load-container-info {
    display: flex;
    width: 50%;
    margin: 0 0 0 30px;
    justify-content: space-between;
    height: 100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
}

.carrier-load-info-main {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    padding: 60px 0 60px 40px;
}

.carrier-load-info-additional {
    display: flex;
    flex-direction: column;
    align-content: start;
    justify-content: space-between;
    margin: 0 30px 0 0;
}

.carrier-load-logo {
    width: 250px;
    height: 200px;
}

.carrier-load-info-additional span {
    padding: 10px;
    margin: 10px 0 10px 0;
    color: var(--grey);
    font-size: 2rem;
    font-weight: bold;
    display: inline-block;
    width: fit-content;
    text-align: center;
    border-radius: 20px;
    background: var(--light-grey);
}

.map-load-section {
    width: 50%;
    height: 100%;
}

.status-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--royal-green);
    margin: 0 0 0 5px;
}

.carrier-load-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 85px;
    height: 40px;
    justify-items: center;
    border-radius: 33px;
    background: var(--light-grey);
}

.status-text {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: var(--grey);
    margin: 0 5px 0 5px;
}

.carrier-load-price {
    color: var(--royal-green);
    font-size: 4rem;
    font-weight: bold;
}

.carrier-load-direction-wrapper {
    display: flex;
    align-items: center;
    height: 120px;
}

.carrier-load-direction {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.carrier-load-direction-origin {
    margin: 15px 0 0 0;
}

.carrier-load-direction-origin h3 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.carrier-load-direction-origin p {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--light-grey);

}

.carrier-load-direction-destination {
    margin: 5px 0 0 0;
}

.carrier-load-direction-destination h3 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.carrier-load-direction-destination p {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--light-grey);
}


.carrier-deal-conversations-sidebar {
    width: 400px;
    margin: 20px auto;
}

.images-preview-wrapper {
    display: flex;
}

.file-image-preview {
    width: 70px;
    border-radius: 8px;
    margin: 5px 0 0 15px;
    object-fit: cover;
}

.send-bol-button {
    display: flex;
    width: 200px;
    height: fit-content;
    border: none;
    margin: 0 10px 0 0;
    border-radius: 8px;
    background: var(--royal-green-hovered);
    color: var(--royal-white);
    font-size: 1.8rem;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;
}

.send-bol-button:hover {
    background: var(--royal-green);
    transition: 0.2s ease-in-out;
}

.pay-button {
    display: flex;
    width: fit-content;
    padding: 7px 15px 7px 15px;
    border: none;
    margin: 0 10px 0 0;
    border-radius: 30px;
    background: var(--royal-green);
    color: var(--royal-white);
    font-size: 1.8rem;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;
}

.pay-button:hover {
    background: var(--royal-green-hovered);
    transition: 0.2s ease-in-out;
}

.payment-loader {
    margin: 0 5px 0 0;
}

.waiting-for-approval-button {
    display: flex;
    width: fit-content;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: center;
    border: none;
    margin: 0 10px 0 0;
    border-radius: 30px;
    background: var(--grey);
    color: var(--royal-white);
    font-size: 1.6rem;
    transition: 0.2s ease-in-out;
}

.fade-loader {
    margin: 0 5px 0 0;
}


.chat-input-icons {
    margin: 0 15px 0 5px;
    cursor: pointer;
    transition: 0.1s ease-in-out;
}

.chat-input-icons:hover {
    transition: 0.1s ease-in-out;
    cursor: pointer;
}


.chat-input-area input {
    font-size: 1.5rem;
    font-weight: normal;
}

.chat-input-area input::placeholder {
    color: var(--light-grey);
}

.chat-send-button {
    color: var(--royal-white);
    font-size: 1.5rem;
    width: 60px;
    height: 55px;
    margin: 0 0 0 10px;
    font-weight: bold;
    border-radius: 30px;
    transition: 0.2s ease-in-out;
    border: none;
}

.chat-send-button:hover {
    background: var(--royal-blue-light-transparent);
    transition: 0.2s ease-in-out;

}


.choose-chat-conversation {
    margin: 230px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;

}

.choose-chat-conversation p {
    border-radius: 25px;
    padding: 45px;
    font-weight: bold;
    font-size: 2rem;
    color: var(--dark-grey);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add this line */
    background: var(--royal-white);
}


.password-settings-container {
    display: flex;
    margin: 20px 0 0 0;
    flex-direction: column;
    justify-content: space-between;
    width: 350px;
}


.settings-content h2 {
    border-bottom: 1px solid var(--light-grey);
    font-size: 3rem;
    font-weight: bold;
    padding-bottom: 20px;
    color: var(--grey);
    margin: 30px 0 0 0;
}

.settings-content p {
    font-size: 1.8rem;
    font-weight: normal;
    color: var(--light-grey);
    margin: 20px 0 0 0;

}


.apply-settings-button {
    background: var(--royal-green);
    border: none;
    width: 150px;
    height: 50px;
    margin: 0 auto;
    font-size: 1.7rem;
    font-weight: bold;
    border-radius: 25px;
    transition: 0.2s ease-in-out;
}

.apply-settings-button:hover {
    background: var(--royal-green-hovered);
    color: var(--royal-white);
    transition: 0.2s ease-in-out;
}


.apply-settings-button-bottom {
    background: var(--royal-green);
    border: none;
    width: 150px;
    height: 50px;
    font-size: 1.7rem;
    font-weight: bold;
    border-radius: 25px;
    transition: 0.2s ease-in-out;
}

.apply-settings-button-bottom:hover {
    background: var(--royal-green-hovered);
    color: var(--royal-white);
    transition: 0.2s ease-in-out;
}


.delete-bin-icon {
    width: 19px;
    height: 19px;
    margin: 0 0 0 3px;
}

.deleting-account-section hr {
    width: 100%;
    border: 1px solid var(--light-grey);
    margin: 0 0 25px 0;
}

.deleting-account-section a {
    color: var(--royal-red);
    font-size: 1.7rem;
    font-weight: bold;
    transition: 0.2s ease-in-out;
    padding: 30px 0 0 0;
}

.avatar-settings {
    margin: 0 auto;
    align-content: center;
    justify-content: center;
    text-align: center;
    align-items: center;
    justify-items: center;
    display: flex;
    height: 250px;
    flex-direction: column;
}

.avatar-user-photo {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    background: var(--royal-blue);
    color: var(--royal-white);
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

}

.avatar-settings-wrapper {
    width: 250px;
    display: flex;
    justify-content: space-between;
    margin: 20px 0 20px 0;
}

.carrier-change-avatar {
    background: var(--royal-blue);
    border: none;
    width: 150px;
    height: 50px;
    font-size: 1.7rem;
    font-weight: bold;
    border-radius: 25px;
    transition: 0.2s ease-in-out;
}

.carrier-change-avatar:hover {
    background: var(--royal-blue-hovered);
    color: var(--royal-white);
    transition: 0.2s ease-in-out;
}

.delete-avatar {
    color: var(--royal-red);
    border: none;
    width: 150px;
    height: 50px;
    font-size: 1.7rem;
    font-weight: bold;
    border-radius: 25px;
    transition: 0.2s ease-in-out;
}

.delete-avatar:hover {
    background: var(--royal-red-hovered);
    color: var(--royal-white);
    transition: 0.2s ease-in-out;
}


.account-info-settings-1 {
    width: 225px;
}

.account-info-settings-2 {
    width: 225px;
}

.avatar-settings-icon {
    height: 150px;
    border-radius: 50%;
    background: var(--royal-blue);
    color: var(--royal-white);
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}


.create-driver-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    z-index: 999;
    justify-content: center;
    align-items: center;
}

.create-driver-popup {
    width: 900px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    background: white;
    border-radius: 10px;
    box-sizing: border-box;
    position: relative;
}

.create-driver-popup section {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 15px 15px 10px 20px;
    align-items: center;
    border-bottom: 1px solid var(--light-grey);
}

.create-driver-popup section div p {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--grey);

}

.create-driver-popup section h3 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.close-button-popup-button {
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--royal-red);
    cursor: pointer;
    transition: 0.1s ease-in-out;
    border-radius: 30px;
}

.close-button-popup-button button:hover {
    background: var(--light-grey-hovered);
    transition: 0.1s ease-in-out;
}

.add-driver-avatar {
    display: flex;
    width: 300px;
    height: 300px;
    margin: 60px auto;
    object-fit: cover;
    color: var(--light-grey);
    border-radius: 50%;
    justify-content: center;
    border: 2px dashed var(--light-grey);
    align-items: center;
    align-content: center;
    cursor: pointer;
    justify-items: center;
    transition: 0.2s ease-in-out;
}

.add-driver-avatar:hover {
    transition: 0.2s ease-in-out;
    background: var(--light-grey-hovered);
    color: var(--royal-blue);
    border: 2px dashed var(--royal-blue);
}

.create-driver-input {
    display: flex;
    width: 400px;
    justify-content: center;
    border: none;
    margin: 0 30px 0 0;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-items: center;
    font-weight: bold;
    font-size: 1.3rem;
    padding: 0 5px 0 20px;
    transition: 0.2s ease-in-out;
}

.create-driver-button {
    background: var(--royal-green);
    border: none;
    height: fit-content;
    width: fit-content;
    padding: 10px 18px 10px 18px;
    color: var(--royal-white);
    font-size: 1.7rem;
    font-weight: bold;
    border-radius: 25px;
    transition: 0.2s ease-in-out;
}

.close-popup-button {
    color: var(--royal-red);
    width: fit-content;
    height: fit-content;
    font-size: 1.5rem;
    padding: 10px 15px 10px 15px;
    display: flex;
    transition: 0.2s ease-in-out;
    border: none;
    border-radius: 30px;
}

.close-popup-button:hover {
    background: var(--light-grey-hovered);
}

.create-driver-button:hover {
    background: var(--royal-green-hovered);
    transition: 0.2s ease-in-out;
}

.create-driver-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 100%;
    flex-direction: column;
    padding: 0 30px 0 30px;
    align-items: center;
}

.create-driver-content {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    justify-content: space-between;
}

.add-driver-description {
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    margin: 15px 0 10px 0

}

.add-driver-description h2 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--dark-grey);
}

.add-driver-description p {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--grey);

}

.create-driver-wrapper-success {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.create-driver-wrapper-success section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
    justify-items: flex-start;
    text-align: left;
    height: 320px;
    border-bottom: 1px solid var(--light-grey);
}

.create-driver-wrapper-success section span p {
    font-size: 1.5rem;
    margin: 0;
    font-weight: bold;
    color: var(--grey);
}

.create-driver-wrapper-success button {
    background: var(--royal-green);
    border: none;
    width: 300px;
    height: 50px;
    margin: 15px auto;
    color: var(--royal-white);
    font-size: 1.7rem;
    font-weight: bold;
    border-radius: 25px;
    transition: 0.2s ease-in-out;
}

.create-driver-wrapper-success button:hover {
    background: var(--royal-green-hovered);
    transition: 0.2s ease-in-out;
}


.assign-driver-popup-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.assign-driver-popup {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 25%;
    right: 25%;
    top: 25%;
    z-index: 1001;
    bottom: 25%;
    margin: auto;
    border-radius: 20px;
    background: white;
    text-align: center;
}

.assign-driver-popup-header {
    width: 100%;
    justify-content: space-between;
    display: flex;
    padding: 20px;
    border-bottom: 1px solid var(--light-grey);
}

.assign-driver-popup-header h1 {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--dark-grey);
}

.assign-driver-popup-content {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    overflow-x: hidden;
}

.driver-container-entity {
    display: flex;
    width: 100%;
    padding: 20px;
    gap: 20px;
    justify-content: space-between;
    margin: 5px 0 5px 0;
    align-content: center;
    align-items: center;
    justify-items: center;
    border-radius: 15px;
    transition: 0.2s ease;

}

.driver-container-entity:hover {
    background: var(--light-grey-hovered);
}

.driver-entity-info {
    display: flex;
}

.driver-entity-info section {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 0 0 0 10px;
}

.driver-entity-info section h2 {
    color: var(--dark-grey);
    font-size: 15px;
}

.driver-entity-info section p {
    color: var(--grey);
    font-size: 13px;
}

.assign-driver-button {
    background: var(--royal-green);
    border: none;
    width: fit-content;
    height: 30px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: var(--royal-white);
    font-size: 1.2rem;
    border-radius: 25px;
    transition: 0.2s ease-in-out;
}

.assign-driver-button:hover {
    background: var(--royal-green-hovered);
    transition: 0.2s ease-in-out;
}

.filter-button-carrier {
    display: none;
}

.condition-load-containers-bids-text {
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin: 120px auto;
    font-size: 1.8rem;
    color: var(--light-grey);
}

.load-filter-weight {
    padding: 0 20px 0 20px;
}

.load-filter-weight label {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--light-grey-hovered);
}

.load-filter-weight section {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.load-filter-weight section input {
    display: flex;
    padding: 0 20px 0 20px;
    border: none;
    height: 40px;
    background: white;
    color: var(--grey);
    background: var(--bs-white);
    border-radius: 50px;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
}

@media screen and (max-width: 1024px) {
    .filter-loads-container {
        display: none;
    }

    .filter-button-carrier {
        display: block;
    }

    .loads-containers-block {
        padding: 10px 0 0 0;
    }

    .carrier-load-directions {
        max-width: 350px;
        width: 100%;
    }

    .load-filter-container section {
        width: 100%;
    }
}