.dashboard-sidebar-wrapper {
    display: flex;
    position: relative;
}

.dashboard-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    height: 100vh;
    padding: 10px 10px 35px 10px;
    font-weight: bold;
    color: var(--light-grey);
    background: var(--royal-white);
    border-right: 1px solid var(--light-grey);
    transition: 0.2s ease-in-out;
}

.dashboard-sidebar-main-buttons {
    display: flex;
    height: fit-content;
    gap: 5px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.dashboard-main-button{
    display: flex;
    margin: 0 0 20px 0;
    width: 100%;
}

.dashboard-logo {
    max-width: 200px;
    display: flex;
    margin: 0 auto;
    object-fit: contain;
    width: 100%;
}

.dashboard-link-nav-button {
    display: flex;
    width: 220px;
    height: fit-content;
    font-weight: 500;
    color: var(--grey);
    text-decoration: none;
    font-size: 1.5rem;
    padding: 7px 10px 7px 10px;
    border-radius: 0 7px 7px 0;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    transition: 0.2s ease-in-out;
    position: relative;
}

.dashboard-link-nav-button::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 6px;
    border-radius: 0 50px 50px 0;
    height: 0;
    background-color: var(--royal-blue-light);
    transition: height 0.2s ease-in-out;
}

.dashboard-link-nav-button:hover::before {
    height: 100%;
    -webkit-box-shadow: 0px 3px 94px -15px rgba(7,175,240,1);
    -moz-box-shadow: 0px 3px 94px -15px rgba(7,175,240,1);
    box-shadow: 0px 3px 94px -15px rgba(7,175,240,1);
}

.dashboard-link-nav-button:hover {
    background: var(--light-grey-hovered-2);
    color: var(--royal-blue-light);
}

.dashboard-link-nav-button:hover .dashboard-link-nav-button-icon {
    color: var(--royal-blue-light);
}

.dashboard-link-nav-button-icon {
    margin-right: 10px;
    width: 25px;
    height: 25px;
}

.dashboard-link-nav-button-logout {
    display: flex;
    width: 220px;
    height: 45px;
    font-weight: bold;
    color: var(--light-grey);
    text-decoration: none;
    font-size: 1.5rem;
    border-radius: 30px;
    text-align: center;
    align-content: center;
    align-items: center;
    padding: 0 0 0 20px;
    justify-content: flex-start;
    transition: 0.2s ease-in-out;
}

.dashboard-link-nav-button-logout:hover {
    transition: 0.2s ease-in-out;
    color: var(--royal-white);
    background: var(--royal-red);
}

.close-dashboard-side-bar {
    height: 40px;
    border-radius: 30px;
    width: 40px;
    margin: 20px 0 0 10px;
    box-shadow: 1px 1px 5px 0px var(--light-grey);
    transition: transform 0.3s ease-in-out;
}

.rotated {
    transition: 0.2s ease-in-out;
    transform: rotate(180deg);
}

.closed {
    transition: 0.2s ease-in-out;
    width: 0;
    transform: translateX(-150px);
}

@media screen and (max-width: 1440px) {

    .dashboard-sidebar {
        width: 200px;
        padding: 5px;
    }

    .dashboard-link-nav-button {
        font-size: 1.3rem;
        width: 180px;
        padding: 0 0 0 20px;
    }


    .dashboard-link-nav-button-icon {
        width: 20px;
        height: 20px;
    }

    .dashboard-link-nav-button-logout {
        width: 180px;
        font-size: 1.3rem;
        padding: 0 0 0 30px;
        margin: 0 0 15px 0;
    }
}

.mobile-sidebar-toggle {
    display: none;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1000;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

@keyframes slideIn {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.mobile-sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 999;
    display: flex;
    justify-content: flex-start;
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes slideOut {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

.mobile-sidebar.closing {
    animation: slideOut 0.3s ease-in-out;
}

.mobile-sidebar {
    background: #fff;
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100dvh;
    overflow-y: auto;
    padding: 20px 20px 40px 20px;
    animation: slideIn 0.3s ease-in-out;
}

.mobile-sidebar.hidden {
    animation: slideOut 0.3s ease-in-out;
}

@media (max-width: 768px) {

    .dashboard-sidebar-main-buttons {
        gap: 7px;
    }

    .dashboard-link-nav-button{
        font-size: 1.4rem;
        width: 180px;
        padding: 10px 10px 10px 20px;
    }

    .mobile-sidebar-toggle {
        display: block;
    }

    .dashboard-sidebar {
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    .dashboard-sidebar {
        display: none;
    }
}