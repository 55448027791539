.success-payment-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.animated-checkmark {
    width: 100px;
    height: 100px;
    display: block;
    margin: 0 auto;
}

.circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: var(--royal-green);
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.tick {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: var(--royal-green);
    fill: none;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards, scale 0.1s ease-in-out 0.9s both;
}

@keyframes text-animation {
    0% { opacity: 0; transform: translateY(20px); }
    100% { opacity: 1; transform: translateY(0); }
}

.animated-text {
    font-size: 20px;
    margin: 0 0 20px 0;
    font-weight: bold;
    color: var(--royal-green);
    animation: text-animation 1s ease-in-out forwards;
}

.animated-text-secondary{
    font-size: 15px;
    margin: 20px 0 20px 0;
    font-weight: normal;
    color: var(--light-grey);
    animation: text-animation 1.5s ease-in-out forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}