.benefitWrapper {
  margin: 90px auto auto auto;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h1{
    text-align: center;
    display: flex;
    margin: 90px auto 90px auto;
    font-weight: bold;
    font-size: 5rem;
    color: var(--dark-grey);
  }
}

.benefitWrapperContent {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 20px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 1250px){
  .benefitWrapper{
    padding: 20px;
  }
}

@media screen and (max-width: 1024px){
  .benefitWrapperContent{
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    padding: 20px;
  }
}

@media screen and (max-width: 768px){
  .benefitWrapper{


    h1{
      text-align: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      font-size: 4rem;
    }
  }

}