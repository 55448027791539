.header2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: sticky;
  flex-direction: column;
  top: 0;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.64);
  z-index: 1000;
  margin: 0 auto;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.03);
  transition: all 0.3s ease;

  &.header2Scrolled {
    width: 80%;
    top: 1%;
    margin: 0 auto;
    padding: 0 20px;
    border-radius: 55px;
    border: 1px solid var(--light-grey-hovered);

    @media screen and (max-width: 1028px) {
      padding: 0 10px;
      width: 95%;
    }
  }

  .headerLogButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    gap: 25px;

    @media screen and (max-width: 1028px) {
      display: none;
    }
  }
}

.burgerMenu {
  display: none;
  flex-direction: column;
  height: 100%;
  width: 97%;
  padding: 10px 20px;
  transform: translateX(100%);
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  z-index: 9999;
}

.burgerMenuOpen {
  display: flex;
  transform: translateX(0);
  opacity: 1;
}

.burgerContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.navIcon {
  color: var(--royal-green-dark);
  font-size: 2rem;
}

.navLinkBurger {
  font-size: 1.7rem;
  font-weight: bold;
  color: var(--dark-grey);
  text-decoration: none;
  display: flex;
  padding: 10px 0 10px 15px;
  align-content: flex-start;
  align-items: center;
  justify-items: center;
  gap: 10px;
  justify-content: flex-start;
  border-radius: 5px;
  position: relative;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    background: rgba(128, 128, 128, 0.06);
  }

  &:hover::before,
  &:focus::before,
  &:active::before,
  &:focus-visible::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    border-radius: 55px;
    background-color: var(--royal-green);
  }
}

.authButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 25px;
}

.signIn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.5rem;
  padding: 10px 20px;
  border-radius: 55px;
  border: 1px solid var(--royal-green);
  color: var(--royal-green);
  text-decoration: none;
  transition: 0.3s all ease-in-out;

  &:hover {
    background: var(--royal-green);
    color: var(--royal-white);
  }
}

.signUp {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.5rem;
  padding: 10px 20px;
  border-radius: 55px;
  border: 1px solid var(--royal-green);
  color: var(--royal-white);
  background: var(--royal-green);
  text-decoration: none;
  transition: 0.3s all ease-in-out;

  &:hover {
    background: var(--royal-green);
    color: var(--royal-white);
  }
}


.sidebarIcon {
  display: none;
  width: 50px;
  height: 50px;
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50px;
  border: none;
  background: none;
  transition: all 0.3s ease-out;

  @media screen and (max-width: 1028px) {
    display: flex;
  }

  &:hover {
    background: var(--light-grey-hovered-2);
  }
}

.logo {
  display: flex;
}

.headerInner2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  @media screen and (max-width: 1028px) {
    padding: 0 20px;
  }
}

.bars {
  display: none;
}

.loginButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  color: var(--dark-grey);
  transition: color 0.2s ease-in-out;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    border-radius: 50px;
    height: 2px;
    background-color: var(--dark-grey);
    transition: width 0.3s ease-in-out;
  }

  &:hover::after {
    width: 100%;
  }
}

.navContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  gap: 25px;
}

.registerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  color: var(--royal-white);
  border-radius: 50px;
  height: fit-content;
  padding: 7px 15px 7px 15px;
  background: linear-gradient(90deg, #3f833f, #3ca53c 100%);
  transition: 0.2s ease-in-out;

  &:hover {
    transition: 0.2s ease-in-out;
    background: var(--royal-green);
  }
}

.navLink {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--dark-grey);
  text-decoration: none;
  display: flex;
  position: relative;
  transition: color 0.2s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    border-radius: 50px;
    height: 2px;
    background-color: var(--dark-grey);
    transition: width 0.3s ease-in-out;
  }

  &:hover::after {
    width: 100%;
  }
}

.sidebar {
  display: none;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 300px;
  background-color: var(--royal-white);
  padding: 50px 20px;
  box-shadow: -5px 0px 10px 0px rgba(0, 0, 0, 0.1);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 9999;
}

.showSidebar {
  transform: translateX(0);
}

.closeButton {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: none;
  margin: 0 0 0 auto;
  transition: 0.2s ease-in-out;

  &:hover {
    background: var(--light-grey-hovered-2);
  }
}

.navContentSidebar {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.navContentSidebarSection {
  display: flex;
  width: 100%;
  padding: 20px;
  gap: 20px;
}

@media screen and (max-width: 1024px) {
  .navContent {
    display: none;
  }

  .loginButton {
    width: 100%;
    font-size: 2rem;
  }

  .registerButton {
    width: 100%;
    font-size: 2rem;
  }

  .navLink {
    font-size: 2rem;
  }

  .sidebar {
    display: flex;
  }

  .header section {
    display: none;
  }

  .bars {
    display: flex;
    width: 50px;
    height: 50px;
    align-content: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50px;
    border: none;
    background: none;

    &:hover {
      background: var(--light-grey-hovered-2);
    }
  }
}