.recommendation-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    margin: 0 0 15px 20px;
}

.recommendation-container h2{
    color: var(--dark-grey);
    font-size: 2.5rem;
    font-weight: bold;
    width: 100%;
}

.recommendation-container p{
    color: var(--grey);
    font-size: 1.9rem;
    width: 100%;
}