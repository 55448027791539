.flexContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: start;
  align-items: start;
  margin: 10px 0 0 0;
}

.flexContainerTitle {
  display: flex;
  font-weight: bold;
  font-size: 1.5rem;
  color: var(--dark-grey);
}

.flexContainerContent {
  display: flex;
  gap: 10px;
}
