.chat-container-wrapper {
    display: flex;
    position: relative;
    border: 1px solid var(--light-grey);
    border-radius: 40px;
    height: 100%;
    background: var(--royal-white);
}

.chat-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.chat-starting-screen {
    width: 100%;
    display: flex;
    max-width: 800px;
    height: 100%;
    margin: 5px 10px 0 0;
    border-radius: 8px;
    padding: 10px;
    overflow: hidden;
    justify-content: space-between;
    flex-direction: column;
    transition: 0.2s ease-in-out;
}

.question-buttons {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    justify-content: center;
    align-items: center;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    gap: 20px; /* Add some space between the items */
}

.stars-chat-icon {
    margin: 0 3px 0 0;
}

.question-buttons section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; /* Adjust width to fit 2 per line, accounting for gap */
    flex-direction: column;
}

.question-buttons section button {
    width: 100%;
    display: flex;
    align-items: center;
    color: var(--grey);
    font-weight: bold;
    border: none;
    background: var(--light-grey-hovered-2);
    font-size: 14px;
    padding: 15px;
    border-radius: 50px;
    transition: 0.2s ease-in-out;
}

.question-buttons section button:hover {
    box-shadow: 0 0 4px 0 var(--royal-blue);
    background: var(--light-grey-hovered);
}

.question-buttons section button:first-child {
    margin: 0 3px 0 15px;
}

.chat-window {
    width: 90%;
    height: 575px;
    margin: 5px 10px 0 0;
    border-radius: 8px;
    padding: 10px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}

.chat-window::-webkit-scrollbar {
    background: none;
}

.chat-window::-webkit-scrollbar-track {
    background: none;
}

.chat-window::-webkit-scrollbar-thumb {
    background-color: #0A53BE; /* Color of the scrollbar thumb */
    border-radius: 4px; /* Optional: roundness of the scrollbar thumb */
    border: 2px solid #F5F5F5; /* Optional: border around the scrollbar thumb */
}

.chat-window {
    scrollbar-color: var(--light-grey) rgba(245, 245, 245, 0); /* thumb and track color */
}

.chat-message {
    display: flex;
    align-items: flex-start;
    margin: 10px 0;
    opacity: 0;
    transform: translateY(20px);
    animation: appear 0.5s forwards;
}

.user {
    flex-direction: row-reverse;
}

.assistant {
    flex-direction: row;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 10px;
}

.user-avatar {
    width: 40px; /* Adjust size as needed */
    height: 40px; /* Adjust size as needed */
    border-radius: 50%; /* Circular avatar */
    object-fit: cover; /* Ensure the image covers the area without distortion */
}

.assistant-avatar {
    background-image: url('../../assets/images/ai-avatar.svg');
    background-size: cover;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid var(--light-grey-hovered);
}

.message-content {
    background-color: #f6f6f6;
    padding: 10px;
    font-size: 14px;
    border-radius: 8px;
    max-width: 70%;
    color: #1a1d20;
    word-wrap: break-word;
}

.message-label {
    font-weight: bold;
    font-size: 13px;
    color: var(--dark-grey);
}

.user .message-content {
    background-color: rgba(0, 39, 172, 0.07);
}

.input-container-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 50px;
    width: fit-content;
    height: fit-content;
    background: var(--light-grey-hovered-2);

    @media screen and (max-width: 1028px) {
        border-radius: 20px;
    }
}

.input-container {
    display: flex;
    width: 100%;
    background: var(--light-grey-hovered-2);
    max-width: 600px;
    border-radius: 50px;
    color: var(--grey);
    gap: 10px;
    padding: 15px 25px 15px 25px;

    input {
        font-size: 20px;
        font-weight: bold;
        width: 100%;
        color: var(--grey);
    }
}

.ai-chat-input-icons {
    font-size: 18px;
    color: var(--grey);
}

.input-container button {
    padding: 10px 16px 10px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50px;
    background: none;
    transition: 0.2s ease-in-out;
}

.info {
    width: 480px;
    font-size: 11px;
    padding: 10px;
    color: var(--grey);
    text-align: center;
}

.input-container button:hover {
    background-color: var(--light-grey-hovered);
}

.greeting {
    font-size: 64px;
    background: -webkit-linear-gradient(var(--royal-blue), var(--royal-green));
    -webkit-background-clip: text;
    font-weight: bold;
    -webkit-text-fill-color: transparent;
}

.greeting-subtitle {
    font-size: 24px;
    font-weight: bold;
    background: -webkit-linear-gradient(var(--grey-darker), var(--grey-design));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.burger-button {
    display: flex;
    align-content: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    border: none;
    background: none;
    border-radius: 50px;
    margin: 15px 0 0 15px;
    z-index: 10;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.times-close-sidebar-button {
    display: flex;
    border-radius: 50px;
    transition: 0.2s ease-in-out;
    border: none;
    padding: 15px 20px 15px 20px;
}

.times-close-sidebar-button:hover {
    background: var(--light-grey-hovered);

}

.bars-open-sidebar-button {
    display: flex;
    border-radius: 50px;
    transition: 0.2s ease-in-out;
    border: none;
    padding: 17px 15px 17px 15px;
}

.bars-open-sidebar-button:hover {
    background: var(--light-grey-hovered);

}

.chat-sidebar {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    justify-content: space-between;
    width: 0;
    position: relative;
    height: 100%;
    border-right: 1px solid var(--light-grey);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease-in-out;
}

.chat-sidebar.open {
    width: 100%;
    opacity: 1;
    visibility: visible;
    transition: 0.3s ease-in-out;
}

.chat-container-wrapper .chat-container {
    transition: margin-left 0.3s ease;
}

.chat-sidebar.closed {
    width: 0;
    z-index: -9999;
    transform: translateX(-250px);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.05s ease-in-out, visibility 0.05s ease-in-out, width 0.2s ease;
}

.chat-sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none;
    border-bottom: 1px solid #ccc;
    text-align: center;
    color: var(--grey);
    align-content: center;
    padding: 25px;
}

.add-chat-button {
    cursor: pointer;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    transition: 0.2s ease-in-out;
}

.add-chat-button:hover {
    background: var(--light-grey-hovered);
}

.chat-sidebar-content {
    width: 100%;
    padding: 15px;
    display: flex;
    height: 602px;
    flex-direction: column;
    overflow: scroll;
    overflow-x: hidden;
}

.chat-session-container {
    background: var(--light-grey-hovered-2);
    border-radius: 55px;
    padding: 15px 20px 15px 20px;
    font-weight: bold;
    font-size: 15px;
    color: var(--grey);
    margin: 5px 0 5px 0;
    transition: 0.2s ease-in-out;
    cursor: pointer;
}

.chat-session-container:hover {
    background: var(--light-grey-hovered);
    box-shadow: 0 0 4px 0 var(--royal-blue);
}

.chat-sidebar-content p {
    font-size: 15px;
    width: 220px;
    margin: 0;
    color: var(--grey);
}

.chat-sidebar-header h3 {
    color: var(--grey);
    margin: 0;
}

.chat-sidebar-footer {
    border-top: 1px solid var(--light-grey);
    padding: 15px;
}

.greeting-wrapper {
    display: flex;
    flex-direction: column;
    text-align: left;
    max-width: 650px;
    width: 100%;
    margin: 50px 0 0 0;
    height: 100px;
}

.chat-sidebar-footer p {
    width: 220px;
    margin: 0;
    color: var(--grey);
    text-align: center;
}

.chat-container-wrapper.open .chat-container {
    margin-left: 250px; /* Adjust based on sidebar width */
}

.chat-container-wrapper .chat-container {
    transition: margin-left 0.3s ease;
}

.typing-indicator {
    display: flex;
    align-items: center;
}

.image-preview {
    height: 60px;
    border-radius: 18px;
    margin: 10px 0 0 25px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    border: 1px solid transparent;
}

.greeting-subtitle {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    height: 100px;
    margin: 0 170px 0 0;

}

.image-preview:hover {
}

.recording-indicator {
    display: flex;
    margin: 10px 0 0 40px;
}

.bars-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 0 40px;

}

.dot {
    height: 8px;
    width: 8px;
    margin: 0 2px;
    background-color: #333;
    border-radius: 50%;
    display: inline-block;
    animation: typing 1.2s infinite;
}

@keyframes typing {
    0% {
        transform: scale(0.8);
        opacity: 0.6;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0.8);
        opacity: 0.6;
    }
}

.dot:nth-child(1) {
    animation-delay: 0s;
}

.dot:nth-child(2) {
    animation-delay: 0.2s;
}

.dot:nth-child(3) {
    animation-delay: 0.4s;
}


@keyframes appear {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@media screen and (max-width: 768px) {
    .burger-button {
        position: absolute;
        transition: transform 0.3s ease-in-out;
    }

    .chat-container-wrapper {
        height: 80vh;
        display: flex;
        border-radius: 25px;
    }

    .chat-container {
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
    }

    .info {
        width: 90%;
        padding: 3px 20px 3px 20px;
        font-size: 8px;
        text-align: center;
    }

    .info p {
        font-size: 8px;
    }

    .input-container-wrapper {
        margin: 0 15px 0 15px;
    }

    .input-container input::placeholder {
        font-size: 14px;
    }

    .question-buttons section button {
        font-size: 12px;
        margin: 0;
        padding: 10px;
    }

    .greeting-wrapper {
        margin: 75px 0 0 40px;
        max-width: 250px;
    }

    .greeting-wrapper h1 {
        font-size: 30px;
    }

    .greeting-subtitle {
        font-size: 20px;
    }

    .chat-sidebar {
        position: fixed;
        background: white;
        z-index: 1000;
        height: 85vh;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        border: 1px solid var(--light-grey);
    }
}
