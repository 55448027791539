
.google-input-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  cursor: pointer;

  select{
    font-size: 1.7rem;
  }
}

.loading-text{
  padding: 10px;
}

.suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 9999;
  display: flex;
  max-height: 500px;
  height: fit-content;
  overflow: scroll;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  border-radius: 5px;
  overflow-x: hidden;
  font-size: 1.7rem;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    width: 100%;
    transition: 0.3s all ease-in-out;

    &:hover {
      background-color: var(--light-grey-hovered);
    }
  }
}

.google-style-input {
  width: 100%;
  padding: 10px;
  height: 50px;
  cursor: pointer;
  border: 1px solid var(--light-grey);
  border-radius: 7px;
  color: var(--dark-grey);
  font-size: 1.7rem;
  outline: none;
  font-weight: normal;
  transition: 0.1s ease-in-out;
}

.optionValue {
  font-size: 1.8rem;
  font-weight: normal;
  padding: 10px;
  height: 150px;
  color: var(--dark-grey);
  border: none;
  border-radius: 0px;
  cursor: pointer;
}

.optionValueDisabled {
  font-size: 1.8rem;
  font-weight: normal;
  padding: 10px;
  color: transparent;
  background: none;
}

.google-style-input:focus {
  transition: 0.1s ease-in-out;
  border: 1px solid var(--royal-blue);
}

.google-style-input:hover {
  transition: 0.1s ease-in-out;
  border: 1px solid var(--royal-blue);
}

.google-style-input-label {
  position: absolute;
  top: 5px;
  font-weight: normal;
  font-size: 1.5rem;
  left: 10px;
  padding: 10px;
  transition: 0.3s;
  pointer-events: none;
  color: var(--light-grey);
  background-color: white;
}

.google-style-input:valid + .google-style-input-label,
.google-style-input:focus + .google-style-input-label,
.google-style-input:-webkit-autofill + .google-style-input-label {
  top: -11px;
  transition: 0.25s;
  color: var(--royal-blue);
  background-color: white;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0 5px;
}

.phone-input-container {
  width: 100%;
}

.google-style-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.input-error {
  border: 1px solid red;
  box-shadow: 0 0 2px red;
}

.input-success {
  border: 1px solid #11bf11;
  box-shadow: 0 0 1px #11bf11;
}

.error-message {
  color: red;
  font-size: 1.2rem;
  text-align: start;
  display: flex;
}

.filled {
  top: -11px;
  color: var(--royal-blue);
  background-color: white;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0 5px;
}




