.descriptionWrapper{
  display: flex;
  flex-direction: column;
  margin: 120px auto;
  max-width: 1440px;
  gap: 20px;
  height: fit-content;

  h1{
    font-size: 4rem;
    font-weight: bold;
    color: var(--dark-grey);
    text-align: center;

  }

  p {
    font-size: 2rem;
    font-weight: lighter;
    text-align: justify;
    width: 100%;
  }
}

.subDescription {
  margin-top: 20px;
  padding: 10px;
}

.subDescription h2 {
  padding: 10px 0 10px 0;
  margin: 0 0 10px 0;
  font-size: 2.3rem;
  color: var(--dark-grey);
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

.subDescription p {
  margin: 0;
  font-size: 1.7rem;
  display: flex;
  align-content: start;
  text-align: left;
  color: var(--grey);
}

.listItem{
  font-size: 1.7rem;
  color: var(--grey-darker);
  margin: 10px 0;


}

@media screen and (max-width: 1024px) {
  .descriptionWrapper{
    margin: 45px auto 25px auto;
   padding: 20px;
  }

}