.faqItem {
  display: flex;
  margin: 0 auto;
  max-width: 900px;
  max-height: fit-content;
  height: 120%;
  flex-direction: column;
  border-bottom: 1px solid var(--royal-blue);
  transition: 0.2s ease-in-out;

  p{
    font-size: 1.7rem;
    font-weight: 300;
    max-width: 800px;
    color: var(--grey-design);
    margin-bottom: 20px;
  }

  section {

    h2 {
      font-size: 2rem;
      font-weight: 600;
      color: var(--royal-blue);
      margin-bottom: 30px;
    }

    display: flex;
    justify-content: space-between;
    width: 100%;


    button {
      width: 50px;
      height: 50px;
      border: none;
      border-radius: 50px;
      color: var(--royal-blue);
      transition: 0.2s ease-in-out;

      &:hover{
        background: var(--light-grey-hovered);
      }
    }
  }
}