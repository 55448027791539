.universalSectionWrapper {
  align-content: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 90px auto 90px auto;
  max-width: fit-content;

  h1 {
    display: flex;
    color: var(--dark-grey);
    align-content: center;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    font-size: 5rem;
  }

  p {
    display: flex;
    color: var(--light-grey);
    align-content: center;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    max-width: 700px;
    margin: 0 auto;
  }
}

.truck {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;

  img {
    width: 100%;
    height: auto;
  }
}

.universalSectionContent {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 4fr);
  margin: 0 auto;
  gap: 15px;
}

@media screen and (max-width: 1650px) {
  .universalSectionContent {
    grid-template-columns: repeat(3, 3fr);
    align-content: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }
}

@media screen and (max-width: 1225px) {
  .universalSectionContent {
    grid-template-columns: repeat(2, 2fr);
    align-content: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }
}

@media screen and (max-width: 1024px) {
  .universalSectionWrapper {
    padding: 15px;
  }
}

@media screen and (max-width: 1225px) {
  .universalSectionContent{
    grid-template-columns: repeat(1, 1fr);
    align-content: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }
}