.switcher-wrapper{
    display: flex;
    margin-top: 1rem;
    width: 340px;
    justify-content: start;
    align-content: center;
    text-align: center;
    align-items: center;
    justify-items: center;
}

.switcher-wrapper span{
    padding: 0 0 0 15px;
    font-size: 1.3rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    color: var(--grey);
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

input:checked + .slider {
    background-color: var(--royal-blue);
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.question-icon{
    height: 20px;
    width: 20px;
    margin: 0 0 0 5px;
    cursor: pointer;
}

.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffffff;
    color: #333;
    width: 250px;
    padding: 15px;
    font-size: 2rem;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip p{
    color: var(--dark-grey);
    font-size: 1.5rem;
}

.tooltip-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
}