* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.driver-dashboard-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
}

.driver-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 300px;
    width: 100%;
    height: 100dvh;
    align-content: center;
    background-color: var(--royal-white);
    border-right: 1px solid var(--light-grey);
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
}

.driver-sidebar.hidden {
    transform: translateX(-250px);
    width: 0;
}

.driver-main-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 400px;
    justify-content: space-between;
}

.link-nav-button {
    display: flex;
    width: 220px;
    height: 55px;
    font-weight: bold;
    color: var(--light-grey);
    text-decoration: none;
    font-size: 1.8rem;
    padding: 0 10px 0 20px;
    border-radius: 30px;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    transition: 0.2s ease-in-out;
}

.link-nav-button-logout {
    display: flex;
    margin: 0 auto 30px auto;
    width: 220px;
    height: 55px;
    font-weight: bold;
    color: var(--light-grey);
    text-decoration: none;
    font-size: 1.8rem;
    padding: 0 10px 0 20px;
    border-radius: 30px;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    transition: 0.2s ease-in-out;
}

.link-nav-button-logout:hover {
    transition: 0.2s ease-in-out;
    color: var(--royal-white);
    background: var(--royal-red);
}

.link-nav-button-icon {
    margin-right: 10px;
    width: 25px;
    height: 25px;
}

.link-nav-button:hover {
    transition: 0.2s ease-in-out;
    background: var(--royal-blue);
    color: var(--royal-white);
}

.driver-dashboard-content {
    position: relative;
    padding: 20px;
    width: 100%;
}

.driver-map-direction-wrapper {
    height: 72dvh;
}

.driver-dashboard-content-assigned-load {
    height: 100%;
    display: flex;
    padding: 20px;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 768px) {
        padding: 5px;
    }
}

.driver-map-direction-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 30px;
    border-bottom-left-radius: 45px;
    border-bottom-right-radius: 45px;
    height: fit-content;
    background: var(--royal-white);
    border: 1px solid var(--light-grey);
}

.driver-map-direction-nav-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
}


.driver-map-direction-nav-header section h2 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--royal-green);
}

.driver-map-direction-nav-header section span {
    display: flex;
}

.driver-map-direction-nav-header section span p {
    color: var(--grey);
    margin: 0 10px 0 0;
    font-size: 14px;
}

.driver-map-direction-nav-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 500px;
    width: 100%;
    gap: 10px;
}

.pick-up-button {
    margin: 0 10px 0 0;
    padding: 5px 15px 5px 15px;
    background: var(--royal-green);
    color: var(--royal-white);
    border-radius: 30px;
    border: none;
    font-size: 17px;
    font-weight: bold;
    transition: 0.2s ease-in-out;
}

.pick-up-button:hover {
    background: var(--royal-green-hovered);
}

.transit-update-button {
    padding: 5px 15px 5px 15px;
    color: var(--royal-blue);
    border-radius: 30px;
    border: none;
    font-size: 17px;
    font-weight: bold;
    transition: 0.2s ease-in-out;
}

.transit-update-button:hover {
    background: var(--light-grey-hovered);
}

.exit-button {
    padding: 10px 20px;
    background: var(--royal-red);
    width: fit-content;
    height: fit-content;
    text-decoration: none;
    color: var(--royal-white);
    border-radius: 30px;
    border: none;
    font-size: 16px;
    font-weight: normal;
    transition: 0.3s ease-in-out;
}

.exit-button:hover {
    background: var(--royal-red-hovered);
}

.payments-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 850px;
}

.payment-selection-header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: start;
    width: 100%;
    height: 130px;
}

.payment-selection-title {
    font-size: 3rem;
    font-weight: bold;
    color: var(--dark-grey);
}


.payment-method-button {
    position: relative;
    border: none;
    display: flex;
    width: 150px;
    height: 50px;
    color: var(--light-grey);
    font-size: 2rem;
    font-weight: bold;
    justify-content: start;
    align-items: start;
    transition: 0.2s ease-in-out;
}

.payment-method-button:hover {
    color: var(--royal-blue);
    transition: 0.2s ease-in-out;
}

.payment-method-content {
    margin: 20px 0 0 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 750px;

}

.user-card-container {
    display: flex;
    justify-content: space-between;
    height: 650px;
    flex-direction: column;
}

.transaction-history {
    display: flex;
    flex-direction: column;
    width: 550px;
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
    border-right: 1px solid var(--light-grey);
    border-left: 1px solid var(--light-grey);
}

.transaction-history-header {
    display: flex;
    justify-content: space-between;
    padding: 0 20px 0 20px;
    align-items: center;
    width: 550px;
    height: 100px;
}

.transaction-history-header h3 {
    font-size: 3rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.transaction-history-header button {
    border: none;
    background: none;
    color: var(--royal-blue);
    font-size: 2rem;
    border-radius: 30px;
    font-weight: bold;
    transition: 0.2s ease-in-out;
}

.transaction-history-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 25px 0 25px;
    margin: 0 auto;
    height: 100px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    border-radius: 30px;

}

.transaction-history-item:hover {
    background: var(--light-grey-hovered);
    transition: 0.2s ease-in-out;

}

.transaction-history-item h4 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--royal-red);
}

.transaction-history-item section {
}

.transaction-history-item section h4 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--dark-grey);
}

.transaction-history-item section p {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--light-grey);
}

.transaction-history-header button:hover {
    background: var(--light-grey-hovered);
    transition: 0.2s ease-in-out;
}


.card-settings-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100px;
}

.card-settings-content a {
    width: 100%;
    text-decoration: none;
    font-size: 2rem;
    font-weight: bold;
    padding: 15px;
    color: var(--light-grey);
    transition: 0.2s ease-in-out;
    border-radius: 35px;
}

.card-settings-content a:hover {
    background: var(--light-grey-hovered);
    color: var(--dark-grey);
    transition: 0.2s ease-in-out;
}


.card-settings button {
    width: 200px;
    border: none;
    border-radius: 35px;
    background: none;
    font-size: 2rem;
    font-weight: bold;
    padding: 15px;
    border: 20px;
    background: var(--royal-red);
    transition: 0.2s ease-in-out;
}

.card-settings button:hover {
    background: var(--royal-red-hovered);
    transition: 0.2s ease-in-out;
}


.card-icon {
    transition: 0.2s ease;
    border-radius: 25px;
}

.card-icon:hover {
    transition: 0.2s ease;
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: 0px 0px 15px 0px var(--dark-grey);
}

.payment-method-button::after {
    content: '';
    position: absolute;
    border-radius: 30px;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    background: var(--royal-blue);
    transition: width 0.3s ease;
}

.payment-method-button:hover::after {
    width: 50%;
}

.driver-chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px 0;
    width: 100%;
    height: 90px;
    border-radius: 35px;
    background: var(--royal-white);
    padding: 0 30px 0 30px;
    box-shadow: 1px 1px 5px 0px var(--light-grey);
}

.driver-driver-chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
    height: 90px;

}

.driver-chat-header h1 {
    font-size: 3rem;
    font-weight: bold;
    color: var(--royal-blue);

}

.driver-account-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.driver-dashboard-content-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.driver-dashboard-account-info {
    display: flex;
    width: 330px;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    justify-items: center;
    margin: 0 0 0 40px;
}

.driver-profile-content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 850px;
}

.driver-profile-content {
    padding: 30px;
    display: flex;
    max-width: 1150px;
    width: 100%;
    border: 1px solid var(--light-grey);
    justify-content: space-between;
    height: 300px;
    border-radius: 25px;
}

.profile-content-wrapper {
    margin: 20px 0 0 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}


.driver-profile-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    height: 300px;
    border-radius: 35px;
    background: linear-gradient(to bottom, var(--light-grey-hovered), var(--light-grey));
    padding: 0 30px 0 30px;
    margin: 0 0 0 20px;
}

.driver-profile-status p {
    font-size: 2rem;
    font-weight: bold;
    color: var(--grey);
    margin: 0 0 10px 0;
}


.driver-profile-activity {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 1300px;
}

.driver-profile-activity section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 460px;
    border-radius: 25px;
    border: 1px solid var(--light-grey);
    height: 100%;
    padding: 30px;
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 75px;
    border-bottom: 1px solid var(--light-grey);
    background: var(--royal-white);
    padding: 0 30px 0 30px;
}

.section-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.section-content h3 {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--grey);

}

.section-header span h3 {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.section-header span p {
    font-size: 2rem;
    font-weight: bold;
    color: var(--light-grey);
}

.section-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100px;
    border-radius: 30px;
    padding: 0 10px 0 10px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.section-item div {
}

.section-item div h3 {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.section-item div p {
    font-size: 1.3rem;
    font-weight: bold;
    color: var(--grey);
}

.section-item:hover {
    background: var(--light-grey-hovered);
    transition: 0.2s ease-in-out;
}


.user-item-info {
    display: flex;
    width: 130px;
    text-align: left;
    align-items: center;
    align-content: center;
    justify-content: space-between;
}

.driver-info {
    display: flex;
    height: 100%;
}

.driver-profile-avatar {
    display: flex;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

.driver-details-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 0 20px;
    height: 200px;
}

.driver-role-name h3 {
    font-size: 3rem;
    font-weight: bold;
    color: var(--royal-blue);
    margin: 0 0 5px 0;

}

.driver-role-name p {
    font-size: 2.2rem;
    font-weight: bold;
    color: var(--light-grey);
}

.driver-info-details p {
    font-size: 2rem;
    font-weight: bold;
    color: var(--grey);
    margin: 0 0 10px 0;
}

.driver-nav-buttons {
    display: flex;
}


.driver-profile-content button {
    display: flex;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 10px;
    background: var(--light-grey);
    color: var(--royal-white);
    font-size: 1.8rem;
    margin: 0 5px 0 5px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;
}

.driver-profile-content button:hover {
    background: var(--light-grey-hovered);
    transition: 0.2s ease-in-out;
}


.account-info-text {
    margin: 10px 0 0 10px;
    height: 50px;
}


.account-info-name {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    color: var(--grey);
}

.account-info-role {
    font-size: 1.5rem;
    margin: 0;
    font-weight: bold;
    color: var(--light-grey);
}

.driver-dashboard-searchbar {
    background: var(--search-bar-background);
    display: flex;
    width: 300px;
    height: 70px;
    padding: 0 15px 0 17px;
    border-radius: 40px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    font-weight: bold;
    color: var(--grey);
    border: 2px solid var(--search-bar-background);
    font-size: 1.8rem;
    transition: 0.2s ease-in-out;
}

.driver-dashboard-searchbar:hover {
    border: 2px solid var(--royal-blue);
    transition: 0.2s ease-in-out;
}

.driver-dashboard-searchbar:focus {
    border: 2px solid var(--royal-blue);
    transition: 0.2s ease-in-out;
}

.driver-dashboard-searchbar input {
    width: 230px;
    height: 30px;
    padding: 0 20px;
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--grey);
    border: none;
}

.driver-dashboard-searchbar input::placeholder {
    color: var(--search-bar-placeholder-color)
}

.driver-dashboard-content-title {
    color: var(--royal-white);
    font-size: 3.5rem;
    background: linear-gradient(to right, var(--royal-blue), var(--royal-blue-hovered));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.driver-dashboard-content-subtitle {
    color: var(--light-grey);
    font-size: 2rem;
    font-weight: bold;
}

.close-driver-side-bar {
    height: 40px;
    border-radius: 30px;
    width: 40px;
    margin: 20px 0 0 10px;
    box-shadow: 1px 1px 5px 0px var(--light-grey);
}

.arrow-nav-close-open-side-bar {
    transition: transform 0.3s ease-in-out;
}

.arrow-nav-close-open-side-bar.rotated {
    transform: rotate(180deg);
}

.driver-dashboard-content-body {
    display: flex;
    width: 100%;
    position: relative;
    margin: 20px 0 20px 0;
    justify-content: space-between;
}

.driver-container-card-wrapper {
    display: flex;
    width: 100%;
    height: 750px;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    overflow: scroll;
    overflow-x: hidden;
    align-items: center;
    justify-items: center;
}

.carrier-dashboard-drivers-message {
    display: flex;
    width: 100%;
    height: 90%;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--light-grey);

}


.pickup-delivery-location-wrapper {
    display: flex;
    width: 100%;
    align-content: start;
    align-items: start;
    flex-direction: column;
    margin: 15px auto;
    justify-content: space-between;
}

.pickup-delivery-location-wrapper h3 {
    color: var(--royal-white);
}

.load-filter {
    display: flex;
    width: 100%;
    height: 60px;
    border-radius: 40px;
    border: none;
    flex-direction: column;
    text-align: left;
    font-weight: bold;
    justify-items: center;
    justify-content: center;
    font-size: 1.3rem;
    transition: 0.2s ease-in-out;
    padding: 0 0 0 20px;
    cursor: pointer;
}

.load-filter label {
    color: var(--light-grey-hovered);
    font-size: 1.5rem;
}

.load-filter h4 {
    color: var(--royal-white);
    font-size: 1.7rem;
}

.load-filter:hover {
    background: var(--royal-blue-light-transparent);
    transition: 0.2s ease-in-out;
}

.take-load-container {
    width: 100%;
    height: fit-content;
    background: white;
    display: flex;
    margin: 20px 0 20px 0;
    justify-content: space-between;
    align-items: center;
    border-radius: 45px;
    border: 1px solid var(--light-grey);
    transition: 0.2s ease-in-out;
    cursor: pointer;
}

.driver-load-directions-wrapper {
    padding: 0 0 0 30px;
}

.take-load-container:hover {
    background: var(--light-grey-hovered-transparent);
    transition: 0.2s ease-in-out;
    border: 1px solid var(--royal-blue);
}

.driver-load-directions-wrapper span label {
    color: var(--grey);
    font-size: 1.2rem;
    font-weight: bold;
}

.driver-load-directions-wrapper span h3 {
    color: var(--royal-blue);
    font-size: 2rem;
    font-weight: bold;
}

.driver-load-directions {
    display: flex;
    align-items: center;
    height: 100px;
    width: 250px;
}

.load-driver-direction {
    display: flex;
    margin: 0 0 0 10px;
    height: 70px;
    flex-direction: column;
    justify-content: space-between;
}

.load-driver-direction section {

}

.load-driver-direction section h3 {
    font-weight: bold;
    margin: 0;
    color: var(--royal-blue);
}

.load-driver-direction section p {
    margin: 5px 0 0 5px;
    color: var(--grey);
    font-weight: bold;
}


.bid-arrow-icon {
    margin: 0 0 0 5px;
    transition: transform 0.3s ease-in-out;
}

.bid-button:hover .bid-arrow-icon {
    transform: translateX(5px);
    transition: transform 0.3s ease-in-out;
}

.instant-book-load label {
    color: var(--grey);
    font-size: 1.3rem;
    font-weight: bold;
}

.instant-book-load h3 {
    color: var(--royal-blue);
    font-size: 1.8rem;
    font-weight: bold;
}

.slider-wrapper-miles input {
    width: 130px;
    height: 40px;
    background: white;
    border-radius: 30px;
    border: none;
    margin: 0 30px 0 0;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    transition: 0.2s ease-in-out;
}

.pickup-delivery-location-input input::placeholder {
    color: var(--light-grey);
    font-weight: bold;
}

.pickup-delivery-location-input button {
    display: flex;
    width: 45px;
    height: 40px;
    border-radius: 40px;
    font-size: 1.8rem;
    font-weight: bold;
    border: none;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;
}

.pickup-delivery-location-input button:hover {
    background: var(--light-grey-hovered);
    transition: 0.2s ease-in-out;
}

.filter-loads-container h2 {
    font-size: 3rem;
    margin: 10px 0 0 0;
    font-weight: bold;
    color: var(--royal-white);
    text-align: center;
}

.choose-location-wrapper {
    background: var(--royal-white);
    display: flex;
    width: 100%;
    height: 50px;
    margin: 15px auto;
    align-content: center;
    justify-items: center;
    align-items: center;
    border-radius: 40px;
    justify-content: space-between;
}

.by-route, .by-location {
    display: flex;
    width: 170px;
    margin: 5px;
    height: 40px;
    color: var(--grey);
    align-items: center;
    border-radius: 40px;
    border: none;
    font-weight: bold;
    justify-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.3rem;
    transition: 0.2s ease-in-out;
}

.by-location.selected,
.by-route.selected {
    background-color: var(--royal-blue);
    color: white;
}

.by-route:hover, .by-location:hover {
    background: var(--light-grey-hovered);
    transition: 0.2s ease-in-out;
}

.by-route:focus, .by-location:focus {
    background: var(--royal-blue);
    transition: 0.2s ease-in-out;
    color: var(--royal-white);
}

.choose-location-icon {
    margin: 0 10px 0 0;

}

.driver-loads-dashboard-content-body {
    display: flex;
    width: 100%;
    margin: 20px 0 20px 0;
    border-radius: 30px;
    flex-direction: column;
}

.driver-qoutes-dashboard-content-body {
    display: flex;
    width: 100%;
    margin: 20px 0 20px 0;
    border-radius: 30px;
}

.driver-listing-container {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100%;
    border-radius: 30px;
    overflow: scroll;
    overflow-x: hidden;

}

.driver-dashboard-chat-metric {
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
}

.driver-dashboard-side-panel-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 560px;
    width: 100%;
    margin: 0 0 0 20px;
}

.driver-map-container {
    display: flex;
    width: 100%;
    max-height: 250px;
    height: 100%;
    border-radius: 30px;
}

.driver-dashboard-side-panel {
    display: flex;
    margin: 20px 0 0 0;
    flex-direction: column;
    width: 100%;
    max-height: 600px;
    height: 100%;
    border-radius: 30px;
    overflow: scroll;
    border: 1px solid var(--light-grey);
}

.driver-dashboard-side-panel::-webkit-scrollbar {
    width: 1px;
}

.active-load-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: fit-content;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    border-radius: 20px;
    padding: 10px;


    @media screen and (max-width: 768px) {
        gap: 10px;
    }
}

.active-load-container:hover {
    background: var(--light-grey-hovered-2);
    transition: 0.1s ease-in-out;
}


.load-directions {
    display: flex;
}

.load-container-status {
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.load-directions-icon {

}

.load-container-info {
    height: 100%;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    justify-items: center;
}

.load-container-driver {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 155px;
    height: 100%;
}

.driver-icon {
    background: none;
    z-index: 999;
}

.load-info-section {
    padding: 5px 10px 5px 10px;
    color: var(--grey);
    background: var(--light-grey-hovered);
    border-radius: 10px;
    font-size: 1.3rem;
    font-weight: 500;
    display: inline-block;
    width: auto;
}

.origin-destination-container {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 5px;
    justify-content: space-between;
    height: 85px;
}

.load-directions-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
    color: var(--grey);
}

.load-directions-description {
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0;
    color: var(--light-grey);

}

.driver-info-google-map-container {
    border-radius: 15px;
    overflow: hidden;
}

.ai-chat-content {
    display: flex;
    width: 100%;
    height: 730px;
    padding: 0 15px 0 15px;
    border-radius: 40px;
    border: 1px solid var(--light-grey);
}

.chat-driver-button {
    display: flex;
    width: fit-content;
    height: fit-content;
    padding: 10px 15px 10px 15px;
    border: none;
    border-radius: 30px;
    background: var(--royal-green);
    color: var(--royal-white);
    font-size: 1.3rem;
    margin: 40px auto;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;
}

.driver-dashboard-load-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    align-content: center;
    margin: 20px 0 0 0;
}

.driver-filter-buttons {
    width: 450px;
    display: flex;
    justify-content: space-between;
}

.filter-buttons-driver {
    width: 200px;
    background: var(--royal-white);
    color: var(--royal-blue);
    font-size: 2rem;
    font-weight: bold;
    transition: 0.2s ease-in-out;
    border-radius: 55px;
}

.filter-buttons-driver:hover {
    background: var(--royal-blue);
    transition: 0.2s ease-in-out;
    color: var(--royal-white);
}


.button-nav-load-icon {
    margin-right: 10px;
}


.driver-loads-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 750px;
    margin: 20px 0 0 0;
    overflow: scroll;
}

.driver-loads-wrapper-qoutes {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 850px;
    margin: 20px 0 0 0;
    overflow: scroll;
}

.driver-loads-qoutes-listing {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 850px;
    padding: 0;
    margin: 0;
    border-radius: 30px;
    border: 2px solid var(--light-grey);
}

.listing-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100px;
    border-bottom: 1px solid var(--light-grey);
    padding: 0 30px 0 30px;
}

.listing-header section {
    margin: 20px 0 0 0;
}

.listing-header section h2 {
    font-size: 3rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.listing-header section p {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--light-grey);
}

.driver-dashboard-load-container {
    display: flex;
    width: 100%;
    height: 395px;
    margin: 40px 0 0 0;
    border: 1px solid var(--light-grey);
    border-radius: 80px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.driver-dashboard-load-container:hover {
    background: var(--light-grey-hovered);
    border: 1px solid var(--royal-blue);
    transition: 0.2s ease-in-out;
}

.driver-load-container-info {
    display: flex;
    width: 50%;
    margin: 0 0 0 30px;
    justify-content: space-between;
    height: 100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
}

.driver-load-info-main {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    padding: 60px 0 60px 40px;
}

.driver-load-info-additional {
    display: flex;
    flex-direction: column;
    align-content: start;
    justify-content: space-between;
    margin: 0 30px 0 0;
}

.driver-load-logo {
    width: 250px;
    height: 200px;
}

.driver-load-info-additional span {
    padding: 10px;
    margin: 10px 0 10px 0;
    color: var(--grey);
    font-size: 2rem;
    font-weight: bold;
    display: inline-block;
    width: fit-content;
    text-align: center;
    border-radius: 20px;
    background: var(--light-grey);
}

.map-load-section {
    width: 50%;
    height: 100%;
}

.status-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--royal-green);
    margin: 0 0 0 5px;
}

.driver-load-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 85px;
    height: 40px;
    justify-items: center;
    border-radius: 33px;
    background: var(--light-grey);
}

.status-text {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: var(--grey);
    margin: 0 5px 0 5px;
}

.driver-load-price {
    color: var(--royal-green);
    font-size: 4rem;
    font-weight: bold;
}

.driver-load-direction-wrapper {
    display: flex;
    align-items: center;
    height: 120px;
}

.driver-load-direction {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.driver-load-direction-origin {
    margin: 15px 0 0 0;
}

.driver-load-direction-origin h3 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.driver-load-direction-origin p {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--light-grey);

}

.driver-load-direction-destination {
    margin: 5px 0 0 0;
}

.driver-load-direction-destination h3 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.driver-load-direction-destination p {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--light-grey);
}


.driver-deal-conversations-sidebar {
    background: var(--royal-blue);
    width: 400px;
}


.chat-input-icons {
    margin: 0 15px 0 5px;
    cursor: pointer;
    transition: 0.1s ease-in-out;
}

.chat-input-icons:hover {
    transition: 0.1s ease-in-out;
    cursor: pointer;
}


.chat-input-area input {
    font-size: 1.5rem;
    font-weight: normal;

    @media screen and (max-width: 768px) {
        width: 50px
    }
}

.chat-input-area input::placeholder {
    color: var(--light-grey);
}

.chat-send-button {
    color: var(--royal-white);
    font-size: 1.5rem;
    width: 60px;
    height: 55px;
    margin: 0 0 0 10px;
    font-weight: bold;
    border-radius: 30px;
    transition: 0.2s ease-in-out;
    border: none;
}

.chat-send-button:hover {
    background: var(--royal-blue-light-transparent);
    transition: 0.2s ease-in-out;

}


.chat-conversation-search-bar input {
    font-size: 1.8rem;
    color: var(--grey);
    font-weight: bold;
    border: none;
}

.choose-chat-conversation {
    margin: 230px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;

}

.choose-chat-conversation p {
    border-radius: 25px;
    padding: 45px;
    font-weight: bold;
    font-size: 2rem;
    color: var(--dark-grey);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add this line */
    background: var(--royal-white);
}


.password-settings-container {
    display: flex;
    margin: 20px 0 0 0;
    flex-direction: column;
    justify-content: space-between;
    width: 350px;
}


.settings-content h2 {
    border-bottom: 1px solid var(--light-grey);
    font-size: 3rem;
    font-weight: bold;
    padding-bottom: 20px;
    color: var(--grey);
    margin: 30px 0 0 0;
}

.settings-content p {
    font-size: 1.8rem;
    font-weight: normal;
    color: var(--light-grey);
    margin: 20px 0 0 0;

}


.apply-settings-button {
    background: var(--royal-green);
    border: none;
    width: 150px;
    height: 50px;
    margin: 0 auto;
    font-size: 1.7rem;
    font-weight: bold;
    border-radius: 25px;
    transition: 0.2s ease-in-out;
}

.apply-settings-button:hover {
    background: var(--royal-green-hovered);
    color: var(--royal-white);
    transition: 0.2s ease-in-out;
}


.apply-settings-button-bottom {
    background: var(--royal-green);
    border: none;
    width: 150px;
    height: 50px;
    font-size: 1.7rem;
    font-weight: bold;
    border-radius: 25px;
    transition: 0.2s ease-in-out;
}

.apply-settings-button-bottom:hover {
    background: var(--royal-green-hovered);
    color: var(--royal-white);
    transition: 0.2s ease-in-out;
}


.delete-bin-icon {
    width: 19px;
    height: 19px;
    margin: 0 0 0 3px;
}

.deleting-account-section hr {
    width: 100%;
    border: 1px solid var(--light-grey);
    margin: 0 0 25px 0;
}

.deleting-account-section a {
    color: var(--royal-red);
    font-size: 1.7rem;
    font-weight: bold;
    transition: 0.2s ease-in-out;
    padding: 30px 0 0 0;
}

.avatar-settings {
    margin: 0 auto;
    align-content: center;
    justify-content: center;
    text-align: center;
    align-items: center;
    justify-items: center;
    display: flex;
    height: 250px;
    flex-direction: column;
}

.avatar-user-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: var(--royal-blue);
    color: var(--royal-white);
    font-size: 3rem;
    display: flex;
    object-fit: cover;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

}

.avatar-settings-wrapper {
    width: 300px;
    display: flex;
    justify-content: space-between;
    margin: 20px 0 20px 0;
}

.driver-change-avatar {
    background: var(--royal-blue);
    border: none;
    width: 150px;
    height: 50px;
    font-size: 1.7rem;
    font-weight: bold;
    border-radius: 25px;
    transition: 0.2s ease-in-out;
}

.driver-change-avatar:hover {
    background: var(--royal-blue-hovered);
    color: var(--royal-white);
    transition: 0.2s ease-in-out;
}

.delete-avatar {
    color: var(--royal-red);
    border: none;
    width: 150px;
    height: 50px;
    font-size: 1.7rem;
    font-weight: bold;
    border-radius: 25px;
    transition: 0.2s ease-in-out;
}

.delete-avatar:hover {
    background: var(--royal-red-hovered);
    color: var(--royal-white);
    transition: 0.2s ease-in-out;
}


.account-info-settings-1 {
    width: 225px;
}

.account-info-settings-2 {
    width: 225px;
}

.avatar-settings-icon {
    height: 150px;
    border-radius: 50%;
    background: var(--royal-blue);
    color: var(--royal-white);
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}


.create-driver-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    z-index: 999;
    justify-content: center;
    align-items: center;
}


.create-driver-popup section {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 15px 15px 10px 20px;
    align-items: center;
    border-bottom: 1px solid var(--light-grey);
}

.create-driver-popup section div p {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--grey);

}

.create-driver-popup section h3 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.create-driver-wrapper-success {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.create-driver-wrapper-success section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
    justify-items: flex-start;
    text-align: left;
    height: 320px;
    border-bottom: 1px solid var(--light-grey);
}

.create-driver-wrapper-success section span p {
    font-size: 1.5rem;
    margin: 0;
    font-weight: bold;
    color: var(--grey);
}

.driver-assigned-load-message {
    display: flex;
    width: 100%;
    height: 90%;
    margin: 250px auto;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--light-grey);
}


.driver-data-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    backdrop-filter: blur(10px);
    z-index: 999;
    justify-content: center;
    align-items: center;
}

.driver-popup {
    display: flex;
    flex-direction: column;
    width: 500px;
    height: fit-content;
    background: var(--royal-white);
    border-radius: 20px;
    padding: 20px;
}

.driver-popup h3 {
    font-size: 2.3rem;
    font-weight: bold;
    color: var(--royal-blue);
}

.driver-popup p {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--grey);
}

.dashboard-content-mobile {
    display: none;
    flex-direction: column;
    height: 100%;
    padding: 10px;
    width: 100%;
}

.load-delivered-status {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    margin: 0 auto;
    padding: 10px 20px;
    font-size: 2rem;
    border-radius: 10px;
    background: var(--light-grey-hovered);
    color: var(--royal-green);
}

.dashboard-buttons-mobile {
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    width: 100%;
    gap: 10px;
}

.active-loads-panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

@media screen and (max-width: 1380px) {

    .dashboard-content-mobile {
        display: flex;
        height: 100%;
        flex-direction: column;
        gap: 10px;
    }

    .load-info-section {
        font-size: 0.9rem;
    }

    .load-directions-title {
        font-size: 1.2rem;
    }
}


@media screen and (max-width: 768px) {
    .map-load-section-load-container {
        display: none;
    }

    .take-load-container {
        padding: 5px 20px 5px 20px;
        border-radius: 15px;
        font-size: 8px;
    }

    .driver-load-directions-wrapper {
        padding: 0 0 0 0
    }

    .driver-load-directions {
        max-width: 170px;
        width: 100%;
    }

    .load-short-info {
        width: fit-content;
        margin: 0 10px 0 10px;
    }


    .load-driver-direction section h3 {
        font-size: 12px;
    }

    .driver-map-direction-nav {
        border-radius: 0;
    }

    .load-driver-direction section p {
        font-size: 12px;

    }

    .driver-map-direction-nav-body {
        margin: 10px 0 0 0;
    }
}