.mainHeadingWrapper {
  display: flex;
  align-content: center;
  justify-items: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  gap: 10px;
  max-width: 550px;
  width: 100%;
  padding: 20px;
  margin: 50px auto 50px auto;

  @media screen and (max-width: 1028px) {
    gap: 30px;
  }

  section {
    display: flex;
    align-content: center;
    justify-items: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 350px;
    gap: 10px;
  }

  p {
    display: flex;
    color: var(--light-grey);
    font-size: 1.8rem;
    text-align: center;
    margin: 0;
    padding: 0;

  }

  h3 {
    color: var(--dark-grey);
    text-align: center;
    font-size: 5rem;
    font-weight: 700;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 1028px) {
      font-size: 3.5rem;
    }
  }

  h2 {
    color: var(--royal-white);
    text-align: center;
    font-size: 4rem;
    font-weight: bold;
    background: var(--royal-green);
    padding: 10px 20px;
    border-radius: 10px;
    background: var(--royal-green);
    margin: 0;

    @media screen and (max-width: 1028px) {
      font-size: 3rem;
    }
  }
}

.paragraphWithClients {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  p {
    flex: 1;
    color: var(--light-grey);
    font-size: 1.8rem;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .trustedClients {
    position: absolute;
    bottom: 0%;
    right: -30%;
    flex: 0 0 auto;
  }
}

.littleBenefits {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: fit-content;
  text-align: center;
  align-content: center;
  background: var(--royal-green-hovered-transparent);
  padding: 3px 15px 3px 3px;
  border-radius: 50px;

  section {
    display: flex;
    align-content: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    gap: 5px;


    span {
      display: flex;
      font-size: 1.2rem;
      width: 140px;
      color: var(--royal-green);
      margin: 0;
      padding: 0;
    }

  }
}

.checkmark {
  width: 15px;
  height: 15px;
}


@media screen and (max-width: 1820px) {

  .paragraphWithClients {
    .trustedClients {
      display: none;
    }
  }

}

@media screen and (max-width: 768px) {
  .mainHeadingWrapper {

    p {
      text-align: center;
    }
  }
}
