.clientItemReviewWrapper {
  display: flex;
  color: var(--light-grey);
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  justify-items: center;
  gap: 20px;

  &.reverse {
    flex-direction: row-reverse;
  }

  p {
    font-size: 1.8rem;
    margin: 0;
    padding: 20px;
    background: var(--light-grey-hovered-2);
    color: var(--dark-grey);
    width: fit-content;
    height: fit-content;
    border-radius: 15px;
  }
}

.clientItemReviewImage {
  display: flex;
  flex-direction: column;
  gap: 20px;

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
    border: 1px solid var(--dark-grey);
    animation: ripple 2.5s infinite;
    background-size: cover;
  }

  @keyframes ripple {
    0% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
    }
    50% {
      box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding: 5px;
    align-items: center;
    border-radius: 15px;
    background: var(--light-grey-hovered-2);

    h3 {
      padding: 0;
      margin: 0;
      font-size: 1.6rem;
      color: var(--dark-grey);
    }

    p {
      padding: 0;
      margin: 0;
      font-size: 1.1rem;
      font-weight: lighter;
      text-transform: uppercase;
      color: var(--light-grey);
    }
  }
}

@media screen and (max-width: 525px) {
  .clientItemReviewWrapper {


    p{
        font-size: 1.2rem;
    }
  }
}