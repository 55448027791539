* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

.sign-in-wrapper {
    display: flex;
}


.right-side-login {
    width: 100vw;
    height: 98vh;
    background: url("../../../src/assets/images/sunset-truck.png") no-repeat center;
    background-size: cover;
    backdrop-filter: blur(5px);
    align-content: center;
    margin: 10px;
    border-radius: 20px;
    justify-content: center;
    text-align: center;
    display: flex;
    align-items: center;
}

.right-side-login-side-title {
    text-transform: uppercase;
    font-size: 9.5rem;
    font-weight: 700;
    background: linear-gradient(90deg, var(--royal-white), var(--royal-blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.right-side-login-side-description {
    font-size: 2.5rem;
    font-weight: 300;
    color: white;
}

.h2-title-login-form {
    font-size: 4.5rem;
    font-weight: 600;
    margin: 20px 0 0 0;
    color: var(--royal-blue);
}

.h3-title-login-form {
    font-size: 2rem;
    margin: 0 0 20px 0;
    font-weight: 300;
    color: var(--grey);
}

.label-text {
    color: #343541;
    font-weight: 500;
    font-size: 1.2rem;
}

.question-p {
    font-size: 1.5rem;
    margin: 20px 0 20px 0;
    font-weight: 400;
    color: #707070;
}


.input-field {
    margin: 10px 0 10px 0;
    background-color: #f2f2f2;
    color: #343541;
    height: 5vh;
    font-weight: 500;
    font-size: 1.5rem;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #d7d7d7;
}

.logo-blue {
    height: auto;
    width: 150px;
    display: flex;
    margin: 0 auto;
}

.login-custom-form {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 10px;
    max-width: 500px;
    width: 100%;
    margin: 0 15px 0 15px;
    padding: 0 15px 0 15px;
}

.sign-in-button {
    margin: 10px auto;
    background-color: var(--royal-blue);
    border: none;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    padding: 7px 13px 7px 13px;
    display: flex;
    font-weight: 500;
    font-size: 1.5rem;
    border-radius: 35px;
    transition: 0.3s ease-out;
}

.sign-in-button:hover {
    background-color: var(--royal-blue-hovered);
    transition: 0.3s ease-out;
}


@media only screen and (max-width: 768px) {
    .right-side-login {
        display: none;
    }

    .left-side {
        padding: 20px 0 20px 0;
        height: 100vh;
        margin: 0 auto;
        align-content: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
    }
}