.getStartedSectionWrapper {
  display: flex;
  flex-direction: column;
  margin: 100px auto 100px auto;
  gap: 20px;
  height: fit-content;
  max-width: 1400px;
  width: 100%;
  padding: 20px;

  h1 {
    font-size: 4.5rem;
    color: var(--dark-grey);
    text-align: center;
    font-weight: bold;
  }

  p {
    font-size: 2.5rem;
    color: var(--light-grey);
    text-align: center;
    margin: 0 auto;
  }

  section {
    max-width: 700px;
    display: flex;
    width: 100%;
    gap: 20px;
    margin: 0 auto;
  }

}

.getStartedContentWrapper {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 30px;
  border: 1px solid var(--light-grey);
}

.getStartedContent {
  display: flex;
  justify-content: space-between;

  section {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    justify-content: center;
  }

  img {
    width: 560px;
    height: 450px;
    object-fit: cover;
  }
}

.getStartedContentInputs {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 20px;
  padding: 20px;
}

@media screen and (max-width: 1024px) {

  .getStartedSectionWrapper{
  }

  .getStartedContent{
    flex-direction: column-reverse;
    align-content: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
  }

  .getStartedContent{
    img{
      max-width: 250px;
      width: 100%;
      height: auto;
    }
  }
}

@media screen and (max-width: 768px) {
  .getStartedContentInputs{
    grid-template-columns: repeat(1, 1fr);
  }
}
