.benefitChanger {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 20px;
  width: 100%;
  height: 530px;
  padding: 20px;
  position: relative;
  transition: 0.2s ease-in-out;
  animation: fadeIn 0.5s ease-in-out;


  label {
    text-transform: uppercase;
    font-size: 1.2rem;
    color: var(--royal-white);
  }

  h2 {
    font-size: 3.5rem;
    font-weight: bold;
    color: var(--royal-white);
  }

  h5 {
    font-size: 1.8rem;
    margin: 15px 0 0 0;
    color: var(--royal-white);
  }

  p {
    font-size: 1.8rem;
    color: var(--royal-white);
  }
}

.benefitChangerImage {
  width: 550px;
  height: 335px;
  border-radius: 20px;
  object-fit: contain;
}

.content {
  transition: opacity 0.5s ease-in-out;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;


  &.fade-in {
    opacity: 1;
  }

  &.fade-out {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

.fade-out {
  animation: fadeOut 0.5s ease-in-out;
}

.plusIcon {
  position: absolute;
  bottom: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--light-grey-hovered);
  border-radius: 50px;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    transform: rotate(45deg);
    background-color: var(--light-grey);
  }
}

.clickToAction {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: fit-content;
  height: fit-content;
  display: flex;
  font-weight: lighter;
  color: var(--royal-white);
  padding: 15px 25px 15px 25px;
  font-size: 2rem;
  border: none;
  border-radius: 50px;
  background: var(--royal-green);
  transition: 0.2s ease-in-out;

  &:hover {
    background: var(--royal-green-hovered);
  }
}

.rotated {
  transform: rotate(45deg);
}

.blue {
  background-color: #1a73e8;
  color: #fff;
}

.white {
  background-color: #fff;
  color: var(--dark-grey);
  border: 1px solid var(--light-grey);

  label {
    color: var(--dark-grey);
  }

  h2 {
    color: var(--dark-grey);
  }

  p {
    color: var(--dark-grey);
  }

  h5 {
    color: var(--dark-grey);
  }
}

.light-blue {
  background-color: #e8f0fe;
  color: var(--dark-grey);

  label {
    color: var(--dark-grey);
  }

  h2 {
    color: var(--dark-grey);
  }

  p {
    color: var(--dark-grey);
  }

  h5 {
    color: var(--dark-grey);
  }
}

.dark-blue {
  background-color: #174ea6;
  color: #fff;
}

.fade-enter {

  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit {
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.fade-exit-active {
  opacity: 0;
}

@media screen and (max-width: 1230px) {
  .benefitChanger {
    width: fit-content;
    height: fit-content;

    .content {

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}