.clientReviewContent {
  margin: 100px auto 100px auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: fit-content;
  max-width: 900px;


  h1 {
    font-size: 4rem;
    color: var(--dark-grey);
    text-align: center;
    font-weight: bold;
  }
}

.clientReviews {
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

@media screen and (max-width: 768px){
  .clientReviewContent{
    padding: 40px;
  }
}