.label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: fit-content;
  position: relative;
  padding: 10px;
  border-radius: 50px;
  overflow: hidden;
  border: 1px solid var(--light-grey);
  margin: 0 5px 0 5px;
  transition: background 0.3s ease-in-out;


  span{
    font-size: 4.5rem;
    font-weight: bold;
    color: var(--dark-grey);
  }
}

.wordContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: transform 0.6s ease-in-out;
}

.animating .wordContainer {
  transform: translateY(-100%);
}

.slide {
  transition: transform 0.6s ease-in-out;
  transform: translateY(100%);
}

.icon {
  margin-right: 10px;
  font-size: 1.5rem;

  img{
    width: 50px;
    height: 50px;
  }
}

.word {
  font-size: 3rem;
  color: white;
}
