@font-face {
    font-family: 'Google Sans';
    src: url('./fonts/Google-Sans/GoogleSans-Regular.ttf') format('truetype'),
    url('./fonts/Google-Sans/GoogleSans-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

* {
    font-family: "Google Sans", "sans-serif";
    object-fit: contain;
}

html {
    font-size: 60%;
    scroll-behavior: smooth;
    background: #fbfbff;

}

input {
    outline: none;
    border: none;
    background: transparent;
}

h1, h2, h3, h4, h5, h6, p, a, span, div, input, button, label, select, option, textarea {
    font-family: "Google Sans", "sans-serif";
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Google Sans", "sans-serif";
}


:root {
    --royal-blue: #024ec9;
    --royal-blue-hovered: #0061ff;
    --royal-blue-light: #07aff0;
    --royal-blue-light-transparent: rgba(77, 147, 255, 0.11);
    --royal-green: #0c945c;
    --royal-green-dark: #08774a;
    --royal-yellow: #e4ad21;
    --orange: #ff8c00;
    --royal-green-hovered: #009f52;
    --royal-green-hovered-2: rgba(0, 159, 82, 0.25);
    --royal-green-hovered-transparent: #d8f0e6;
    --alert-success: #b6eacd;
    --alert-info: #c2edfe;
    --alert-warning: #fbeed9;
    --alert-error: #fee3e1;
    --royal-red: #d83025;
    --royal-red-hovered: #ea3528;
    --royal-blue-transparent: rgba(2, 78, 201, 0.31);
    --royal-white: #ffffff;
    --royal-white-dark: #fbfbff;
    --royal-white-transparent: rgba(255, 255, 255, 0.66);
    --dark-grey: #2e2a2a;
    --grey: #707070;
    --dark: #292929;
    --light-grey: #afafaf;
    --grey-darker: #979797;
    --light-grey-hovered: #e5e5e5;
    --light-grey-hovered-2: #f6f6f6;
    --light-grey-hovered-transparent: rgba(229, 229, 229, 0.23);
    --load-credentials-background: #e6e6e6;
    --load-credentials-font-color: #9E9E9E;
    --search-bar-background: #efefef;
    --search-bar-placeholder-color: #B4B4B4;
    --grey-design: #9390a0;
}