.aiPage{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 800px;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 50px;

    @media screen and (max-width: 1028px) {
        padding: 20px 5px;
    }
}
