.gridTwoColumns{
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  width: 100%;
  align-items: center;
  align-content: start;
  margin: 0 auto;
  justify-content: start;
  justify-items: start;
  gap: 10px;
}

@media screen and (max-width: 768px) {
    .gridTwoColumns{
        grid-template-columns: repeat(1, 1fr) !important;
    }
}